import React from 'react'
import {Row, Col, Button} from 'antd'
import withSizes from 'react-sizes'

import MenuBurger from './Menu-burger'
import MenuAntd from './Menu-antd'

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 992,
})

class IndexMenu extends React.Component {
  render(){
    const { isMobile } = this.props
    if(isMobile) {
      return <MenuBurger />
    } else {
      return <MenuAntd />
    }
  }
}

export default withSizes(mapSizesToProps)(IndexMenu)
