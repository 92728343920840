import React from 'react'
import {Statistic} from 'antd'

import { Bloc } from './../../../../util/Bloc'

const Depenses = ({data}) => {
  return(
    <Bloc
    title="Résultat">

    <Statistic
      style={{textAlign:'center'}}
      title="30 derniers jours"
      value={1001.28}
      groupSeparator=" "
      precision={2}
      valueStyle={{ color: '#3f8600' }}
      suffix="€"
    />
     {/*sum(depensesTotal.map(depense=> depense.totalTtc))*/}
    </Bloc>
  )
}

export default Depenses
