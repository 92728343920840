import React from 'react'
import { Row, Col, Button, Switch } from 'antd'
import { MinusCircleOutlined, PlusOutlined, DragOutlined, DownSquareOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'

import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form'
import { TextInputWoError, NumberInputWoError, SelectInputWoError } from './../../../util/Form/field'

const selector = formValueSelector('devisArticlesForm') // on récupère les values du form

const showBdC = [
  {
    key: 0,
    value: true,
    name: 'Afficher',
  },
  {
    key: 1,
    value: false,
    name: 'Masquer',
  }
]
const unite = [
  {
    key: 'h',
    value: 'h',
    name: 'h',
  },
  {
    key: 'dj',
    value: '½j',
    name: '½j',
  },
  {
    key: 'j',
    value: 'j',
    name: 'j',
  },
  {
    key: 's',
    value: 's',
    name: 's',
  },
  {
    key: 'm',
    value: 'm',
    name: 'm',
  },
  {
    key: 'km',
    value: 'km',
    name: 'km',
  },
  {
    key: 'm2',
    value: 'm²',
    name: 'm²',
  },
  {
    key: 'm3',
    value: 'm³',
    name: 'm³',
  },
  {
    key: 'kg',
    value: 'kg',
    name: 'kg',
  },
  {
    key: 'u',
    value: 'u',
    name: 'u',
  }
]

class ArticlesFormField extends React.Component {

  render() {
    console.info('faire validate');
    const { fields, field, i, articlesEdited, articles, categorySelected, descSelected, articleSelected, qSelected, rSelected, devisSelected } = this.props
    return (
      <Row type="flex" key={i} justify="space-around" gutter={4}>
        <Col span={descSelected ? 6 : 12}>
          <Field
            name={`${field}.article`}
            component={SelectInputWoError}
            options={articlesEdited}
            showSearch
            placeholder="Article"
          />
        </Col>
        <Col span={descSelected ? 10 : 4}>
          <Field
            name={`${field}.desc`}
            placeholder="Titre personnalisé"
            component={TextInputWoError}
            allowClear
          />
        </Col>
        <Col span={2}>
          <Field
            name={`${field}.q`}
            placeholder="1"
            defaultValue="1"
            component={NumberInputWoError}
          />
        </Col>
        <Col span={2}>
          <Field
            name={`${field}.u`}
            defaultValue='u'
            placeholder="J"
            options={unite}
            component={SelectInputWoError}
          />
        </Col>
        <Col span={3}>
          <Field
            name={`${field}.showBdC`}
            options={showBdC}
            component={SelectInputWoError}
          />
        </Col>


        <Col span={1} style={{ marginTop: '10px', textAlign: 'center' }}>
          <MinusCircleOutlined
            onClick={() => fields.remove(i)}
          />
        </Col>
      </Row>
    )
  }
}

export default
  connect(
    (state, props) => ({
      categorySelected: selector(state, `${props.field}.category`),
      articleSelected: selector(state, `${props.field}.article`),
      descSelected: selector(state, `${props.field}.desc`),
      qSelected: selector(state, `${props.field}.q`),
      rSelected: selector(state, `${props.field}.r`),
      devisSelected: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
    })
  )(ArticlesFormField)
