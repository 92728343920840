import React from 'react'
import { Route, Switch } from "react-router-dom";

import { connect } from 'react-redux'
import { firestoreConnect, withFirestore } from 'react-redux-firebase'

import { Loading, NoMatchPage } from './../../util/Errors'

import SettingsCompany from './Settings/Settings'
import Dashboard from './Dashboard/'
import DevisList from './Devis/Devis'
import FacturesList from './Factures/Factures'
import Facture from './FactureSelected/'
import Compta from './Compta/'
import Devis from './DevisSelected/Devis'
import Depenses from './Depenses/'
import Okr from './OKR/'
import BdC from './BdC';
import BdCSelected from './BdC/BdCSelected';


const query = (props) => {
  return [
    {
      collection: 'companies',
      where: ['publicId', '==', props.match.params.idCompany],
      queryParams: ['limitToFirst=1'],
      storeAs: 'myCompany'
    },
    {
      collection: 'devis',
      where: ['compId', '==', props.match.params.idCompany],
    },
    {
      collection: 'factures',
      where: ['compId', '==', props.match.params.idCompany],
    }
  ]
}

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  loading: state.firestore.status.requested
})

class MyCompany extends React.Component {

  render() {
    const { loading, myCompany } = this.props
    console.log(this.props);
    if (loading.myCompany) {
      if (myCompany) {
        return (
          <Switch>
            <Route path="/:idCompany/TableauDeBord/" exact component={Dashboard} />
            <Route path="/:idCompany/Parametres/" exact component={SettingsCompany} />
            <Route path="/:idCompany/OKR/" exact component={Okr} />
            <Route path="/:idCompany/Devis/" exact component={DevisList} />
            <Route path="/:idCompany/Factures/" exact component={FacturesList} />
            <Route path="/:idCompany/BonsDeCommande/" exact component={BdC} />
            <Route path="/:idCompany/BonsDeCommande/:idDevis/" exact component={BdCSelected} />
            <Route path="/:idCompany/Devis/:idDevis/" exact component={Devis} />
            <Route path="/:idCompany/Factures/:idFacture/" exact component={Facture} />
            <Route path="/:idCompany/Depenses/" exact component={Depenses} />
            <Route path="/:idCompany/Comptabilite/" exact component={Compta} />
          </Switch>
        )
      } else {
        return <NoMatchPage />
      }
    } else {
      return <Loading title="Chargement de votre société" />
    }
  }
}

export default withFirestore(connect(mapState)(firestoreConnect(query)(MyCompany)))
