import firebase from './../config/firebase'
import { message } from 'antd'

import { calculTVA, calculHT, sum, sumHT, sumTVA } from './../util/Maths/calculsTVA'

export const postDepense = (data, compId) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const { date, ttc0, ttc55, ttc10, ttc20, ...restData } = data; // on destructure data pour sortir les éléments qu'on veut modifier
  const listTTC = {
    ttc0: ttc0 ? ttc0 : 0,
    ttc55:ttc55 ? ttc55 : 0,
    ttc10:ttc10 ? ttc10 : 0,
    ttc20:ttc20 ? ttc20 : 0};
  let dateNow = Date.now();

  let newDepense = {}
  newDepense = {
    ...restData,
    date: date._d,
    compId: compId,
    uid: user.uid,

    ttc0: ttc0 ? parseFloat(ttc0) : null,
    ht0: ttc0 ? ttc0 : null,
    tva0: ttc0 ? 0 : null,

    ttc55: ttc55 ? parseFloat(ttc55) : null,
    ht55: ttc55 ? calculHT(ttc55, 5.5) : null,
    tva55: ttc55 ? calculTVA(ttc55, 5.5) : null,

    ttc10: ttc10 ? parseFloat(ttc10) : null,
    ht10: ttc10 ? calculHT(ttc10, 10) : null,
    tva10: ttc10 ? calculTVA(ttc10, 10) : null,

    ttc20: ttc20 ? parseFloat(ttc20) : null,
    ht20: ttc20 ? calculHT(ttc20, 20) : null,
    tva20: ttc20 ? calculTVA(ttc20, 20) : null,

    totalTtc: sum(listTTC),
    totalTva: sumTVA(listTTC),
    totalHt: sumHT(listTTC),
  }
  try{
    await firestore.set(`depenses/${dateNow}`,  {...newDepense})
    console.log(newDepense);
    message.success('Nouvelle note de frais enregistrée')
  }catch (e){
    message.error(e)
  }
}

export const updateClient = (data, id) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const clientRef = firestore.collection('clients').doc(id)
  try{
    await clientRef.update({
      ...data
    })
    message.success('Informations du client mises à jours')
  }catch (e){
    message.error(e)
  }
}

//ajouter de la sécurité à tous ça (seuelement la personne connecter avec l'id du propriétaire peut supprimer le client)
export const deleteDepense = (id) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const depenseRef = firestore.collection('depenses').doc(id)
  //if(user.uid === depenseRef.uid)
  try{
    await depenseRef.delete()
    .then(
      () => message.success('Dépense supprimée')
    )
    .catch(
      (e)=> message.error(e)
    )
  }catch (e){
    message.error(e)
  }
}
