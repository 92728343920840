import React from 'react'
import { Card, Form, Button, Col, Row, Alert} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'

//import { createCompany, updateComapny } from './../../../actions/companiesActions'

import { TextInput } from './../../../util/Form/field'

const validate = combineValidators({
  title: composeValidators(
    isRequired({message: 'Requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(150)({message:'Trop long'})
  )(),
  price: composeValidators(
    isRequired({message: 'Requis'}),
    isNumeric({message:'Nombre uniquement'}),
  )(),
  ref: composeValidators(
    hasLengthLessThan(7)({message:'Trop long'})
  )(),
})

const mapState = (state, props) => {
  let initialValues = {}

  if(props.editId && state.firestore.data.articles){
    initialValues = state.firestore.data.articles[props.editId]
  } else {
    initialValues = null
  }
  return{
    initialValues,
    loading: state.form && state.form.article
  }
}

const actions = {
//  postArticle, updateArticle
}

class ArticleForm extends React.Component {

  componentDidUpdate(){
    if(!this.props.editId && this.props.cancelEdit){
      this.props.destroy()
    }
  }

  onFormSubmit = values => {
    if(!this.props.editId){
      console.log('add');
      this.props.postArticle(values)
      .then(this.props.reset)
    }else{
      console.log('edit');
      this.props.updateArticle(values, this.props.editId)
      this.props.destroy()
      this.props.resetParent()
    }
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, editId, loading } = this.props
    return (
        <Form onFinish={handleSubmit(this.onFormSubmit)} > {/* this.props.handleSubmit(this.onFormSubmit) on réalise la fonction handleSubmit de Redux sur onFormSubmit*/}
          <Row gutter={16}>
            <Col span={18}>
              <Field
                name="companyName"
                labelTitle="Nom société abrégé"
                placeholder="Danone"
                component={TextInput}
              />
            </Col>
          </Row>

          <div style={{textAlign:'right'}}>
            <Button
              onClick={reset}
              style={{ marginRight: 8 }}
              disabled={pristine || submitting}
            >
              Effacer
            </Button>
            <Button
              loading={loading && loading.submitting}
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              {editId? 'Modifier' : 'Sauvegarder'}
            </Button>
          </div>
        </Form>
    );
  }
}

export default connect(mapState, actions)(reduxForm({form:'society', validate})(ArticleForm))
