import React from 'react'
import {Statistic} from 'antd'
import { useCountUp } from 'react-countup';

import { Bloc } from './../../../../util/Bloc'
import { sumArray } from './../../../../util/Maths/simple'
import Chart from './../../../../util/Charts/PieChart'

const Depenses = ({data}) => {
  return(
    <Bloc
    title="Dépenses">
    <Statistic
      style={{textAlign:'center'}}
      title="30 derniers jours"
      value={data ? sumArray(data.map(e => e.totalTtc)) : "NaN"}
      groupSeparator=" "
      precision={2}
      valueStyle={{ color: 'rgb(134, 57, 0)' }}
      suffix="€"
    />
    <Chart />
    </Bloc>
  )
}

export default Depenses
