import React from 'react';
import { Row, Col, Breadcrumb, message, List, Popconfirm, Button, Input, Tag, Divider, Radio, Tooltip, Progress } from 'antd'
import { HomeOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined, SearchOutlined, SendOutlined, CheckOutlined, CloseOutlined, SubnodeOutlined, DownOutlined } from '@ant-design/icons';

import { Link } from "react-router-dom";

import { Bloc } from './../../../util/Bloc'
import { NotAllowed } from './../../../util/Errors'
import { sortByNumber } from './../../../util/sort'

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

//import DevisForm from './DevisForm'
import ActionBar from './ActionBar'

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  factures: state.firestore.ordered.factures,
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  loading: state.firestore.status.requested
})

const StatutFactureProgress = ({ state }) => { // marche pas pk ?
  switch (state) {
    case 'redaction':
      return <Tooltip title="Rédaction"><Progress style={{ width: '100px' }} percent={30} status="active" showInfo={false} /></Tooltip>;
    case 'sent':
      return <Tooltip title="Envoyé"><Progress style={{ width: '100px' }} percent={70} status="active" strokeColor="#49e8b4" showInfo={false} /></Tooltip>;
    case 'declined':
      return <Tooltip title="Refusé"><Progress style={{ width: '100px' }} percent={70} status="exception" strokeColor="#bc8181" showInfo={false} /></Tooltip>;
    case 'validated':
      return <Tooltip title="Validé"><Progress style={{ width: '100px' }} percent={100} showInfo={false} /></Tooltip>;
    default:
      return '';
  }
}

class Factures extends React.Component {
  state = {
    editId: null,
    cancelEdit: false,
    facturesFiltered: []
  }

  componentDidMount() {
    let filtered = sortByNumber(this.props.factures ? this.props.factures : [])
    this.setState({
      facturesFiltered: filtered
    });
  }
  componentWillReceiveProps(nextProps) {
    let filtered = sortByNumber(nextProps.factures ? nextProps.factures : [])
    this.setState({
      facturesFiltered: filtered
    });
  }

  cancelEdit = () => { //si cancelEdit alors on réinitinialise le form, d'ou désactiver cancelEdit après 10ms
    this.setState({ editId: null, cancelEdit: true })
    setTimeout(() => this.setState({ cancelEdit: false }), 100)
  }

  handleChange = e => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      currentList = this.props.factures;
      newList = currentList.filter(facture =>
        (facture.tilte && facture.name.toLowerCase().includes(e.target.value.toLowerCase()))
      )

      this.setState({
        facturesFiltered: newList
      })
    } else {
      newList = this.props.factures;
      this.setState({
        facturesFiltered: newList
      });
    }
  }

  render() {
    const { factures, loading, auth, profile, myCompany } = this.props
    const { editId, facturesFiltered, cancelEdit } = this.state
    return (
      <div>
        <Breadcrumb style={{ marginBottom: '15px' }}>
          <Breadcrumb.Item href="/">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={'/' + myCompany.publicId + '/TableauDeBord/'}>
              <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Factures</span>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ height: '74vh' }}>
          <Bloc
            title="Factures"
            action={<Input placeholder="Chercher une facture" allowClear onChange={this.handleChange} prefix={<SearchOutlined />} />}
            loading={false} > {/*//!loading['clients?where=uid:==:'+ auth.uid +'&orderBy=society']*/}
            <List
              size="small"
              dataSource={facturesFiltered}
              renderItem={facture =>
                <List.Item
                  style={{ borderBottom: '1px solid #80808066', padding: '5px' }}
                  actions={[
                    facture.inDate ?
                      <Tooltip title={"Encaissé le " + facture.inDate}><Progress style={{ width: '100px' }} percent={100} showInfo={false} /></Tooltip>
                      :
                      <Tooltip title={"Généré le " + facture.creationDate}><Progress style={{ width: '100px' }} percent={20} status="active" strokeColor="#49e8b4" showInfo={false} /></Tooltip>
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Link to={facture.id + "/"} onClick={() => this.setState({ editId: facture.id, data: facture, cancelEdit: false })} style={{ color: editId === facture.id ? '' : 'rgba(0, 0, 0, 0.7)' }} >
                        {facture.title}
                      </Link>}
                    description={
                      !facture.inDate &&
                      <Link onClick={() => this.setState({ editId: facture.id, data: facture, cancelEdit: false })} style={{ color: editId === facture.id ? '' : 'rgba(0, 0, 0, 0.7)' }} >
                        Facture N°{facture.number}
                      </Link>
                    }
                  />
                </List.Item>}
            />
          </Bloc>
        </div>
      </div>
    )
  }
}

export default withFirestore(connect(mapState)(Factures))
