import React from 'react'
import {Card, Typography} from 'antd'
import {
  DashboardOutlined,
  StarOutlined,
  VideoCameraOutlined,
  VideoCameraAddOutlined,
  BankOutlined,
  TeamOutlined,
  UserAddOutlined,
  ControlOutlined,
  SettingOutlined,
  LogoutOutlined } from '@ant-design/icons';

const { Text } = Typography;

const TheLogo = ({icon}) => {
  switch(icon) {
    case 'bank':
      return <BankOutlined style={{fontSize:'2em'}}/>;
    case 'dashboard':
      return <DashboardOutlined style={{fontSize:'2em'}}/>;
    case 'camera':
      return <VideoCameraOutlined style={{fontSize:'2em'}}/>;
    case 'cameraAdd':
      return <VideoCameraAddOutlined style={{fontSize:'2em'}}/>;
    case 'team':
      return <TeamOutlined style={{fontSize:'2em'}}/>;
    case 'userAdd':
      return <UserAddOutlined style={{fontSize:'2em'}}/>;
    case 'setting':
      return <SettingOutlined style={{fontSize:'2em'}}/>;
    default:
      return '';
  }
}

const CallToAction = ({ icon, text, callToAction}) => {
  return(
    <Card style={{ width: '100%', textAlign:'center' }}>
    <div>
      <TheLogo icon={icon}/>
    </div>
    <div style={{marginTop:'9px'}}>
    <Text>{text} </Text>
    </div>
    <div style={{marginTop:'3px'}}>
    <Text code>{callToAction}</Text>
    </div>
    </Card>
  )
}

export default CallToAction
