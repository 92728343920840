import firebase from './../config/firebase'
import { message } from 'antd'

export const postClient = (data) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  let newClient = {}
  let dateNow = Date.now();
  newClient = {
    ...data,
    uid: user.uid
  }
  try{
    await firestore.set(`clients/${dateNow}`,  {...newClient})
    console.log(newClient);
    message.success('Nouveau client enregistré')
  }catch (e){
    message.error(e)
  }
}

export const updateClient = (data, id) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const clientRef = firestore.collection('clients').doc(id)
  try{
    await clientRef.update({
      ...data
    })
    message.success('Informations du client mises à jours')
  }catch (e){
    message.error(e)
  }
}

//ajouter de la sécurité à tous ça (seuelement la personne connecter avec l'id du propriétaire peut supprimer le client)
export const deleteClient = (id) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const clientRef = firestore.collection('clients').doc(id)
  //if(user.uid === clientRef.uid)
  try{
    await clientRef.delete()
    .then(
      () => message.success('Client supprimé')
    )
    .catch(
      (e)=> message.error(e)
    )
  }catch (e){
    message.error(e)
  }
}
