import React from 'react'
import {Row, Col, Button, Breadcrumb, List, Popconfirm, Input, Tag} from 'antd'
import { HomeOutlined, DeleteOutlined, ArrowLeftOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

import { Link, withRouter } from "react-router-dom";

import { compose } from "redux"
import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

import { deleteDepense } from './../../../actions/depensesActions'

import { Bloc } from './../../../util/Bloc'

import DepenseForm from './DepenseForm'

const query = (props) => {
  return [
    {
      collection:'depenses',
      where:['compId', '==', props.match.params.idCompany],
      orderBy:'date'
    },
  ]
}

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    depenses: state.firestore.ordered.depenses,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    loading: state.firestore.status.requested
})

const actions = {
  deleteDepense
}

class Depenses extends React.Component {
  state={
    editId: null,
    cancelEdit:false,
    depensesFiltered: []
  }

  componentDidMount(){
    this.setState({
      depensesFiltered: this.props.depenses
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      depensesFiltered: nextProps.depenses
    });
  }

  cancelEdit = () => { //si cancelEdit alors on réinitinialise le form, d'ou désactiver cancelEdit après 10ms
    this.setState({editId: null, cancelEdit:true})
    setTimeout(() => this.setState({cancelEdit:false}), 100)
  }

  handleChange = e => { //recherche
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      currentList = this.props.depenses;
      newList = currentList.filter(depense =>
        (depense.ref && depense.ref.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (depense.type && depense.type.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (depense.description && depense.description.toLowerCase().includes(e.target.value.toLowerCase()))
      )

      this.setState({
        depensesFiltered: newList
      })
    } else {
      newList = this.props.depenses;
      this.setState({
        depensesFiltered: newList
      });
    }
  }

  render(){
    const { depenses, loading, deleteDepense, auth, myCompany } = this.props
    const { editId, depensesFiltered, cancelEdit } = this.state
    return(
      <div>
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined/>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={'/'+myCompany.id+'/TableauDeBord/'}>
            <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <span>Dépenses</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row type="flex" gutter={[14, 16]} style={{height:'74vh'}}>
        <Col xs={24} md={12} lg={13} xl={14} xxl={15} >
        <Bloc
          title={
          <Row justify="space-between">
            <Col>Dépenses</Col>
            <Col><Input placeholder="Chercher une note de frais" allowClear onChange={this.handleChange} prefix={<SearchOutlined />} /></Col>
          </Row>}
          loading={!loading['depenses?where=compId:==:'+this.props.match.params.idCompany+'&orderBy=date']}
          >
            <List
              size="small"
              dataSource={depensesFiltered}
              renderItem={depense =>
                <List.Item
                  style={{borderBottom:'1px solid #80808066', padding:'5px'}}
                  actions={ editId && editId === depense.id ? /*Si cette modif est selectionnée */
                    [
                      <a key="cancel-edit" onClick={this.cancelEdit}><ArrowLeftOutlined /></a>,
                      <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={() => deleteDepense(depense.id)} okText="Oui" cancelText="Non">
                        <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                      </Popconfirm>
                    ]
                    :
                      !editId ? /*Si il aucune modif est selectionnée // disabled car erreur due au form date */
                      [
                        <a key="list-edit"  disabled onClick={() => this.setState({editId: depense.id, cancelEdit:false}) }><EditOutlined /></a>,
                        <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={() => deleteDepense(depense.id)} okText="Oui" cancelText="Non">
                          <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                        </Popconfirm>
                      ]
                      : /*Si il une modif est selectionnée */
                      [
                        <EditOutlined />,
                        <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={() => deleteDepense(depense.id)} okText="Oui" cancelText="Non">
                          <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                        </Popconfirm>
                      ]
                    }
                >
                <List.Item.Meta
                title={<div><Tag>{depense.ref}</Tag><Tag color="blue">{depense.type}</Tag></div>}
                description={depense.description}
                />
                <div>{depense.totalTtc ? <div>{depense.totalTva}TVA, <b>{depense.totalTtc}TTC</b></div> : 'NaN'}</div>
                </List.Item>}
            />
          </Bloc>
        </Col>
        <Col xs={24} md={12} lg={11} xl={10} xxl={9}>
          <Bloc title={editId ? 'Modifier une note de frais' : 'Ajouter une note de frais'} >
            <DepenseForm editId={editId} cancelEdit={cancelEdit} resetParent={() => this.setState({editId:null})}/>
          </Bloc>
        </Col>
      </Row>
      </div>
    )
  }
}

//export default withFirestore(connect(mapState)(firestoreConnect(query)(Depenses)))

export default withRouter(withFirestore(connect(mapState, actions)(firestoreConnect(query)(Depenses))))
