import React from 'react'
import { Row, Col, Button, Breadcrumb } from 'antd'
import { HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined, FilePdfOutlined } from '@ant-design/icons';

import { Link, withRouter } from "react-router-dom";

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore, populate } from 'react-redux-firebase'

import { Loading } from './../../../util/Errors'
import { Bloc } from './../../../util/Bloc'

import FacturePDF from './FacturePDF_V2.js'

const query = (props) => {
  return [
    {
      collection: 'factures',
      where: ['id', '==', props.match.params.idFacture],
      storeAs: 'factureSelected'
    },
    {
      collection: 'clients',
      where: ['uid', '==', props.auth.uid],
      orderBy: 'society'
    },
  ]
}

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  factureSelected: state.firestore.ordered.factureSelected && state.firestore.ordered.factureSelected[0],
  loading: state.firestore.status.requested
})

class Facture extends React.Component {
  state = {
    showPDF: true,
  }

  render() {
    const { showPDF } = this.state
    const { factureSelected, myCompany, loading } = this.props
    console.info('lorsque on est en tablette aligner le button du showPDF a droite.');
    return (
      <div>
        <Row justify="space-between">
          <Col>
            <Breadcrumb style={{ marginBottom: '15px' }}>
              <Breadcrumb.Item href="/">
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/' + myCompany.publicId + '/TableauDeBord/'}>
                  <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/' + myCompany.publicId + '/Factures/'}>
                  <span>Factures</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{factureSelected ? factureSelected.title : 'Chargement...'}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col>
            <Button style={{ textAlign: 'right' }} onClick={() => this.setState({ showPDF: !showPDF })} >{showPDF ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} <FilePdfOutlined /></Button>
          </Col>
        </Row>

        {loading['factureSelected'] ?
          <Row type="flex" justify="center" align="top" gutter={8}>
            <Col xs={24} sm={24} md={showPDF ? 12 : 24} lg={showPDF ? 12 : 24} xl={showPDF ? 12 : 24}>
              <Bloc>
                Informations de la Facture :
                Titre, <br />
                Description, <br />
                Montants dûs<br />
              </Bloc>
              <Bloc title="Détails">
                Numéro de la facture<br />

              </Bloc>
              <Bloc title="Articles complémentaires">
                Ajouter des articles en complément au devis original
              </Bloc>
              <Bloc title="Frais de Débours">
                Ajouter des frais de débours en complément au devis original. Les frais de débours correspondent à des frais engagés par le prestataire au nom et pour le compte de son client.
                La facture de débours est alors directement établie au nom du client, sans mention du prestataire.
              </Bloc>
              <Bloc title="Paiements">
                Ajouter un paiement <br />
                Type de paiement (Accompte, Paiement),<br />
                Montant (restant/%, personalisé),<br />
                Date<br />
              </Bloc>
            </Col>
            <Col xs={24} sm={24} md={showPDF ? 12 : 0} lg={showPDF ? 12 : 0} xl={showPDF ? 12 : 0} >
              <FacturePDF />
            </Col>
          </Row>
          :
          <Loading title="Récupération des données" />
        }
      </div>
    )
  }
}

export default withRouter(withFirestore(connect(mapState)(firestoreConnect(query)(Facture))))
