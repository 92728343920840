export const calculHT = (ttc, tvaP) => {
  let tva;
  let ht;
  ht = ttc * 100 / (100 + parseFloat(tvaP));
  /*ht = ttc - tva*/
  return Math.round(ht * 100) / 100;
}

export const calculTVA = (ttc, tvaP) => {
  let tva;
  tva = ttc - calculHT(ttc, tvaP)
  return Math.round(tva * 100) / 100;
}


export const sum = (obj) => {
  var sum = 0;
  for( var el in obj ) {
    if( obj.hasOwnProperty( el ) ) {
      sum += parseFloat( obj[el] );
    }
  }
  return Math.round(sum * 100) / 100;
}

export const sumHT = (obj) => { //obj est un array de ttc20, ttc10, ttc55, ttc0
  var sum = 0;
  for( var el in obj ) {
    if( obj.hasOwnProperty(el) ) {
      sum += calculHT(
        obj[el],
        el.match(/\d/g).join("") == 55 ? 5.5 : el.match(/\d/g).join("")
    ); //on envoie la somme et le pourcentage via le type
    }
  }
  return Math.round(sum * 100) / 100;
}

export const sumTVA = (obj) => { //obj est un array de ttc20, ttc10, ttc55, ttc0
  var sum = 0;
  for( var el in obj ) {
    if( obj.hasOwnProperty(el) ) {
      sum += calculTVA(
        obj[el],
        el.match(/\d/g).join("") == 55 ? 5.5 : el.match(/\d/g).join("")
    ); //on envoie la somme et le pourcentage via le type
    }
  }
  return Math.round(sum * 100) / 100;
}
