import React from 'react'
import {Row, Col, Button, Form, Divider} from 'antd'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { TextInputWoError, SelectInputWoError } from './../../../util/Form/field'

import { updateCompanyEntities } from './../../../actions/companyActions'

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    devis: state.firestore.ordered.devis,
    initialValues: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    loading: state.form.companyEntities
})

const actions = {
  updateCompanyEntities
}

let renderEntitiesForm = ({fields}) => (
  <div>
  {fields && fields.map((field, i) =>
    <Row gutter={8} key="field">
      <Col flex="20%">
      <Field
        name={`${field}.sex`}
        labelTitle="Sexe"
        placeholder="Jacques"
        component={SelectInputWoError}
        options={[
          {value:'M', name:'Mr'},
          {value:'F', name:'Mme'},
          {value:'', name:'NN'}
        ]}
      />
      </Col>
      <Col flex="40%">
      <Field
        name={`${field}.firstName`}
        labelTitle="Prénom"
        placeholder="Jacques"
        component={TextInputWoError}
      />
      </Col>
      <Col flex="40%">
      <Field
        name={`${field}.lastName`}
        labelTitle="Nom"
        placeholder="Dupon"
        component={TextInputWoError}
      />
      </Col>
      <Col flex="100%">
        <Field
          name={`${field}.function`}
          labelTitle="Fonction"
          labelDetails="Votre fonction sera visible sur les devis et factures"
          placeholder="Chargé de Projet"
          component={TextInputWoError}
        />
      </Col>
      <Col flex="40%">
        <Field
          name={`${field}.phone`}
          labelTitle="Téléphone"
          placeholder="06 01 02 03 04"
          component={TextInputWoError}
        />
      </Col>
      <Col flex="40%">
        <Field
          name={`${field}.mail`}
          labelTitle="Mail"
          placeholder="jacques@mail.fr"
          component={TextInputWoError}
        />
      </Col>
      <Col flex="20%" style={{textAlign:'center'}}>
        <Button shape='circle' style={{marginTop:'26px'}} danger onClick={() => fields.remove(i)}>
          <DeleteOutlined />
        </Button>
      </Col>
        <Divider />
    </Row>
  )}
  <Row justify="center" gutter={8} style={{marginTop:'4px', marginBottom:'10px'}}>
    <Col>
      <Button
        type="dashed"
        onClick={() => fields.push({})}
        block
        >
        <PlusOutlined /> Ajouter une Entité
      </Button>
    </Col>
  </Row>
  </div>
)

class CompanyForm extends React.Component {

  onFormSubmit = values => {
    console.log(values);
    this.props.updateCompanyEntities(values.entities, values.id)
    .then(() => this.props.initialize(values))
  };

  render(){
    const {handleSubmit, reset, pristine, submitting, loading} = this.props
    return(
      <Form onFinish={handleSubmit(this.onFormSubmit)}>

        <FieldArray name="entities" component={renderEntitiesForm} />

        <div style={{textAlign:'right'}}>
          <Button
            onClick={reset}
            style={{ marginRight: 8 }}
            disabled={pristine || submitting}
          >
            Effacer
          </Button>
          <Button
            loading={loading && loading.submitting}
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            Sauvegarder
          </Button>
        </div>
      </Form>
    )
  }
}

export default connect(mapState, actions)(reduxForm({form:'companyEntities'})(CompanyForm))
