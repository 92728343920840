import React from 'react'
import { Breadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons';

import { Link, withRouter } from "react-router-dom";

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore, populate } from 'react-redux-firebase'

class BreadCrumb extends React.Component {
  componentDidMount(){
  }

  render(){
    const route = this.props.location.pathname && this.props.location.pathname.split("/")

    console.log("BreadCrumb ", route);

    const {devisSelected} = this.props
    return(
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined/>
        </Breadcrumb.Item>

        {route.map(element => element && (
          <Breadcrumb.Item>
            <Link to={element}>
              <span>{element}</span>
            </Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    )
  }
}

export default withRouter(BreadCrumb)
