import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAfEObZpLfi5ePf6RQf3jl9tORa__dJtpo",
  authDomain: "comptae-9ec5a.firebaseapp.com",
  databaseURL: "https://comptae-9ec5a.firebaseio.com",
  projectId: "comptae-9ec5a",
  storageBucket: "gs://comptae-9ec5a.appspot.com",
  messagingSenderId: "280321483533",
  appId: "1:280321483533:web:16531daa042fba56694644"
};

firebase.initializeApp(firebaseConfig)
firebase.firestore()

//const fireStore = firebase.firestore()
const fireStorage = firebase.storage()

export default firebase