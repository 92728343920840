import React from 'react'
import { Row, Col } from 'antd'
import './A4.css'

const Watermark = ({ logoUrl, title, subtitle, leftFooter, centerFooter, centerFooterSub, rightFooter }) => {
  return (
    <div className='watermark'>
      <Row className="page-header" justify='space-beetwen' align="middle" >
        <Col span={12}>
          {logoUrl && <img src={logoUrl} style={{ height: '1.8cm' }} />}
        </Col>

        <Col span={12}>
          <div className="right">
            <div className="title x-big">
              {title && title}
            </div>
            <div className='title small'>
              {subtitle && subtitle}
            </div>
          </div>
        </Col>
      </Row>

      <div className='page-footer x-small space-between'>
        {leftFooter && <div>{leftFooter}</div>}
        {centerFooter && <div className='center'>{centerFooter} <br /> {centerFooterSub}</div>}
        {rightFooter && <div >{rightFooter}</div>}
      </div>
    </div>
  );
}

export default Watermark;
