import React from "react"
import { Form, message, Button } from 'antd'

import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import { updatePassword } from "../../actions/authActions"
import { TextInput } from "../../util/Form/field"

import { combineValidators, isRequired, composeValidators, matchesField, hasLengthBetween } from 'revalidate'

const mapState = state => ({
  initialValues: state.firebase.profile && state.firebase.profile,
  profile: state.firebase.profile,
	loading: state.firestore.status.requested,
})

const actions = { updatePassword }

const validate = combineValidators({
  newPassword1: composeValidators(
    isRequired({message: 'Votre mot de passe est requis'}),
    hasLengthBetween(5, 30)({message:'Votre mot de passe doit être compris entre 5 et 90 charactères.'})
  )(),
  newPassword2: matchesField('newPassword1')({message:'Entrez le même mot de passe'}),
})

class FormPassword extends React.Component {
	render() {
    const { updatePassword, handleSubmit, error, invalid, profile, submitting, reset } = this.props

		return (
      <Form onFinish={handleSubmit(updatePassword)}>
        <Field
        name="newPassword1"
        labelTitle="Nouveau mot de passe"
        component={TextInput}
        type="password"
        labelColor="#fff"
        placeholder=""
        />
        <Field
        name="newPassword2"
        labelTitle="Répétition du nouveau mot de passe"
        component={TextInput}
        type="password"
        labelColor="#fff"
        placeholder=""
        />
        {error && (
          message.error(error === "This operation is sensitive and requires recent authentication. Log in again before retrying this request." ? "Cette opération est sensible, Reconnectez-vous à votre compte pour modifier le mot de passe" : error)
        )}
        <div style={{ marginTop: 20, textAlign: "right" }}>
        <Button type="primary" htmlType="submit" disabled={invalid || submitting || error} >Modifier</Button>
        </div>
      </Form>
		)
	}
}

export default connect(mapState, actions)(reduxForm({form:'myAccountPasswordForm', validate})(FormPassword))
