import React from 'react'
import { HomeOutlined, BankOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import Companies from './Companies/Companies'
import { NotAllowed } from './../../util/Errors'

import BreadCrumb from './../../util/BreadCrumb'
import FormPassword from './formPassword'

class Settings extends React.Component {
  render(){
    return(
      <div>
        <BreadCrumb/>
        <FormPassword/>
      </div>
    )
  }
}

export default Settings
