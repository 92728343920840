import firebase from './../config/firebase'
import { SubmissionError } from 'redux-form'
import { message } from 'antd'


export const registerUser = user =>
  async (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase()
    const firestore = getFirestore()
    let dateNow = Date.now();
    try {
      let usersRef = firestore.collection('users')
      let query = await usersRef.where('displayName', '==', user.email).get()
      if(query.docs.length == 0){ // si aucun utilisateur utilise cette adresse mail
        let createdUser = await firebase.auth().createUserWithEmailAndPassword(user.email, user.password)

        console.log(createdUser);
        await createdUser.user.updateProfile({
          displayName: user.name
        })
        let newUser = {
          isNewUser: createdUser.additionalUserInfo.isNewUser ,
          name: user.name,
          uid: createdUser.user.uid,
          email: user.email,
          createdAt: firestore.FieldValue.serverTimestamp(),
        }
        await firestore.set(`users/${createdUser.user.uid}`,  {... newUser})
        console.log(createdUser);
        /*let newNotification = [
          {
            idd:dateNow,
            createdAt: firestore.FieldValue.serverTimestamp(),
            uid: createdUser.user.uid,
            showInHeader: true,
            linkTo: "untropbeaulien",
            message: "Abonnez-vous des à présent à vos streamers préférés!",
            type: "info", // message, info-circle, trophy, transaction
          }
        ]
        await firestore.set(`notifications/${user.displayName}`, {... newNotification})*/
        message.success('Bienvenue sur Kompta.fr')
      }else {
        message.error(user.email +' est déjà utilisée.')
      }

    } catch (error) {
      console.log(error)
      throw new SubmissionError({
        _error : error.message
      })
    }
  }

export const login = (creds) => {
  return async (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    try {
      await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password)
      message.success('Bienvenue, vous êtes connecté')
    } catch (error) {
      console.log(error);
      throw new SubmissionError({
        _error : 'Echec : '+ error.code
      })
    }
  }
}

export const logout = (creds) => {
  return async (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();
    try {
      firebase.auth().signOut()
      message.success('Vous êtes déconnecté, A bientôt')
    } catch (error) {
      console.log(error);
      message.error('Erreur lors de la déconnexion : ', error)
    }
  }
}

export const updatePassword = (creds) =>
async (dispatch, getState, {getFirebase}) => {
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  try{
    await user.updatePassword(creds.newPassword1)
    // await dispatch(reset('myAccountPasswordForm'))
    message.success('Mot de passe maj')
  }catch (error){
    throw new SubmissionError({
      _error: error.message
    })
  }
}
