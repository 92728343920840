import React from 'react';
import { Row, Col, Breadcrumb, message, List, Popconfirm, Button, Typography, Input} from 'antd'
import { HomeOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';

import { Bloc } from './../../../util/Bloc'
import { NotAllowed } from './../../../util/Errors'

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

//import { deleteArticle } from './../../actions/clientsActions'

import ArticleForm from './CompanyForm'
import CompanyApi from './CompanyAPI'

const query = (props) => {
  return [
    {
      collection:'companies',
      where:['uid', '==', props.auth.uid],
    },
  ]
}

const mapState = (state) => ({
    auth: state.firebase.auth,
    articles: state.firestore.ordered.articles,
    loading: state.firestore.status.requested
})

const actions = {
}

class Clients extends React.Component {
  state={
    editId: null,
    cancelEdit:false,
    articlesFiltered: []
  }

  componentDidMount(){
    this.setState({
      articlesFiltered: this.props.articles
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      articlesFiltered: nextProps.articles
    });
  }

  handleChange = e => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      currentList = this.props.articles;
      newList = currentList.filter(article =>
        (article.description && article.description.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (article.title && article.title.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (article.ref && article.ref.toLowerCase().includes(e.target.value.toLowerCase()))
      )

      this.setState({
        articlesFiltered: newList
      })
    } else {
      newList = this.props.articles;
      this.setState({
        articlesFiltered: newList
      });
    }
  }

  cancelEdit = () => { //si cancelEdit alors on réinitinialise le form, d'ou désactiver cancelEdit après 10ms
    this.setState({editId: null, cancelEdit:true})
    setTimeout(() => this.setState({cancelEdit:false}), 100)
  }

  render(){
    const { loading, deleteArticle, auth } = this.props
    const { editId, articlesFiltered, cancelEdit } = this.state
    return (
      <div>
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined/>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Articles</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      {/*<ClientsTable/>*/}
      <Row type="flex" gutter={[14, 16]} style={{height:'74vh'}}>
        <Col xs={24} md={12} lg={13} xl={14} xxl={15} >
          <Bloc
            title="Vos Sociétés"
            loading={!loading['articles?where=uid:==:'+auth.uid+'&orderBy=ref']} >

            <List
              size="small"
              dataSource={articlesFiltered}
              renderItem={article =>
                <List.Item
                  style={{borderBottom:'1px solid #80808066', padding:'5px'}}
                  actions={ editId && editId === article.id ? /*Si cette modif est selectionnée */
                    [
                      <a key="cancel-edit" onClick={this.cancelEdit}><ArrowLeftOutlined /></a>,
                      <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={()=> deleteArticle(article.id)} okText="Oui" cancelText="Non">
                        <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                      </Popconfirm>
                    ]
                    :
                      !editId ? /*Si aucune modif est selectionnée */
                      [
                        <a key="list-edit" onClick={() => this.setState({editId: article.id, cancelEdit:false}) }><EditOutlined /></a>,
                        <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={()=> deleteArticle(article.id)} okText="Oui" cancelText="Non">
                          <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                        </Popconfirm>
                      ]
                      : /*Si une modif est selectionnée */
                      [
                        <EditOutlined />,
                        <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={()=> deleteArticle(article.id)} okText="Oui" cancelText="Non">
                          <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                        </Popconfirm>
                      ]
                    }
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <span style={{textTransform:'uppercase'}} >{article.ref}</span> - <b>{article.title}</b> ({article.price}€ HC/U + {article.taxes}%)</div>}
                    description={<div>{article.description}</div>}
                  />
                </List.Item>}
            />
          </Bloc>
        </Col>
        <Col xs={24} md={12} lg={11} xl={10} xxl={9}>
          <Bloc title={editId ? 'Modifier un article' : 'Ajouter un article'} >
            {/*<ArticleForm editId={editId} resetParent={() => {this.setState({editId:null})}}/>*/}
            <ArticleForm editId={editId} cancelEdit={cancelEdit} resetParent={() => {this.setState({editId:null})}}/>
          </Bloc>
        </Col>
      </Row>
      </div>
    )
  }
}

export default withFirestore(connect(mapState, actions)(firestoreConnect(query)(Clients)))
