import React from 'react'
import { Card, Form, Button, Col, Row, Alert} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'

import { postClient, updateClient } from './../../actions/clientsActions'

import { TextInput, NumberInput, SelectInput } from './../../util/Form/field'

const validate = combineValidators({
  name: composeValidators(
    isRequired({message: 'Requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(150)({message:'Trop long'})
  )(),
  zipcode: composeValidators(
    isRequired({message: 'Requis'}),
    isNumeric({message:'Nombre uniquement'}),
    hasLengthGreaterThan(4)({message:'Trop court'}),
    hasLengthLessThan(6)({message:'Trop long'})
  )(),
})

const options = [
  {value:'Mr', name:'Monsieur (Mr)'},
  {value:'Mme', name:'Madame (Mme)'},
  {value:'Dr', name:'Docteur (Dr)'},
  {value:'Me', name:'Maître (Me)'}
];

const mapState = (state, props) => {
  let initialValues = {}

  if(props.editId && state.firestore.data.clients){
    initialValues = state.firestore.data.clients[props.editId]
  } else {
    initialValues = null
  }
  return{
    initialValues,
    loading: state.form && state.form.client
  }
}

const actions = {
  postClient,
  updateClient
}

class ClientForm extends React.Component {

  componentDidUpdate(){
    if(!this.props.editId && this.props.cancelEdit){
      this.props.destroy()
    }
  }

  onFormSubmit = values => {
    if(!this.props.editId){
      this.props.postClient(values)
      .then(this.props.reset)
    }else{
      this.props.updateClient(values, this.props.editId)
      this.props.destroy()
      this.props.resetParent()
    }
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, editId, loading } = this.props
    return (
        <Form onFinish={handleSubmit(this.onFormSubmit)}> {/* this.props.handleSubmit(this.onFormSubmit) on réalise la fonction handleSubmit de Redux sur onFormSubmit*/}
          <Row gutter={16}>
            <Col span={12}>
              <Field
                name="society"
                labelTitle="Nom de la Société"
                placeholder="Air France"
                component={TextInput}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Field
                name="acronyme"
                labelTitle="Genre"
                component={SelectInput}
                options={options}
                //showSearch
                placeholder="Selectionner un genre"
              />
            </Col>
            <Col span={9}>
              <Field
                name="name"
                labelTitle="Nom"
                placeholder="Dupon"
                component={TextInput}
              />
            </Col>
            <Col span={9}>
            <Field
              name="surname"
              labelTitle="Prénom"
              placeholder="Martin"
              component={TextInput}
            />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={10}>
              <Field
                name="adress"
                labelTitle="Adresse"
                placeholder="36 bd Velten"
                component={TextInput}
              />
            </Col>
            <Col span={5}>
              <Field
                name="zipcode"
                labelTitle="CodePostal"
                placeholder="13004"
                component={TextInput}
              />
            </Col>
            <Col span={9}>
              <Field
                name="city"
                labelTitle="Ville"
                placeholder="Marseille"
                component={TextInput}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Field
                name="mail"
                labelTitle="Adresse mail"
                placeholder="contact@gmail.com"
                component={TextInput}
              />
            </Col>
            <Col span={12}>
              <Field
                name="phone"
                labelTitle="Téléphone"
                placeholder="0123456789"
                component={TextInput}
              />
            </Col>
          </Row>
          {editId &&
            <Alert
              style={{marginBottom:'10px'}}
              message="Si vous modifiez un client, toutes ses occurances seront modifiées. Par exemple les entêtes dans les devis existants seront modifiés."
              type="warning"
              showIcon
            />}
            <div style={{textAlign:'right'}}>
            <Button
              onClick={reset}
              style={{ marginRight: 8 }}
              disabled={pristine || submitting}
            >
              Effacer
            </Button>
            <Button
              loading={loading && loading.submitting}
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              {editId? 'Modifier' : 'Sauvegarder'}
            </Button>
          </div>
        </Form>
    );
  }
}

export default connect(mapState, actions)(reduxForm({form:'client',  validate})(ClientForm))
