import React from 'react'
import { Button, Divider, Radio, Tooltip } from 'antd'
import { EditOutlined, SendOutlined, CloseOutlined, CheckOutlined} from '@ant-design/icons';

import { Link} from "react-router-dom";

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

import { copyDevis, newFacture, updateState } from './../../../actions/devisActions'

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    devis: state.firestore.ordered.devis,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    loading: state.firestore.status.requested
})

const actions = {
  newFacture,
  copyDevis,
  updateState
}

class ActionBar extends React.Component {

  render(){
    const { editId, newFacture, myCompany, updateState } = this.props
    return(
      <div style={{marginBottom:'15px'}}>
        <Button style={{marginRight:'5px'}} type='primary' disabled={!editId}><Link to={editId}>Ouvrir</Link></Button>
        <Button style={{marginRight:'5px'}} disabled={!editId}>Encaissé aujourd'hui</Button>
      </div>
    )
  }
}

export default withFirestore(connect(mapState, actions)(ActionBar))
