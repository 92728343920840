import React from 'react'
import { Card, Form, Button, Col, Row, Alert} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { withRouter } from "react-router-dom";

import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {combineValidators, isRequired, matchesPattern } from 'revalidate'

import { postDepense } from './../../../actions/depensesActions'

import { calculTVA, calculHT, sum, sumHT, sumTVA } from './../../../util/Maths/calculsTVA'
import { TextInput, NumberInput, SelectInput, DatePickerInput, NumberInputWoError } from './../../../util/Form/field'

const validate = combineValidators({
  date: isRequired({message: 'Requis'}),
  type: isRequired({message: 'Requis'}),
  ttc20: matchesPattern(/^(-?[1-9]+\d*([.]\d+)?)$|^(-?0[.]\d*[1-9]+)$|^0$|^0.0$/)('Nombres uniquement'),
})

const options = [
  {value:'peage', name:'Péage'},
  {value:'carburant', name:'Carburant'},
  {value:'restauration', name:'Restauration'},
  {value:'abonnement', name:'Abonnement'},
  {value:'fourniture', name:'Fourniture'},
  {value:'investissement', name:'Investissement'}
];

const selector = formValueSelector('depense') // on récupère les values du form

const mapState = (state, props) => {
  let initialValues = {}

  if(props.editId && state.firestore.data.depenses){
    initialValues = state.firestore.data.depenses
    [props.editId]
  } else {
    initialValues = null
  }

  const values = selector(state, 'ttc20', 'ttc10', 'ttc55', 'ttc0') // on récupères les values du form
  const type = selector(state, 'type')

  return{
    initialValues,
    currentValues : values,
    type: type,
    loading: state.form && state.form.depense,
    depenses: state.firestore.ordered.depenses,
  }
}

const actions = {
  postDepense
}

const refGenerator = (lastRef) => { //AAAA-MM-NUM
  if(lastRef){ //si on a une dernière ref

  } else {

  }
}

class ClientForm extends React.Component {

  componentDidUpdate(){
    if(!this.props.editId && this.props.cancelEdit){
      this.props.destroy()
    }
  }

  onFormSubmit = values => {
    if(!this.props.editId){
      this.props.postDepense(values, this.props.match.params.idCompany)
      .then(this.props.reset)
    }else{
      this.props.updateClient(values, this.props.editId)
      this.props.destroy()
      this.props.resetParent()
    }
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, editId, loading, currentValues, type } = this.props
    console.log(currentValues);
    return (
        <Form onFinish={handleSubmit(this.onFormSubmit)}> {/* this.props.handleSubmit(this.onFormSubmit) on réalise la fonction handleSubmit de Redux sur onFormSubmit*/}
          <Row gutter={16}>
            <Col span={12}>
              <Field
                name="ref"
                labelTitle="Référence (automatique)"
                placeholder="2020-MM-REF"
                component={TextInput}
              />
            </Col>
            <Col span={12}>
            <Field
              name="date"
              labelTitle="Date"
              allowClear="true"
              placeholder="JJ-MM-AAAA"
              component={DatePickerInput}
            />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Field
                name="type"
                labelTitle="Type"
                component={SelectInput}
                options={options}
                showSearch
                onChange={this.selectType}
                placeholder="Selectionner un Type"
              />
            </Col>
            <Col span={16}>
              <Field
                name="description"
                labelTitle="Détails"
                placeholder="Péage de Marseille-Paris"
                component={TextInput}
              />
            </Col>
          </Row>

          <Row gutter={16}> {/* TTC 20% */}
            <Col span={8}>
              <Field
                name="ttc20"
                labelTitle={<div>TTC 20%</div>}
                placeholder="10.55"
                component={NumberInputWoError}
              />
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>HT</h5>
                <div>{calculHT(currentValues.ttc20, 20) ? calculHT(currentValues.ttc20, 20) : '-'} €</div>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>TVA</h5>
                <div>{calculTVA(currentValues.ttc20, 20) ? calculTVA(currentValues.ttc20, 20) : '-'} €</div>
              </div>
            </Col>
          </Row>

          {(type === 'fourniture' || type === 'restauration' ) &&
          <Row gutter={16}> {/* TTC 10% */}
            <Col span={8}>
              <Field
                name="ttc10"
                labelTitle={<div>TTC 10%</div>}
                placeholder="10.55"
                component={NumberInputWoError}
              />
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>HT</h5>
                <div>{calculHT(currentValues.ttc10, 10) ? calculHT(currentValues.ttc10, 10) : '-'} €</div>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>TVA</h5>
                <div>{calculTVA(currentValues.ttc10, 10) ? calculTVA(currentValues.ttc10, 10) : '-'} €</div>
              </div>
            </Col>
          </Row>
          }

          {(type === 'fourniture' || type === 'restauration' ) &&
          <Row gutter={16}> {/* TTC 5.5% */}
            <Col span={8}>
              <Field
                name="ttc55"
                labelTitle={<div>TTC 5.5%</div>}
                placeholder="10.55"
                component={NumberInputWoError}
              />
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>HT</h5>
                <div>{calculHT(currentValues.ttc55, 5.5) ? calculHT(currentValues.ttc55, 5.5) : '-'} €</div>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>TVA</h5>
                <div>{calculTVA(currentValues.ttc55, 5.5) ? calculTVA(currentValues.ttc55, 5.5) : '-'} €</div>
              </div>
            </Col>
          </Row>
          }

          {(type === "carburant" || type === "fourniture") &&
          <Row gutter={16}> {/* TTC 0% */}
            <Col span={8}>
              <Field
                name="ttc0"
                labelTitle={<div>TTC 0%</div>}
                placeholder="10.55"
                component={NumberInputWoError}
              />
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>HT</h5>
                <div>{currentValues.ttc0 ? currentValues.ttc0 : '-'} €</div>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>TVA</h5>
                <div>- €</div>
              </div>
            </Col>
          </Row>
          }

          <Row gutter={16} style={{marginBottom:'10px' }}> {/* Totaux */}
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>Total TTC</h5>
                <div>{sum(currentValues) ? sum(currentValues) : '-'} €</div>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>Total HT</h5>
                <div>{sumHT(currentValues) ? sumHT(currentValues) : '-' } €</div>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>Total TVA</h5>
                <div>{sumTVA(currentValues) ? sumTVA(currentValues) : '-' } €</div>
              </div>
            </Col>
          </Row>

          {(type === 'carburant') &&
            <Alert
              style={{marginBottom:'10px'}}
              message="En 2020, les utilitaires peuvent récupérer 60% de la TVA (Essence), et 100% pour le Gazole"
              type="warning"
              showIcon
            />}
            <div style={{textAlign:'right'}}>
            <Button
              onClick={reset}
              style={{ marginRight: 8 }}
              disabled={pristine || submitting}
            >
              Effacer
            </Button>
            <Button
              loading={loading && loading.submitting}
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              {editId? 'Modifier' : 'Sauvegarder'}
            </Button>
          </div>
        </Form>
    );
  }
}

export default withRouter(connect(mapState, actions)(reduxForm({form:'depense',  validate})(ClientForm)))
