import React from 'react'
import { Row, Col, Form, Input, Button, Checkbox, Alert } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import './Login.css'

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'
import { login } from './../../actions/authActions'

import { Bloc } from './../../util/Bloc'
import { TextInput } from './../../util/Form/field'
import { Loading } from './../../util/Errors'

const mapState = (state) => ({
  loading: state.form && state.form.login
})

const actions = {
login
}

const validate = combineValidators({
  email: composeValidators(
    isRequired({message: 'Votre mail est requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(35)({message:'Trop long'}),
  )(),
  password: composeValidators(
    isRequired({message: 'Votre mot de passe est requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(35)({message:'Trop long'}),
  )()
})

class LoginForm extends React.Component {
  render() {
    const { handleSubmit, loading, error, style, login, showSignIn } = this.props
    return (
      <Bloc
      style={{width:'320px', textAlign:'center', ...style}}
      >
      <Form onFinish={handleSubmit(values => login(values))} className="login-form">
        <Row>
        <Col span={24}>
          <Field
            name="email"
            component={TextInput}
            type="text"
            placeholder="Adresse mail"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Col>
        <Col span={24}>
          <Field
            name="password"
            component={TextInput}
            placeholder="Mot de passe"
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Col>
        {error && <Col span={24} style={{marginBottom:'5px'}}><Alert message={error} type="error" showIcon /></Col>}
        <Col span={24}>
          <Button loading={loading && loading.submitting} type="primary" htmlType="submit" className="login-form-button">
            Connexion
          </Button>
        </Col>
        </Row>
          <div style={{marginTop:'15px', fontSize:'0.85em'}}>
            <a>Mot de passe oublié</a> ou <a onClick={showSignIn}>Créer un compte</a>
          </div>
      </Form>
      </Bloc>
    )
  }
}

export default connect(mapState, actions)(reduxForm({form:'login',  validate})(LoginForm))
