import { Row, Col, Button, Affix } from 'antd';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Link, } from 'react-router-dom'

import { connect } from 'react-redux'
import { withFirestore, withFirebase } from 'react-redux-firebase'

import { Loading } from './../../../util/Errors'

import numeral from 'numeral'
import './../../../util/Maths/localNumeral.js'
import { calcPrixU, calcTotalArticle, calcTotalinCat, calcTotalHT, calcTVA, calcTTC, sumWamount } from './../../../util/Maths/calculsPrixDevis'

import Header from './MV3.png'
import Signature from './SignatureLG.jpg'
import Watermark from '../ReactToPrint/watermark';

const mapState = (state) => {
  let devisSelected = []
  devisSelected = state.firestore.ordered.factureSelected &&
    state.firestore.ordered.factureSelected[0] &&
    state.firestore.ordered.devis &&
    state.firestore.ordered.devis.find(
      devis => devis.id === state.firestore.ordered.factureSelected[0].devisId
    )
  let clientSelected = []
  clientSelected = state.firestore.ordered.factureSelected &&
    state.firestore.ordered.factureSelected[0] &&
    state.firestore.ordered.clients &&
    state.firestore.ordered.clients.find(
      client => client.id === state.firestore.ordered.factureSelected[0].clientId
    )

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    devisSelected,
    factureSelected: state.firestore.ordered.factureSelected && state.firestore.ordered.factureSelected[0],
    client: clientSelected,
    loading: state.firestore.status.requested
  }
}

function CSA4({ myCompany, factureSelected, devisSelected, client }) {
  const printPreviewRef = useRef();

  if (!devisSelected && !factureSelected && !myCompany && !client) return <Loading title="Ouverture du PDF" />

  const paiement = () => {
    let result = factureSelected.content.length > 0 ?
      calcTTC(calcTotalHT(factureSelected.content, factureSelected.type))
      :
      devisSelected.debours
        ? (devisSelected.tva
          ? calcTTC(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))
          : calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))
        : (devisSelected.tva
          ? calcTTC(calcTotalHT(devisSelected.content, devisSelected.type))
          : calcTotalHT(devisSelected.content, devisSelected.type));
    return result;
  }

  let contentToMap = factureSelected.content.length > 0 ? factureSelected.content : devisSelected.content;

  return (
    <>
      <ReactToPrint
        trigger={() => <div style={{ position: 'absolute', top: '-36px', right: '172px' }}><Affix offsetTop={10}><Button>Imprimer !</Button></Affix></div>}
        content={() => printPreviewRef.current}
        documentTitle={"Facture_" + factureSelected.number + "_" + devisSelected.title}
      />
      <div className="print-preview" ref={printPreviewRef}>

        <Watermark
          title={'Facture N°' + factureSelected.number}
          subtitle={'Emis le ' + factureSelected.creationDate}
          logoUrl={Header}
          leftFooter={'FACTURE N°' + factureSelected.number}
          centerFooter={myCompany.companyName + ', ' + myCompany.adress + ' ' + myCompany.zipcode + ' — EURL au capital de ' + myCompany.capital + ' €'}
          centerFooterSub={'SIRET : ' + myCompany.siret + ' — TVA : ' + myCompany.tva}
          rightFooter="."
        />

        <div className='page-content'>
          {/*--- BLOC DE HEADER --- */}
          <div style={{ margin: '40px 0 50px 0' }}>
            {/*--- BLOCS DE CLIENT + SUIVI DE DOSSIER --- */}
            <Row >
              <Col span={10}>
                <div className="small up marBot10">Suivi de dossier :</div>
                <span style={{ paddingTop: '10px' }}>
                  {myCompany.entities[0].firstName ? myCompany.entities[0].firstName : 'Prénom'} {myCompany.entities[0].lastName ? myCompany.entities[0].lastName : 'Nom'} </span><br />
                <span >{myCompany.entities[0].function ? myCompany.entities[0].function : "Fonction"}</span> <br />
                <span >
                  <Link src={'mailto:' + myCompany.entities[0].mail ? myCompany.entities[0].mail : 'mailAdress'} >
                    {myCompany.entities[0].mail ? myCompany.entities[0].mail : "adress mail"}
                  </Link></span> <br />
                <span>{myCompany.entities[0].phone && myCompany.entities[0].phone}</span> <br />
              </Col>
              <Col span={14} className="font">
                {client ?
                  <div className="border pad10" style={{ flexDirection: 'column' }}>
                    <div className=" bold up">{client.society} </div>
                    <div >{client.acronyme} <span className='up'>{client.name}</span> {client.surname} </div>
                    <div >{client.adress}</div>
                    <div >{client.zipcode} {client.city}</div>
                    <div >
                      < Link src={'mailto:' + client.mail ? client.mail : 'mailAdress'} >
                        {client.mail ? client.mail : "adress mail"}
                      </Link>
                    </div>
                    {client.phone && <div> {client.phone}</div>}
                  </div>
                  :
                  <div>
                    Aucun Client Sélectionné
                  </div>
                }
              </Col>
            </Row>
          </div >

          {/*--- BLOC CONTENT --- */}
          < div className='marBot20' >

            {/* --- BLOC TITLE + SECTION + TOTAL */}
            < div className='border marBot30' >
              <div className='borderBot pad10'>
                <div className="title center">{factureSelected.title ? factureSelected.title : devisSelected.title} {devisSelected.eventDate && (" – le " + devisSelected.eventDate)} </div>
                <div className='small center'>Cette facture concerne {devisSelected.details}. Facture coforme au devis N°{devisSelected.number} émis le {devisSelected.creationDate}</div>
              </div>
              <div className="space-between">
                <div className='pad10 column-bottom' style={{ width: '50%', borderRight: '1px solid black' }} >
                  <div className='up bold x-small space-between'>
                    <div>Votre Facture</div>
                    <div>détail page suivante</div>
                  </div>
                  {contentToMap.map((cat, i) =>
                    <div key={i} className="space-between marTop5 small">
                      <div >{cat.title}</div>
                      <div className='right'>{numeral(calcTotalinCat(cat.articles, factureSelected.type)).format('0,0.00 $')}</div>
                    </div>
                  )}

                  {devisSelected.debours &&

                    <div className="marTop5 small">

                      <div >
                        &nbsp;
                      </div>
                      {devisSelected.debours.map((frais, k) =>
                        <div className='space-between' key={k}>
                          <div>{frais.title}</div>
                          <div>
                            - {numeral(frais.amount).format('0,0.00 $')}
                          </div>
                        </div>
                      )}


                    </div>
                  }
                </div>
                <div className='pad10 column-bottom' style={{ width: '50%', }} >
                  <div className='space-between bold'>
                    <div>TOTAL HT</div>
                    <div>
                      {factureSelected.content.length == 0 ?
                        devisSelected.debours ?
                          numeral(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours)).format('0,0.00 $') :
                          numeral(calcTotalHT(devisSelected.content, devisSelected.type)).format('0,0.00 $')
                        :
                        numeral(calcTotalHT(contentToMap, factureSelected.type)).format('0,0.00 $')
                      }
                    </div>
                  </div>
                  {devisSelected.tva ?
                    <div className='space-between'>
                      <div>TVA (20%)</div>
                      <div>
                        {factureSelected.content.length == 0 ?
                          devisSelected.debours ?
                            numeral(calcTVA(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))).format('0,0.00 $') :
                            numeral(calcTVA(calcTotalHT(devisSelected.content, devisSelected.type))).format('0,0.00 $')
                          :
                          numeral(calcTVA(calcTotalHT(contentToMap, factureSelected.type))).format('0,0.00 $')
                        }
                      </div>
                    </div>
                    :
                    <div className='space-between'>
                      <div >TVA (0%)<br />
                        <span class='small'>Exonération (Art.262 ter I du CGI)</span></div>
                      <div> {numeral(0).format('0,0.00 $')}</div>
                    </div>
                  }
                  <div className='right borderBot' style={{ width: '50%', marginLeft: '50%' }} >
                    &nbsp;
                  </div>
                  <div className='space-between bold'>
                    <div>TOTAL TTC</div>
                    <div>
                      {numeral(paiement()).format('0,0.00 $')}
                    </div>
                  </div>
                </div>
              </div>
            </div >

            {/* BLOC DE PAIEMENT */}
            < div className='border marBot10 space-between' >
              <div style={{ width: '50%', borderRight: '1px solid black' }} className="x-small pad10 up column-bottom">
                <div className='bold' >Paiement par virement bancaire</div>
                <div><b>IBAN</b> : {myCompany.iban && myCompany.iban}</div>
                <div><b>BIC</b> : {myCompany.bic && myCompany.bic}</div>
                <div><b>Délai de paiement</b> : 30 jours</div>
              </div>
              <div style={{ width: '50%' }} className='pad10 column-bottom'>
                {Array.isArray(factureSelected.paiement) ?
                  <div >
                    {factureSelected.paiement.map(el =>
                      <div className='space-between'>
                        <div className="up">{el.type} ({el.date})</div>
                        <div>- {numeral(el.amount).format('0,0.00 $')}</div>
                      </div>
                    )}
                    <div className='right borderBot' style={{ width: '50%', marginLeft: '50%' }} >
                      &nbsp;
                    </div>
                    <div className='space-between bold up'>
                      <div>Reste à payer</div>
                      <div>{numeral(paiement() - sumWamount(factureSelected.paiement)).format('0,0.00 $')}</div>
                    </div>
                  </div>
                  :
                  <div className='space-between bold'>
                    <div>Paiement (100,00 %)</div>
                    <div>{numeral(paiement()).format('0,0.00 $')}</div>
                  </div>
                }
              </div>
            </div >

            {/*--- BLOC D'INFOS DE PAIEMENT --- */}
            < div className='xx-small justify hideOnScreen' >
              {devisSelected.footer}
            </div >
          </div >

          {/*--- BLOC SIGNATURE--- */}
          < div className='hideOnScreen' >
            <div className='space-between'>
              <div className='column center '>
              </div>
              <div className='column right'>
                <div className='center'>{myCompany.entities[0].firstName} {myCompany.entities[0].lastName}</div>
                <img src={Signature} style={{ height: '2.7cm' }} />
              </div>
            </div>
          </div >
        </div >

        <div className="newPage"> </div>

        <div className='page-content'>
          <div className='border small marBot10'>
            {/* --- HEADER --- */}
            <div className='borderBot space-between' style={{ padding: '3px 0px 3px 0px' }}>
              <div className='center wtab1'>
                Ref.
              </div>
              <div className='wtab3'>
                Description
              </div>
              <div className='center wtab1'>
                Q
              </div>
              <div className='center wtab2'>
                Prix U.
              </div>
              <div className='center wtab1'>
                R%
              </div>
              <div className='center wtab2'>
                Total
              </div>
            </div>
            {/* --- CONTENT --- */}
            <>
              {
                contentToMap.map((cat, i) =>
                  <>
                    <div key={i} style={{ display: 'block' }} className={i === devisSelected.split ? "newPage" : i === devisSelected.split + 1 ? "top3" : ""}>
                      {cat.title &&
                        <div className=" up bold center" style={{ padding: '1px' }}>
                          <div className="pad5" style={{ backgroundColor: '#fcb2a7' }}>{cat.title && cat.title}</div>
                        </div>
                      }
                      {cat && cat.articles.map((art, i) =>
                        <div key={i} className='space-between' style={{ alignItems: 'center', padding: '2px 10px 2px 10px' }}>
                          <div className='wtab1 xx-small grey'>
                            {art.articleDetails.ref}
                          </div>
                          <div className='wtab3 ' >
                            {art.desc ? art.desc :
                              <div>
                                <div>{art.articleDetails.title}</div>
                                {art.articleDetails.description &&
                                  art.articleDetails.description !== "" &&
                                  <div className='xx-small grey'>
                                    {art.articleDetails.description}
                                  </div>

                                }
                              </div>
                            }
                          </div>
                          <div className='wtab4 right'>
                            {art.q}
                          </div>
                          <div className='wtab5'>
                            &nbsp;{art.u}
                          </div>
                          <div className='wtab2 right'>
                            {numeral(calcPrixU(art.articleDetails.price, art.articleDetails.taxes, factureSelected.type)).format('0,0.00 $')}
                          </div>
                          <div className='wtab1 right'>
                            {art.r != 0 ? art.r + ' %' : '-'}
                          </div>
                          <div className='wtab2 right '>
                            {art.r != 100 ? numeral(calcTotalArticle(calcPrixU(art.articleDetails.price, art.articleDetails.taxes, factureSelected.type), art.q, art.r)).format('0,0.00 $') : <div style={{ fontSize: '0.32cm' }}>OFFERT</div>}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
            </>
          </div>
          <div style={{ width: '50%', marginLeft: '50%' }}>
            <div className='space-between bold border pad10' >
              <div>TOTAL</div>
              <div>{numeral(calcTotalHT(contentToMap, factureSelected.type)).format('0,0.00 $')}</div>
            </div>
          </div>
        </div>

      </div >
    </ >
  );
};

export default withFirebase(withFirestore(connect(mapState)(CSA4)))
