import React from 'react'
import { Row, Col, Button, Form, Divider } from 'antd'

import { connect } from 'react-redux'
import { withFirebase } from 'react-redux-firebase'

import { Field, reduxForm } from 'redux-form'
import { composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric } from 'revalidate'

import { TextInput, SelectInput } from './../../../util/Form/field'

const validate = combineValidators({
  siren: composeValidators(
    isRequired({ message: 'Requis' }),
    isNumeric({ message: 'Nombre seulement' }),
    hasLengthGreaterThan(8)({ message: 'Trop court' }),
    hasLengthLessThan(10)({ message: 'Trop long' })
  )(),
  nic: composeValidators(
    isRequired({ message: 'Requis' }),
    isNumeric({ message: 'Nombre seulement' }),
    hasLengthGreaterThan(4)({ message: 'Trop court' }),
    hasLengthLessThan(6)({ message: 'Trop long' })
  )(),
  adress: isRequired({ message: 'Requis' }),
  zipcode: composeValidators(
    isRequired({ message: 'Requis' }),
    isNumeric({ message: 'Nombre seulement' }),
    hasLengthGreaterThan(4)({ message: 'Trop court' }),
    hasLengthLessThan(6)({ message: 'Trop long' })
  )(),
  city: composeValidators(
    isRequired({ message: 'Requis' }),
    hasLengthGreaterThan(3)({ message: 'Trop court' }),
    hasLengthLessThan(25)({ message: 'Trop long' })
  )(),
  firstName: composeValidators(
    isRequired({ message: 'Requis' }),
    hasLengthGreaterThan(3)({ message: 'Trop court' }),
    hasLengthLessThan(25)({ message: 'Trop long' })
  )(),
  lastName: composeValidators(
    isRequired({ message: 'Requis' }),
    hasLengthGreaterThan(3)({ message: 'Trop court' }),
    hasLengthLessThan(25)({ message: 'Trop long' })
  )(),
})

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  devis: state.firestore.ordered.devis,
  initialValues: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  loading: state.firestore.status.requested
})

const actions = {

}


class CompanyForm extends React.Component {

  render() {
    const { reset, pristine, submitting, loading } = this.props
    return (
      <Form>
        <Row gutter={8}>
          <Col flex="40%">
            <Field
              name="siren"
              labelTitle="SIREN"
              placeholder="111222333"
              component={TextInput}
            />
          </Col>
          <Col flex="30%">
            <Field
              name="nic"
              labelTitle="NIC"
              placeholder="12345"
              component={TextInput}
            />
          </Col>
          <Col flex="30%">
            <Field
              name="creationDate"
              labelTitle="Creation"
              placeholder="DD/MM/YYYY"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="auto">
            <Field
              name="companyName"
              labelTitle="Nom de la société"
              labelDetails="Ce nom sera visible sur les devis et factures"
              placeholder="Apple"
              component={TextInput}
            />
          </Col>
          <Col flex="20%">
            <Field
              name="code"
              labelTitle="APE"
              placeholder="71.11Z"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="50%">
            <Field
              name="adress"
              labelTitle="Adresse"
              placeholder="15 rue des capucins"
              component={TextInput}
            />
          </Col>
          <Col flex="50%">
            <Field
              name="adressComplement"
              labelTitle="Complément"
              placeholder="Lieu dit des patates"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="30%">
            <Field
              name="zipcode"
              labelTitle="Code Postal"
              placeholder="13000"
              component={TextInput}
            />
          </Col>
          <Col flex="70%">
            <Field
              name="city"
              labelTitle="Ville"
              placeholder="Marseille"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="30%">
            <img src={this.props.initialValues.logoUrl} width="100%" name="logo" />
          </Col>
          <Col flex="70%">
            <Field
              name="logoUrl"
              labelTitle="Lien logo d'entête"
              placeholder="http://..."
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="50%">
            <Field
              name="societyType"
              labelTitle="Forme Juridique"
              labelDetails="Actuellement seuls les AE sont pris en charge"
              component={SelectInput}
              options={[
                { value: 'AE', name: 'Auto-Entreprise' },
                { value: "SARL", name: 'SARL' },
                { value: "EURL", name: 'EURL' },
                { value: "SAS", name: 'SAS' },
                { value: "SASU", name: 'SASU' },
                { value: "Association", name: 'Association' }
              ]}
            />
          </Col>
          <Col flex="50%">
            <Field
              name="capital"
              labelTitle="Capital Social"
              placeholder="1 000€"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="100%">
            <Field
              name="tva"
              labelTitle="Numéro TVA"
              placeholder="FR11 111 222 333"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="30%">
            <Field
              name="commercant"
              labelTitle="Commercant"
              component={SelectInput}
              options={[
                { value: 'true', name: 'Oui' },
                { value: 'false', name: 'Non' },
              ]}
            />
          </Col>
          <Col flex="70%">
            <Field
              name="RCSCity"
              labelTitle="Lieu d'immatriculation"
              placeholder=" Paris"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col flex="30%">
            <Field
              name="artisan"
              labelTitle="Artisan"
              component={SelectInput}
              options={[
                { value: 'true', name: 'Oui' },
                { value: 'false', name: 'Non' },
              ]}
            />
          </Col>
          <Col flex="70%">
            <Field
              name="RM"
              labelTitle="RM"
              placeholder=" Paris"
              component={TextInput}
            />
          </Col>
        </Row>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={reset}
            style={{ marginRight: 8 }}
            disabled={pristine || submitting}
          >
            Effacer
          </Button>
          <Button
            loading={loading && loading.submitting}
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            Sauvegarder
          </Button>
        </div>
      </Form>
    )
  }
}

export default withFirebase(connect(mapState, actions)(reduxForm({ form: 'companySettings', validate })(CompanyForm)))
