import React from 'react';
import './App.css';

import { Layout } from 'antd';

import { Route, Link, Switch } from "react-router-dom";

import HomeLogin from './Home/HomeLogin'
import HomeLogged from './Home/HomeLogged.js'

import Menu from './Menu/index.js'
import Articles from './Articles/Articles'
import Clients from './Clients/Clients'
import Company from './MyCompany/'
import Sub from './Subscription/Sub'
import Settings from './Settings/Settings'

import { Loading } from './../util/Errors'

import { connect } from 'react-redux'
//import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

const { Header, Content, Footer } = Layout;

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
})

const actions = {}

class App extends React.Component {
  render() {
    //console.clear();
    const { auth, profile } = this.props
    const authenticated = auth.isLoaded && !auth.isEmpty;
    if (auth.isLoaded) {
      if (!authenticated) {
        return (
          <HomeLogin />
        )
      } else {
        return (
          <Layout className="fullHeight">
            <Menu />
            <Layout>

              <Content style={{ margin: '24px 16px 0' }}>
                <Switch>
                  <Route path="/" exact component={HomeLogged} />
                  <Route path="/Articles/" exact component={Articles} />
                  <Route path="/Clients/" exact component={Clients} />
                  <Route path="/Abonnement/" exact component={Sub} />
                  <Route path="/Parametres/" exact component={Settings} />
                  <Route path="/:idCompany/" component={Company} />
                </Switch>
              </Content>
              {/*<Footer style={{ textAlign: 'center' }}>Kompta.fr fais avec <HeartOutlined /> par un entrepeneur pour des entrepeneurs</Footer>*/}
            </Layout>
          </Layout>
        )
      }
    } else {
      return <Loading title="Connexion" />
    }
  }
}

//export default withFirestore(connect(mapState, actions)(firestoreConnect(query)(App)))
export default connect(mapState, actions)(App)
