export const calcPrixU = (prixSelected, taxeIntSelected, chargeSelected) => {
  let prixHC = parseFloat(prixSelected)
  let taxeInt = taxeIntSelected ? parseFloat(taxeIntSelected) : 0
  let charge = chargeSelected ? parseFloat(chargeSelected) : 0
  let prix

  prix = prixHC + (prixHC * taxeInt / 100) // (20€ et 10%) > 20 + (20*10/100) = 22
  prix = prix + (prix * charge / 100)
  prix = Math.round(prix * 100) / 100

  return prix
}

export const calcTotalArticle = (prixSelected, qSelected, rSelected) => {
  let somme
  let totalHC
  let total
  let prix = parseFloat(prixSelected)
  let quantite = qSelected ? parseFloat(qSelected) : 1
  let remise = rSelected ? parseFloat(rSelected) : 0
  somme = prix * quantite
  totalHC = somme - (somme * remise / 100)
  total = Math.round(totalHC * 100) / 100

  return total
}

export const calcTotalHT = (content, charge) => {
  let sum = 0;
  let sommeInCat = content && content.map(inCat =>
    parseFloat(calcTotalinCat(inCat.articles, charge))
  )
  sum = sommeInCat && sommeInCat.reduce((total, art) => total + art, 0)
  sum = Math.round(sum)  /* ! ICI A COMMENTER POUR ARRONDIR OU NON ! */
  return sum
}

export const sumWamount = (content) => { //
  let sum = 0;
  let sommeInCat = content && content.map(inCat =>
    parseFloat(inCat.amount)
  )
  sum = sommeInCat && sommeInCat.reduce((total, art) => total + art, 0)
  sum = Math.round(sum * 100) / 100
  return sum
}

export const calcTotalinCat = (articlesList, charge) => {
  let sum = 0
  let prixArticles = articlesList && articlesList.map(art =>
    parseFloat(calcTotalArticle(calcPrixU(art.articleDetails.price, art.articleDetails.taxes, charge), art.q, art.r))
  )
  sum = prixArticles && prixArticles.reduce((total, art) => total + art, 0)
  //sum = Math.round(sum);
  return sum
}

export const calcTVA = (totalHT) => {
  let tva = 0
  tva = totalHT * 0.2
  return tva
}

export const calcTTC = (totalHT) => {
  let ttc = 0
  ttc = totalHT * 0.2 + totalHT
  return ttc
}


export const calcValeur = (articlesList) => {
  let result = 0
  let prixArticles = articlesList && articlesList.map(art => parseFloat(art.price))
  result = prixArticles && prixArticles.reduce((total, art) => total + art, 0)
  return
}