import React from 'react'
import {Breadcrumb, message, Spin, Tabs, Row, Col} from 'antd'
import { HomeOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import { connect } from 'react-redux'

import { Bloc } from './../../../util/Bloc'
import { NotAllowed } from './../../../util/Errors'

import CompanyForm from './CompanyForm'
import EntitiesForm from './EntitiesForm'
import DevisDetails from './DevisDetails'
import FacturesDetails from './FacturesDetails'

const { TabPane } = Tabs;

const mapState = (state) => ({
  myCompany : state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
})

class SettingsCompany extends React.Component {
  state = {
    uid:null,
  }

  render(){
    const { myCompany } = this.props
    if(!this.state.uid){
    return(
      <div>
        <Breadcrumb style={{marginBottom:'15px'}}>
          <Breadcrumb.Item href="/">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={'/'+myCompany.publicId+'/TableauDeBord/'}>
              <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Paramêtres</span>
          </Breadcrumb.Item>
        </Breadcrumb>


        <Row gutter={8}>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Bloc title='La Société'>
            <CompanyForm />
          </Bloc>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Bloc title="Les Entités">
            <EntitiesForm />
          </Bloc>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Bloc title="Détails devis types">
            <DevisDetails />
          </Bloc>
          <Bloc title="Détails factures types" style={{marginTop:'10px'}}>
            <FacturesDetails />
          </Bloc>
        </Col>
        </Row>

        {/*<Tabs defaultActiveKey="1" tabPosition="left" size="large" >
          <TabPane tab="L'entreprise" key="1">
            Nom de l'entreprise <br/>
            Lien du Logo<br/>
            Adresse siège social <br/>
            Code postal,<br/>
            Ville, <br/>
            SiteWeb <br/>
            Siren/Siret<br/>
            Société? MicroEntreprise, EURL, SARL, SA, SNC, SAS <br/>
            montant du Capital Social <br/>
            TVA? Oui/Non <br/>
            N°TVA <br/>
            Commercant? Oui/Non<br/>
            N° RCS + Ville de la Greffe<br/>
            Artisan? Oui/Non <br/>
            N° Répertoire Métiers + N°Département Immatriculation
          </TabPane>
          <TabPane tab="L'entité" key="2">
            Nom, <br/>
            Prénom, <br/>
            Fonction <br/>
            Numéro de Tel <br/>
            Mail <br/>
          </TabPane>
          <TabPane tab="Les devis/factures" key="3">
            TextBottom<br/>
            Délais de Paiement<br/>
            % de surfacturation<br/>
          </TabPane>
          <TabPane tab="La compta" key="4">
            Type de Régime TVA<br/>

          </TabPane>
        </Tabs>*/}
      </div>
    )
  }else{
    return <NotAllowed/>
    }
  }
}

export default connect(mapState)(SettingsCompany)
