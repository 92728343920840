import React from 'react'
import {Row, Col, Button, Form, Breadcrumb, List, Divider} from 'antd'
import { HomeOutlined, ArrowsAltOutlined, MinusCircleOutlined, CaretRightOutlined, PlusOutlined, InfoCircleOutlined,  DownSquareOutlined, RightOutlined } from '@ant-design/icons';

import { withRouter, Link } from "react-router-dom";

import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form'
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, withFirestore } from 'react-redux-firebase'

import { updateOkr } from './../../../actions/okrActions'

import { Bloc } from './../../../util/Bloc'
import { TextInputWoError, SelectInputWoError} from './../../../util/Form/field'

const actions={
  updateOkr
}

const mapState = (state, props) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    initialValues: state.firestore.ordered.okr && state.firestore.ordered.okr[props.idOkrS],
    loading: state.firestore.status.requested,
    form: 'okrForm'+props.idOkrS,
})

let renderActionsForm = ({fields}) => (
  <div>
  {fields && fields.map((action, i) => // la suite se passe dans ArticlesFormField pour rerendre seulement le field et pas la map entière
    <Row type="flex" key={i} justify="space-around" gutter={4}>
    <Col span={1}></Col>
    <Col span={1} style={{marginTop:'10px', textAlign:'center'}}>
      <CaretRightOutlined />
    </Col>
      <Col span={18}>
        <Field
          style={{
            backgroundColor:'#f000',
            padding:0,
            border: '0px solid #d9d9d9'
          }}
          name={`${action}.title`}
          placeholder="Action à mettre en oeuvre"
          component={TextInputWoError}
        />
      </Col>
      <Col span={2}>
        <Field
          name={`${action}.isFinished`}
          defaultValue="En cours"
          options={[{key:0, value:false, name:"En cours"},{key:1, value:true, name:"Terminé"}]}
          component={SelectInputWoError}
        />
      </Col>
      <Col span={2} style={{marginTop:'10px', textAlign:'center'}}>
        <MinusCircleOutlined
          onClick={() => fields.remove(i)}
        />
      </Col>
    </Row>
  )}

  <Row justify="center" gutter={8} style={{marginTop:'4px'}}>

    <Col>
      <Button
        type="dashed"
        onClick={() => fields.push({})}
        block
        >
        <PlusOutlined /> Action
      </Button>
    </Col>
  </Row>

  </div>
)

let renderSolsForm = ({fields}) => (
  <>
    {fields.map((field, i) => (
      <div key={i} i={i}>
        <Row type="flex" key={i} justify="space-around" gutter={4}>
        <Col span={1} style={{marginTop:'12px', textAlign:'center'}}>
          <RightOutlined />
        </Col>
        <Col span={19}>
          <Field
            style={{
              backgroundColor:'#f000',
              fontSize:'1.2em',
              padding:0,
              border: '0px solid #d9d9d9',
              borderBottom:'1px solid #d9d9d9'
            }}
            name={`${field}.title`}
            placeholder="Solution"
            component={TextInputWoError}
          />
        </Col>
        <Col span={2}>
          Progression
        </Col>
        <Col span={2} style={{marginTop:'10px', textAlign:'center'}}>
          <MinusCircleOutlined
            onClick={() => fields.remove(i)}
          />
        </Col>
        </Row>

        <FieldArray name={`${field}.actions`} component={renderActionsForm} />

      </div>
    ))}

  <Row justify="center" gutter={8} style={{marginTop:'4px'}}>
    <Col>
      <Button
        type="dashed"
        onClick={() => fields.push({})}
        block
        >
        <PlusOutlined /> Solution
      </Button>
    </Col>
  </Row>

  </>
)

class TheOkrForm extends React.Component {

  onFormSubmit = values => {
    this.props.updateOkr(values).then(() => this.props.initialize(values))
  };

  render(){
    const { handleSubmit, myCompany, okr, pristine, submitting, loading } = this.props
    return(
      <>

        <Form onFinish={handleSubmit(this.onFormSubmit)} autoComplete="off">
        <Row type="flex" gutter={[8,10]} justify="space-around" align="middle" style={{marginBottom:'20px'}}>
          <Col xs={24} sm={24} md={23} lg={22} xl={20} key="1">

            <Bloc title={
              <Field
                style={{
                  backgroundColor:'#f000',
                  color:'white',
                  fontSize:'1.3em',
                  border: '0px solid #d9d9d9'
                }}
                name="title"
                placeholder="Titre de l'OKR"
                component={TextInputWoError}
              />}>

            <FieldArray name="solutions" component={renderSolsForm} />

            </Bloc>

            <div style={{textAlign:'center', marginTop:'10px'}}>
              <Button
                loading={loading && loading.submitting}
                type="primary"
                htmlType="submit"
                disabled={pristine || submitting}
              >
                Sauvegarder
              </Button>
            </div>

          </Col>
        </Row>
        </Form>

      </>
    )
  }
}

export default
  withFirestore(
    connect(mapState, actions)(
      reduxForm()
      (TheOkrForm)
    )
  )
