import firebase from './../config/firebase'
import { message } from 'antd'
import { calcTotalHT } from './../util/Maths/calculsPrixDevis'

import { nextId } from './../util/idGenerator'

import moment from 'moment'


export const newDevis = (idCompany, publicId, settingsDevis) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    let dateNow = Date.now();
    let newDevis = {}
    const companyRef = firestore.collection('companies').doc(idCompany) // a modifier a terme pour seulement publicId
    const { lastNumber, ...rest } = settingsDevis
    newDevis = {
      id: String(dateNow),
      clientId: null,
      compId: publicId,
      split: 2,

      title: 'Titre du Devis',
      details: '',
      number: nextId(settingsDevis.lastNumber, settingsDevis.lastNumberFormat),
      statut: 'redaction',
      creationDate: null,
      content: [],

      type: settingsDevis.type ? settingsDevis.type : 0,
      tva: settingsDevis.tva ? settingsDevis.tva : false,
      paiement: settingsDevis.paiement ? settingsDevis.paiement : 1,
      footer: settingsDevis.footer,
    }
    try {
      await firestore.set(`devis/${dateNow}`, { ...newDevis })
      await companyRef.update({
        settingsDevis: {
          lastNumber: nextId(settingsDevis.lastNumber, settingsDevis.lastNumberFormat),
          ...rest
        }
      })
      message.success('Nouveau devis créé')
    } catch (e) {
      message.error(e)
    }
  }

export const newFacture = (idCompany, publicId, settingsDevis, settingsFactures) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    let dateNow = Date.now();
    let newFact = {}
    const companyRef = firestore.collection('companies').doc(idCompany) // a modifier a terme pour seulement publicId
    const { lastNumber, ...rest } = settingsDevis
    newFact = {
      id: String(dateNow),
      clientId: null,
      compId: publicId,

      title: 'Titre de la Facture',
      details: '',
      number: nextId(settingsDevis.lastNumber, settingsDevis.lastNumberFormat),
      statut: 'redaction',
      creationDate: null,
      content: [],

      type: settingsDevis.type ? settingsDevis.type : 0,
      tva: settingsDevis.tva ? settingsDevis.tva : false,
      paiement: settingsDevis.paiement ? settingsDevis.paiement : 1,
      footer: settingsFactures.footer,
    }
    try {
      await firestore.set(`factures/${dateNow}`, { ...newFact })
      await companyRef.update({
        settingsDevis: {
          lastNumber: nextId(settingsFactures.lastNumber, settingsFactures.lastNumberFormat),
          ...rest
        }
      })
      message.success('Nouvelle facture créée')
    } catch (e) {
      message.error(e)
    }
  }

export const newFactureFromDevis = (idCompany, publicId, data, settingsDevis, settingsFactures) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    let dateNow = Date.now();
    let today = moment().format('DD/MM/YYYY');
    let newFact = {}
    const companyRef = firestore.collection('companies').doc(idCompany) // a modifier a terme pour seulement publicId
    const devisRef = firestore.collection('devis').doc(data.id)
    const { lastNumber, ...rest } = settingsDevis
    newFact = {
      id: String(dateNow),
      clientId: null,
      compId: publicId,
      devisId: data.id,
      clientId: data.clientId,

      title: data.title,
      details: data.details,
      number: nextId(settingsFactures.lastNumber, settingsFactures.lastNumberFormat),
      statut: 'redaction',
      creationDate: today,
      content: [],

      type: settingsDevis.type ? settingsDevis.type : 0,
      tva: settingsDevis.tva ? settingsDevis.tva : false,
      totalHT: data.totalHT,
      paiement: settingsDevis.paiement ? settingsDevis.paiement : 1,
      footer: settingsFactures.footer,
    }
    try {
      await firestore.set(`factures/${dateNow}`, { ...newFact })
      await companyRef.update({
        settingsFactures: {
          lastNumber: nextId(settingsFactures.lastNumber, settingsFactures.lastNumberFormat),
          ...rest
        }
      })
      await devisRef.update({
        statut: 'factured',
        factureId: String(dateNow)
      })
      message.success('Nouvelle facture créée')
    } catch (e) {
      message.error(e)
    }
  }

export const newFactureAcompte = (idCompany, publicId, data, settingsDevis, settingsFactures) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    let dateNow = Date.now();
    let today = moment().format('DD/MM/YYYY');
    let newFact = {}
    const companyRef = firestore.collection('companies').doc(idCompany) // a modifier a terme pour seulement publicId
    const { lastNumber, ...rest } = settingsDevis
    newFact = {
      id: String(dateNow),
      clientId: null,
      compId: publicId,
      devisId: data.id,
      clientId: data.clientId,

      title: "Facture d'Acompte sur devis " + data.title,
      details: data.details,
      number: nextId(settingsFactures.lastNumber, settingsFactures.lastNumberFormat),
      statut: 'redaction',
      creationDate: today,
      content: [{
        title: "Prestation",
        articles: [{
          r: "0",
          q: "1",
          u: 'u',
          articleDetails: {
            price: data.totalHT / 2,
            ref: "ACO",
            taxes: 0,
            title: 'Acompte 50% sur devis',
          }
        }],
      }],
      type: 0,
      tva: settingsDevis.tva ? settingsDevis.tva : false,
      totalHT: data.totalHT / 2,
      paiement: 1,
      footer: settingsFactures.footer,
    }
    try {
      await firestore.set(`factures/${dateNow}`, { ...newFact })
      await companyRef.update({
        settingsFactures: {
          lastNumber: nextId(settingsFactures.lastNumber, settingsFactures.lastNumberFormat),
          ...rest
        }
      })
      message.success('Nouvelle facture créée')
    } catch (e) {
      message.error(e)
    }
  }

export const inDateFacture = (id, date) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const firebase = getFirebase();

    const factureRef = firestore.collection('factures').doc(id)
    try {
      await factureRef.update({
        inDate: date
      })
      message.success('Statut mis à jours')
    } catch (e) {
      message.error(e)
    }
  }

export const copyDevis = (data, id) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    let dateNow = Date.now();
    let newDevis = {}
    newDevis = {
      id: dateNow,
      ...data
    }
    try {
      await firestore.set(`devis/${dateNow}`, { ...newDevis })
      message.success('Nouveau devis créé')
    } catch (e) {
      message.error(e)
    }
  }

export const updateState = (id, value) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const firebase = getFirebase();
    const devisRef = firestore.collection('devis').doc(id)
    try {
      await devisRef.update({
        statut: value
      })
      message.success('Statut mis à jours')
    } catch (e) {
      message.error(e)
    }
  }

export const updateDevisInformations = (data, id) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const firebase = getFirebase();
    const devisRef = firestore.collection('devis').doc(id)
    const { content, totalHT, ...infos } = data
    try {
      await devisRef.update({
        ...infos
      })
      message.success('Informations mises à jours')
    } catch (e) {
      message.error(e)
    }
  }

export const updateDevisArticles = (data, id, articlesData, charges) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const firebase = getFirebase();
    const devisRef = firestore.collection('devis').doc(id)

    console.log(data, id, articlesData, charges);

    /* Traitement des données*/
    let articlesEdited = []
    let contentEdited = data &&
      data.map(catList => { //list des Categories
        let { articles, ...infoCategory } = catList
        let articlesSelected = articles && articles.map(
          articleSelected => {
            let articleMatched = articlesData.find(art => art.id === articleSelected.article)
            let { u, q, r, ...rest } = articleSelected
            u = u ? u : "u"
            q = q ? q : "1"
            r = r ? r : "0"
            if (articleMatched) {
              return ({
                q, r, u,
                ...rest, //infosArticleForm
                articleDetails: articleMatched && articleMatched, //infosArticles
              })
            } else {
              return ({
                ...articleSelected,
                q: 0,
                r: 0,
                articleDetails: {
                  price: 0
                }
              })
            }
          }
        )
        return ({
          articles: articlesSelected,
          ...infoCategory
        })
      })


    try {
      await devisRef.update({
        content: contentEdited,
        totalHT: calcTotalHT(contentEdited, charges)
      })
      message.success('Articles mis à jours')
    } catch (e) {
      message.error(e)
    }
  }

export const pinElement = (id, state) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()
    const devisRef = firestore.collection('devis').doc(id)
    try {
      await devisRef.update({
        pinned: state ? !state : false
      })
      message.success('Favoris edité')
    } catch (e) {
      message.error(e)
    }
  }
