import React from 'react'
import {Row, Col, Button, Form, Divider} from 'antd'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { TextInputWoError, SelectInputWoError, TextAreaInput } from './../../../util/Form/field'

import { updateCompanyEntities } from './../../../actions/companyActions'

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    devis: state.firestore.ordered.devis,
    initialValues: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0].settingsDevis,
    company: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    loading: state.form.companyEntities
})

const actions = {
  updateCompanyEntities
}

const typeOptions=[
  {
    key:'0',
    value:'0',
    name:'+0% (Aucunes charges)',
  },
  {
    key:'1',
    value:'12.8',
    name:'+12,8% (Commerce, Hébergement)',
  },
  {
    key:'2',
    value:'22',
    name:'+22% (Prestation de services, Profession libérales)',
  },
  {
    key:'3',
    value:'30',
    name:'+30% (Charges personnalisées)',
  },
  {
    key:'3',
    value:'40',
    name:'+40% (Charges personnalisées)',
  }
]
const tvaOptions= [
  {
    key:'0',
    value: false,
    name:'Non'
  },
  {
    key:'1',
    value: true,
    name:'Oui'
  },
]

class CompanyForm extends React.Component {

  onFormSubmit = (values) => {
    //console.log(values, this.props.company.id);
    this.props.updateCompanyEntities(values, this.props.company.id, "devis")
    .then(() => this.props.initialize(values))
  };

  render(){
    const {handleSubmit, reset, pristine, submitting, loading} = this.props
    return(
      <Form onFinish={handleSubmit(this.onFormSubmit)}>

        <Row gutter={8}>
          <Col flex="50%">
          <Field
            name="lastNumberFormat"
            labelTitle="Format de Numéro"
            component={SelectInputWoError}
            placeholder="YYYY-NN-V"
            options={[
              {value:'YYYY-NNN', name:'YYYY-NNN'},
              {value:'YYYY-NNN-V', name:'YYYY-NNN-V'},
            ]}
          />
          </Col>
          <Col flex="50%">
          <Field
            name="lastNumber"
            labelTitle="Dernier N°Devis"
            placeholder="YYYY-NNN-V"
            component={TextInputWoError}
          />
          </Col>
          <Col flex="50%">
            <Field
              name="type"
              labelTitle="Type de Charges"
              component={SelectInputWoError}
              options={typeOptions}
              defaultValue="0"
              placeholder="type"
            />
          </Col>
          <Col flex="50%">
            <Field
              name="tva"
              labelTitle="TVA"
              labelDetails="La TVA est applicable à partir du premier jour du mois de dépassement de la 1ère limite d'auto-entreprise"
              component={SelectInputWoError}
              options={tvaOptions}
              defaultValue="Non"
              placeholder="type"
            />
          </Col>
          <Col flex="100%">
          <Field
            name="footer"
            labelTitle="Pied de Page (conditions de paiements, etc.)"
            placeholder="Paiement dans un délai de 60 jours maximum à compter de la date de réception de la facture, si les modalités de facturation sont conformes et valides. Au-delà de ce délai une pénalité de retard de 10% du montant total vous sera facturée."
            component={TextAreaInput}
          />
          </Col>
        </Row>
        <div style={{textAlign:'right'}}>
          <Button
            onClick={reset}
            style={{ marginRight: 8 }}
            disabled={pristine || submitting}
          >
            Effacer
          </Button>
          <Button
            loading={loading && loading.submitting}
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            Sauvegarder
          </Button>
        </div>

      </Form>
    )
  }
}

export default connect(mapState, actions)(reduxForm({form:'companyDevisDetails'})(CompanyForm))
