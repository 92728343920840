import firebase from './../config/firebase'
import { message } from 'antd'
import {calcTotalHT} from './../util/Maths/calculsPrixDevis'

import { nextId } from './../util/idGenerator'

import moment from 'moment'


export const newOkr = (compId) =>
async (dispatch, getState, {getFirestore}) => {
  const firestore = getFirestore()
  let dateNow = Date.now();
  let newEl = {}
  newEl = {
    id: String(dateNow),
    date: Date.now(),
    compId: compId,

    title: "",
    solutions:[],
    isFinished:false,
  }
  try{
    await firestore.set(`okr/${dateNow}`, {...newEl})
    message.success('Nouveau OKR créé')
  }catch (e){
    message.error(e)
  }
}

export const updateOkr = (data) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const elRef = firestore.collection('okr').doc(data.id)
  try{
    await elRef.update({
      ...data
    })
    message.success('Informations mises à jours')
  }catch (e){
    message.error(e)
  }
}
