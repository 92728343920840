import React from 'react'
import { Row, Col, Button, Breadcrumb, Select, Collapse, Affix, Typography, Tooltip, Input } from 'antd'
import { HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined, FilePdfOutlined } from '@ant-design/icons';

import { Link, withRouter } from "react-router-dom";

import numeral from 'numeral'
import './../../../util/Maths/localNumeral.js'

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore, populate } from 'react-redux-firebase'

import { Loading } from './../../../util/Errors'
import { Bloc } from './../../../util/Bloc'

import CSA4 from './CSA4'
import ArticlesForm from './BdCform'

const query = (props) => {
  return [
    {
      collection: 'devis',
      where: ['id', '==', props.match.params.idDevis],
      storeAs: 'devisSelected'
    },
    {
      collection: 'clients',
      where: ['uid', '==', props.auth.uid],
      orderBy: 'society'
    },
  ]
}

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  devisSelected: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
  loading: state.firestore.status.requested
})

class BdCS extends React.Component {
  state = {
    showPDF: true,
  }

  render() {
    const { devisSelected, myCompany, loading } = this.props
    console.info('lorsque on est en tablette aligner le button du showPDF a droite.');
    return (
      <div>
        <Row justify="space-between">
          <Col>
            <Breadcrumb style={{ marginBottom: '15px' }}>
              <Breadcrumb.Item href="/">
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/' + myCompany.publicId + '/TableauDeBord/'}>
                  <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/' + myCompany.publicId + '/BondeCommande/'}>
                  <span>Projet</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{devisSelected ? devisSelected.title : 'Chargement...'}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>

        </Row>

        {loading['devisSelected'] ?
          <Row type="flex" justify="center" align="top" gutter={[8, 8]}>
            <Col xs={24} sm={24} md={14}> {/* md={showPDF ? 12 : 24} lg={showPDF ? 12 : 24} xl={showPDF ? 12 : 24}*/}
              <Bloc title="Matériel">
                <ArticlesForm />
              </Bloc>

            </Col>
            <Col xs={24} sm={24} md={10} style={{ height: '50wh' }}>
              {/*<Status/>*/}
              {/*<DevisPDF />*/}
              <Bloc>
                <CSA4 />
              </Bloc>

            </Col>
          </Row>
          :
          <Loading title="Récupération des données" />
        }
      </div>
    )
  }
}

export default withRouter(withFirestore(connect(mapState)(firestoreConnect(query)(BdCS))))
