import React from 'react'
import {Row, Col, Button, Form, Breadcrumb, List, Divider} from 'antd'
import { HomeOutlined, ArrowsAltOutlined, MinusCircleOutlined, CaretRightOutlined, PlusOutlined, InfoCircleOutlined,  DownSquareOutlined, RightOutlined } from '@ant-design/icons';

import { withRouter, Link } from "react-router-dom";

import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form'
import { connect, useSelector } from 'react-redux'
import { firestoreConnect, withFirestore } from 'react-redux-firebase'

import { newOkr } from './../../../actions/okrActions'

import { Bloc } from './../../../util/Bloc'
import { TextInputWoError, SelectInputWoError} from './../../../util/Form/field'

import TheOkrForm from './okrForm'

const query = (props) => {
  return [
    {
      collection:'okr',
      where:['compId', '==', props.match.params.idCompany],
      storeAs:'okr'
    },
  ]
}

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    okr: state.firestore.ordered.okr,
    loading: state.firestore.status.requested
})

const actions = {
  newOkr
}

class Okr extends React.Component {

  render(){
    const { myCompany, okr } = this.props
    return(
      <>
      <Row type='flex' justify="space-between" style={{marginBottom:'50px'}}>
      <Col>
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined/>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={'/'+myCompany.publicId+'/TableauDeBord/'}>
            <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>OKR</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      </Col>
      <Col>
        <Button onClick={() => this.props.newOkr(myCompany.publicId)} style={{marginRight:'5px'}}>
          Créer un Objectif
        </Button>
      </Col>
      </Row>


        <Row>
      {okr && okr.map((okr, i) =>
          <Col span={24}>
            <TheOkrForm idOkrS={i} key={i}/>
          </Col>
      )}
        </Row>

      </>
    )
  }
}

export default
withRouter(
  withFirestore(
    connect(mapState, actions)(
        firestoreConnect(query)(Okr)
      )
    )
  )
