import React from 'react';
import { Row, Col, Breadcrumb, message, List, Popconfirm, Button, Input} from 'antd'
import { HomeOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';

import { Bloc } from './../../util/Bloc'
import { NotAllowed } from './../../util/Errors'

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

import { deleteClient } from './../../actions/clientsActions'

import ClientForm from './ClientForm'

const query = (props) => {
  return [
    {
      collection:'clients',
      where:['uid', '==', props.auth.uid],
      orderBy:'society'
    },
  ]
}

const mapState = (state) => ({
    auth: state.firebase.auth,
    clients: state.firestore.ordered.clients,
    loading: state.firestore.status.requested
})

const actions = {
  deleteClient
}

class Clients extends React.Component {
  state={
    editId: null,
    cancelEdit:false,
    clientsFiltered: []
  }

  componentDidMount(){
    this.setState({
      clientsFiltered: this.props.clients
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      clientsFiltered: nextProps.clients
    });
  }

  cancelEdit = () => { //si cancelEdit alors on réinitinialise le form, d'ou désactiver cancelEdit après 10ms
    this.setState({editId: null, cancelEdit:true})
    setTimeout(() => this.setState({cancelEdit:false}), 100)
  }

  handleChange = e => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      currentList = this.props.clients;
      newList = currentList.filter(client =>
        (client.name && client.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (client.society && client.society.toLowerCase().includes(e.target.value.toLowerCase())) ||
        (client.surname && client.surname.toLowerCase().includes(e.target.value.toLowerCase()))
      )

      this.setState({
        clientsFiltered: newList
      })
    } else {
      newList = this.props.clients;
      this.setState({
        clientsFiltered: newList
      });
    }
  }

  render(){
    const { clients, loading, deleteClient, auth } = this.props
    const { editId, clientsFiltered, cancelEdit } = this.state
    return (
      <div>
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined/>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Clients</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      {/*<ClientsTable/>*/}
      <Row type="flex" gutter={[14, 16]} >
        <Col xs={24} md={12} lg={13} xl={14} xxl={15} >
        <Bloc
          title="Clients"
          action={<Input placeholder="Chercher un client" allowClear onChange={this.handleChange} prefix={<SearchOutlined />} />}
          loading={!loading['clients?where=uid:==:'+ auth.uid +'&orderBy=society']} >
            <List
              size="small"
              dataSource={clientsFiltered}
              style={{height:'70vh', overflow:'auto'}}
              renderItem={client =>
                <List.Item
                  style={{borderBottom:'1px solid #80808066', padding:'5px'}}
                  actions={ editId && editId === client.id ? /*Si cette modif est selectionnée */
                    [
                      <a key="cancel-edit" onClick={this.cancelEdit}><ArrowLeftOutlined /></a>,
                      <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={()=> deleteClient(client.id)} okText="Oui" cancelText="Non">
                        <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                      </Popconfirm>
                    ]
                    :
                      !editId ? /*Si il aucune modif est selectionnée */
                      [
                        <a key="list-edit" onClick={() => this.setState({editId: client.id, cancelEdit:false}) }><EditOutlined /></a>,
                        <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={()=> deleteClient(client.id)} okText="Oui" cancelText="Non">
                          <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                        </Popconfirm>
                      ]
                      : /*Si il une modif est selectionnée */
                      [
                        <EditOutlined />,
                        <Popconfirm key="list-delete" placement="topLeft" title="Validez la suppression ?" onConfirm={()=> deleteClient(client.id)} okText="Oui" cancelText="Non">
                          <a href="#"><DeleteOutlined style={{color:'red'}}/></a>
                        </Popconfirm>
                      ]
                    }
                >
                  <List.Item.Meta
                    title={<div><b>{client.society}</b> ({client.acronyme}. {client.name} {client.surname})</div>}
                    description={<div>{client.adress}, {client.zipcode} {client.city}</div>}
                  />
                  {client.mail} | {client.phone}
                </List.Item>}
            />
          </Bloc>
        </Col>
        <Col xs={24} md={12} lg={11} xl={10} xxl={9}>
          <Bloc title={editId ? 'Modifier un client' : 'Ajouter un client'} >
            <ClientForm editId={editId} cancelEdit={cancelEdit} resetParent={() => this.setState({editId:null})}/>
          </Bloc>
        </Col>
      </Row>
      </div>
    )
  }
}

export default withFirestore(connect(mapState, actions)(firestoreConnect(query)(Clients)))
