import React from 'react'
import {Row, Col, Button} from 'antd'
import {Breadcrumb, Progress, Typography, Tag} from 'antd'
import { HomeOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

class Sub extends React.Component {

  render(){
    return(
      <div>
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Abonnement</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row type="flex" justify="space-around" align="middle">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Title style={{textAlign:'center'}} level={3}>Statut de votre Compte</Title>
        <Title style={{textAlign:'center'}} level={4}>
        <Text style={{fontSize:'1.2em', color:'green', backgroundColor:'LimeGreen', paddingLeft:'5px', border:'1px', paddingRight:'5px', borderRadius:'10px', textTransform:'uppercase'}}>Actif</Text>
        <br/><br/>
        jusqu'au 01/12/2019</Title>
        <Progress
          strokeColor={{
            from: '#87d068',
            to: '#F08080',
          }}
          percent={100}
          status="active"
        />
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>

        </Col>
      </Row>


      </div>
    )
  }
}

export default Sub
