import firebase from './../config/firebase'
import { message } from 'antd'

export const postArticle = (data) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    let newArticle = {}
    let dateNow = Date.now();
    newArticle = {
      ...data,
      uid: user.uid
    }
    try {
      await firestore.set(`articles/${dateNow}`, { ...newArticle })
      message.success('Nouvel article enregistré')
    } catch (e) {
      message.error(e)
    }
  }

export const updateArticle = (data, id) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log(data);
    const firestore = getFirestore()
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const articleRef = firestore.collection('articles').doc(id)
    try {
      await articleRef.update({
        ...data
      })
      message.success('Informations du Article mises à jours')
    } catch (e) {
      message.error(e)
    }
  }

//ajouter de la sécurité à tous ça (seuelement la personne connecter avec l'id du propriétaire peut supprimer le Article)
export const deleteArticle = (id) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const articleRef = firestore.collection('articles').doc(id)
    //if(user.uid === ArticleRef.uid)
    try {
      await articleRef.delete()
        .then(
          () => message.success('Article supprimé')
        )
        .catch(
          (e) => message.error(e)
        )
    } catch (e) {
      message.error(e)
    }
  }
