import React from 'react'
import {Row, Col, Button} from 'antd'

import styles from './PDFStyles'
import { Page, Text, View, Image, Link, Document, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell} from '@david.kucsai/react-pdf-table'

import { connect } from 'react-redux'
import { withFirestore, withFirebase } from 'react-redux-firebase'

import { Loading } from './../../../util/Errors'
import { calcPrixU, calcTotalArticle, calcTotalinCat, calcTotalHT, calcTVA, calcTTC, sumWamount } from './../../../util/Maths/calculsPrixDevis'

import numeral from 'numeral'
import './../../../util/Maths/localNumeral.js'

import Header from './MV2.png'
import Signature from './SignatureLG.jpg'

const mapState = (state, props) => {
  let clientSelected = []
  clientSelected = state.firestore.ordered.devisSelected &&
    state.firestore.ordered.devisSelected[0] &&
    state.firestore.ordered.clients &&
    state.firestore.ordered.clients.find(
      client => client.id === state.firestore.ordered.devisSelected[0].clientId
    )

  return{
    auth: state.firebase.auth,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    devisSelected: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
    client: clientSelected,
    loading: state.firestore.status.requested
  }
}

const MyDocument = ({devisSelected, client, myCompany, urlLogo, paiement}) =>
  <Document
    title={"Devis_"+devisSelected.number+"_"+devisSelected.title}
    author={myCompany.companyName}
    keywords="Devis"
    subject={devisSelected.title}
  >
    <Page size="A4" style={styles.page} wrap>
      <View fixed style={styles.header}>
        <Image src={Header} />
          <View>
            {myCompany.companyName && <Text style={styles.myCompany}>{myCompany.companyName}&nbsp;</Text>}
            {myCompany.adress && <Text style={styles.myCompany}>{myCompany.adress}&nbsp;</Text>}
            {myCompany.adressComplement && <Text style={styles.myCompany}>{myCompany.adressComplement}&nbsp;</Text>}
            {myCompany.zipcode && myCompany.city &&<Text style={styles.myCompany}>{myCompany.zipcode} {myCompany.city}&nbsp;</Text>}
            {myCompany.siret && <Text style={styles.myCompany}>SIRET : {myCompany.siret}&nbsp;</Text>}
            {myCompany.tva && <Text style={styles.myCompany}>TVA : {myCompany.tva}&nbsp;</Text>}
          </View>
      </View>
      <View style={styles.ids}>
        <View style={styles.myEntity}>
          <Text style={styles.suiviDossier}>Suivi de dossier :</Text>
          <Text>{myCompany.entities[0].firstName ? myCompany.entities[0].firstName : 'Prénom'} {myCompany.entities[0].lastName ? myCompany.entities[0].lastName : 'Nom'}</Text>
          <Text>{myCompany.entities[0].function ? myCompany.entities[0].function : "Fonction"}</Text>
          <Link style={styles.link} src={'mailto:'+myCompany.entities[0].mail ? myCompany.entities[0].mail : 'mailAdress'}>{myCompany.entities[0].mail ? myCompany.entities[0].mail : "adress mail"}</Link>
          <Text>{myCompany.entities[0].phone && myCompany.entities[0].phone}</Text>
        </View>
        <View style={styles.client}>
        {client ?
          <View>
            <Text style={styles.bold}>{client.society}</Text>
            <Text>{client.acronyme} {client.name} {client.surname}</Text>
            <Text>{client.adress}</Text>
            <Text>{client.zipcode} {client.city}</Text>
            <Link style={styles.link} src={"mailto:" + client.mail} >{client.mail}</Link>
          </View>
          :
          <Text>Aucun client sélectionné </Text>
        }
        </View>
      </View>
      <View style={styles.sectionTitle}>
        <View style={styles.sectionTitleflex}>
          <Text style={styles.numDevisTitle}>Devis N°{devisSelected.number}</Text>
          <Text style={styles.dateTitle}>Emis le {devisSelected.creationDate}</Text>
        </View>
        <View style={styles.sectionInTitle}>
          {devisSelected.eventDate ?
            <Text style={styles.title}>{devisSelected.title} – le {devisSelected.eventDate}</Text>
          :
            <Text style={styles.title}>{devisSelected.title}</Text>
          }
          <Text style={styles.subtitle}>Ce devis concerne {devisSelected.details}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={[styles.divTabHeader, {border:1}]}>
          <View style={[styles.tabCellTitleCenter, {width:'1.5cm'}]}>
            <Text>
              Ref.
            </Text>
          </View>
          <View style={[styles.tabCellTitleLeft, {width:'10cm', borderLeft:1}]}>
            <Text>
              Description
            </Text>
          </View>
          <View style={[styles.tabCellTitleCenter, {width:'1.65cm', borderLeft:1}]}>
            <Text>
              Q
            </Text>
          </View>
          <View style={[styles.tabCellTitleCenter, {width:'2.35cm', borderLeft:1}]}>
            <Text>
              Prix U.
            </Text>
          </View>
          <View style={[styles.tabCellTitleCenter, {width:'1.65cm', borderLeft:1}]}>
            <Text>
              R%
            </Text>
          </View>
          <View style={[styles.tabCellTitleCenter, {width:'2.72cm', borderLeft:1}]}>
            <Text>
              Total
            </Text>
          </View>
        </View>
        {devisSelected.content && devisSelected.content.map((cat,i) =>
          <View key={i} style={styles.divTabContent} wrap={false}>
           {cat.title &&
            <View>
              <Text style={[styles.bold, styles.category]}>{cat.title && cat.title}</Text>
            </View>}
          {cat && cat.articles.map((art, i) =>
            <View key={i} style={styles.divTabHeader}>
              <Text style={[styles.tabCellItemLeft, {width:'1.5cm'}]}>
                {art.articleDetails.ref}
              </Text>
              <Text style={[styles.tabCellItemLeft, {width:'10cm'}]}>
                {art.desc ? art.desc :
                  <View wrap={false}>
                    <Text>{art.articleDetails.title}</Text>
                    {art.articleDetails.description ?
                       art.articleDetails.description !== "" &&
                      <Text style={styles.description}>
                      &nbsp;&nbsp;-&nbsp;&nbsp;{art.articleDetails.description}
                      </Text>
                    :
                    <Text>&nbsp;</Text>
                    }
                  </View>
                }
              </Text>
              <Text style={[styles.tabCellItemRight, {width:'1cm'}]}>
                {art.q}
              </Text>
              <Text style={[styles.tabCellItemLeft, {width:'0.65cm'}]}>
                {art.u}
              </Text>
              <Text style={[styles.tabCellItemRight, {width:'2.35cm'}]}>
                {numeral(calcPrixU(art.articleDetails.price, art.articleDetails.taxes, devisSelected.type)).format('0,0.00 $')}
              </Text>
              <Text style={[styles.tabCellItemRight, {width:'1.65cm'}]}>
                {art.r != 0 ? art.r + ' %' : '-'}
              </Text>
              <Text style={[styles.tabCellItemRight, {width:'2.72cm'}]}>
                {art.r != 100 ? numeral(calcTotalArticle(calcPrixU(art.articleDetails.price, art.articleDetails.taxes, devisSelected.type), art.q, art.r)).format('0,0.00 $') : <Text style={{fontSize:'0.32cm'}}>OFFERT</Text>}
              </Text>
            </View>
          )}
          {cat.displayTotal &&
          <View style={[styles.divTabHeader, styles.category, {fontSize:'0.4cm', backgroundColor:'rgb(234, 234, 234)'}]}>
            <Text style={{textDecoration: 'underline', paddingLeft:'12cm'}}>SOUS-TOTAL : {numeral(calcTotalinCat(cat.articles, devisSelected.type)).format('0,0.00 $')}</Text>
          </View>
          }
          <Text>&nbsp;</Text>
          </View>
        )}

      </View>
      <View wrap={false}>
      <View style={styles.sectionTotaux}>
        <View style={styles.blocTotaux}>
          <View style={styles.blocTitlePrices}>
            {devisSelected.debours ?
              <Text style={[styles.bold, {fontSize:'0.45cm'}]}>TOTAL</Text>
              :
              devisSelected.tva ?
              <Text style={[styles.bold, {fontSize:'0.45cm'}]}>TOTAL HT</Text>
              :
              <Text style={[{fontSize:'0.4cm'}]}><Text style={[styles.bold, {fontSize:'0.45cm'}]}> TOTAL </Text>(TVA non applicable, Art.293B du CGI)</Text>
            }

            {devisSelected.debours &&
              <Text>&nbsp;</Text>
            }
            {devisSelected.debours &&
              <Text style={[styles.bold, {fontSize:'0.45cm'}]}>Frais de débours</Text>
            }
            {devisSelected.debours && devisSelected.debours.map((frais,i) =>
              <Text key={i}>{frais.company} - {frais.description}</Text>
            )}
            {devisSelected.debours &&
              <Text>&nbsp;</Text>
            }
            {devisSelected.debours &&
              <Text style={[styles.bold, {fontSize:'0.45cm'}]}>MONTANT TOTAL HT</Text>
            }

            {devisSelected.tva && <Text>TVA 20%</Text>}
            <Text>&nbsp;</Text>
            {devisSelected.tva && <Text style={[styles.bold, {fontSize:'0.45cm'}]}>TOTAL TTC </Text>}

            {
              devisSelected.paiement == 1 ?
                <Text>Paiement total à réception de la facture (100,00 %)</Text> :
              devisSelected.paiement == 2 ?
                <View>
                  <Text>Accompte dès la signature du devis (50,00 %)</Text>
                  <Text>Paiement restant à réception de la facture (50,00 %)</Text>
                </View> :
              devisSelected.paiement == 3 ?
                <View>
                  <Text>Accompte dès la signature du devis (33,00 %)</Text>
                  <Text>2ème paiement (33,00 %)</Text>
                  <Text>Paiement restant (33,00 %)</Text>
                </View> :
              devisSelected.paiement == 4 ?
                <View>
                  <Text>Accompte dès la signature du devis (25,00 %)</Text>
                  <Text>2ème paiement (25,00 %)</Text>
                  <Text>3ème paiement (25,00 %)</Text>
                  <Text>Paiement restant (25,00 %)</Text>
                </View> :
              devisSelected.paiement == 5 ?
                <View>
                  <Text>Accompte dès la signature du devis (20,00 %)</Text>
                  <Text>2ème paiement (20,00 %)</Text>
                  <Text>3ème paiement (20,00 %)</Text>
                  <Text>4ème paiement (20,00 %)</Text>
                  <Text>Paiement restant (20,00 %)</Text>
                </View>
                :
                <View>
                  <Text>Accompte dès la signature du devis (15,00 %)</Text>
                  <Text>2ème paiement (15,00 %)</Text>
                  <Text>3ème paiement (15,00 %)</Text>
                  <Text>4ème paiement (15,00 %)</Text>
                  <Text>5ème paiement (15,00 %)</Text>
                  <Text>Paiement restant (15,00 %)</Text>
                </View>
            }
          </View>
          <View style={styles.blocPrices}>
            <Text style={[styles.bold, {fontSize:'0.45cm'}]}>{numeral(calcTotalHT(devisSelected.content, devisSelected.type)).format('0,0.00 $')}</Text>

            {devisSelected.debours &&
              <Text>&nbsp;</Text>
            }
            {devisSelected.debours &&
              <Text>&nbsp;</Text>
            }
            {devisSelected.debours && devisSelected.debours.map((frais,k) =>
              <Text key={k}>- {numeral(frais.amount).format('0,0.00 $')}</Text>
            )}
            {devisSelected.debours &&
              <Text>___________________</Text>
            }
            {devisSelected.debours &&
              <Text style={[styles.bold, {fontSize:'0.45cm'}]}>{numeral(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours)).format('0,0.00 $')}</Text>
            }

            {devisSelected.tva && <Text>
              {devisSelected.debours ?
                numeral(calcTVA(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))).format('0,0.00 $')
                :
                numeral(calcTVA(calcTotalHT(devisSelected.content, devisSelected.type))).format('0,0.00 $')
              }
            </Text>}
            {devisSelected.tva ? <Text>___________________</Text> : <Text>&nbsp;</Text>}
            {devisSelected.tva && <Text style={[styles.bold, {fontSize:'0.45cm'}]}>
              {devisSelected.debours ?
                numeral(calcTTC(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))).format('0,0.00 $')
                :
                numeral(calcTTC(calcTotalHT(devisSelected.content, devisSelected.type))).format('0,0.00 $')
              }
            </Text>}

            {devisSelected.tva ?
              <View>
                {
                  devisSelected.paiement == 1 ? //si tu lis le code qui suit ne m'engueule pas la boucle for bug sur le pdfrender
                    <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                  :
                  devisSelected.paiement == 2 ?
                    <View>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    </View>
                  :
                  devisSelected.paiement == 3 ?
                    <View>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    </View>
                  :
                  devisSelected.paiement == 4 ?
                    <View>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    </View>
                  :
                  devisSelected.paiement == 5 ?
                    <View>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                      <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    </View>
                  :
                  <View>
                    <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                    <Text>{numeral(paiement).format('0,0.00 $')}</Text>
                  </View>
                }
              </View>
              :
              <View>
                <Text>{numeral(calcTotalHT(devisSelected.content, devisSelected.type)/devisSelected.paiement).format('0,0.00 $')}</Text>
              </View>}
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>Devis gratuit valable jusqu'au {devisSelected.limitDate}. {devisSelected.footer}</Text>
      </View>
      <View style={styles.signature}>
        <View>
          <Text style={styles.bold}>Le Client</Text>
          <Text>Signature et Bon pour accord</Text>
        </View>
        <View>
          <Text style={styles.bold}>{myCompany.firstName} {myCompany.lastName}</Text>
          <Image style={{width:150, backgroundColor:'grey'}} src={Signature} />
        </View>
      </View>
      </View>
      <View fixed style={styles.bottom}>
        <Text>Devis N°{devisSelected.number}</Text>
        {myCompany.societyType == "EURL" &&
          <Text>{myCompany.companyName}, {myCompany.adress} {myCompany.zipcode} — EURL au capital de {myCompany.capital} €</Text>
        }
        <Text render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </View>
    </Page>
  </Document>

class DevisPDF extends React.Component {
  state={
    show:false,
    urlLogo:""
  }

  loadLogo = (urlName) => {
    urlName &&
      this.props.firebase.storage().ref(urlName).getDownloadURL().then((url) => {
        this.setState({urlLogo:url})
      })
  }

  componentDidMount(){
    this.setState({ show: false });
    this.loadLogo(this.props.myCompany.logoUrlName)
    setTimeout(()=>{
      this.setState({ show: true });
    }, 2000);
  }

  render(){
    const { show, urlLogo } = this.state
    const { devisSelected, myCompany, client } = this.props;
    if( devisSelected && myCompany && show){
      const paiement =
        devisSelected.debours ?
        calcTTC(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))/devisSelected.paiement
        :
        calcTTC(calcTotalHT(devisSelected.content, devisSelected.type))/devisSelected.paiement;
    return(
      <Row>
      <Col span={24}>
        <PDFDownloadLink
          document={<MyDocument devisSelected={devisSelected} myCompany={myCompany} client={client} paiement={paiement} />}
          fileName={"Devis_"+devisSelected.number+"_"+devisSelected.title+".pdf"}
        >
          {({ blob, url, loading, error }) => (loading ? 'Ouverture du document...' : 'Télécharger')}
        </PDFDownloadLink>
      </Col>
      <Col span={24}>
        <PDFViewer width="100%" style={{minHeight: '70vh'}}>
          <MyDocument devisSelected={devisSelected} myCompany={myCompany} client={client} urlLogo={urlLogo} paiement={paiement} />
        </PDFViewer>
      </Col>
      </Row>
    )
    }else{
      return <Loading title="Ouverture du PDF"/>
    }
  }
}

export default withFirebase(withFirestore(connect(mapState)(DevisPDF)))
