import React from 'react'
import { Form, Row, Col, Button, Popover } from 'antd'
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined, DownSquareOutlined } from '@ant-design/icons';

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form'
import { TextInputWoError, NumberInputWoError, SelectInputWoError } from './../../../util/Form/field'
import { composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric } from 'revalidate'

import { updateDevisArticles } from './../../../actions/devisActions'

import { Loading } from './../../../util/Errors'

import ArticlesFormField from './DevisArticlesFormField'

const selector = formValueSelector('devisArticlesForm') // on récupère les values du form

const validate = combineValidators({
  article: isRequired({ message: 'Requis' }),
  q: isRequired({ message: 'Requis' }),
})

const query = (props) => {
  return [
    {
      collection: 'articles',
      where: ['uid', '==', props.auth.uid],
      orderBy: 'ref'
    }
  ]
}

const actions = {
  updateDevisArticles
}

const mapState = (state, props) => {
  let articlesEdited = []
  articlesEdited = state.firestore.ordered.articles && state.firestore.ordered.articles.map(art => {
    return {
      value: art.id,
      name: art.ref + ' | ' + art.title + ' (' + art.price + '€ +' + art.taxes + '%)',
      ...art
    }
  })

  const articleSelected = selector(state, `${props.field}.article`)

  return {
    initialValues: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
    articleSelected,
    articlesEdited,
    auth: state.firebase.auth,
    articles: state.firestore.ordered.articles,
    loading: state.form && state.form.devisArticlesForm,
  }
}

let renderArticlesForm = ({ fields, articlesEdited, articles }) => (
  <div>

    {fields.map((field, i) => // la suite se passe dans ArticlesFormField pour rerendre seulement le field et pas la map entière
      <ArticlesFormField fields={fields} field={field} key={i} i={i} articlesEdited={articlesEdited} articles={articles} />
    )}

    <Row justify="center" gutter={8} style={{ marginTop: '4px' }}>

      <Col>
        <Button
          type="dashed"
          onClick={() => fields.push({})}
          block
        >
          <PlusOutlined /> Ajouter un article
        </Button>
      </Col>
    </Row>

  </div>
)

let renderCategoryForm = ({ fields, articlesEdited, articles }) => (
  <div>

    {fields.map((cat, index) => (
      <Row type="flex" key={index} i={index} justify="space-around" gutter={4} style={{ marginBottom: '6px' }} >
        <Col span={24}>
          <h5 style={{ margin: 7, marginBottom: 2, fontWeight: 'bold' }}>Section {index + 1}</h5>
        </Col>
        <Col span={18}>
          <Field
            name={`${cat}.title`}
            placeholder="Titre de la Section (Préproduction, Prestation ...)"
            component={TextInputWoError}
            allowClear
          />
        </Col>
        <Col span={4}>
          <Field
            name={`${cat}.displayTotal`}
            component={SelectInputWoError}
            options={[
              { key: '1', value: true, name: 'Afficher Total' },
              { key: '2', value: false, name: 'Masquer Total' }
            ]}
          />
        </Col>
        <Col span={2} style={{ marginTop: '10px', textAlign: 'center' }}>
          <MinusCircleOutlined
            onClick={() => fields.remove(index)}
          />
        </Col>
        <Col span={24}>
          <FieldArray name={`${cat}.articles`} component={renderArticlesForm} articlesEdited={articlesEdited} articles={articles} />
        </Col>
      </Row>
    ))}

    <Row justify="center" gutter={8} style={{ marginTop: '4px' }}>
      <Col>
        <Button
          onClick={() => fields.push({})}
          block
        >
          <PlusOutlined /> Ajouter une Section
        </Button>
      </Col>
    </Row>
  </div>
)


class ArticlesForm extends React.Component {

  onFormSubmit = values => {
    this.props.updateDevisArticles(values.content, values.id, this.props.articles, values.type).then(() => this.props.initialize(values))
  };

  render() {
    console.info('mettre en fonction validate');
    const { handleSubmit, articlesEdited, articles, articleSelected, loading, pristine, submitting } = this.props
    return (
      <Form onFinish={handleSubmit(this.onFormSubmit)} autoComplete="off">

        <FieldArray name="content" component={renderCategoryForm} articlesEdited={articlesEdited} articles={articles} />

        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button
            loading={loading && loading.submitting}
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            Sauvegarder
          </Button>
        </div>
      </Form>
    )
  }
}

export default
  withFirestore(
    connect(mapState, actions)(
      firestoreConnect(query)(
        reduxForm({ form: 'devisArticlesForm', validate })
          (ArticlesForm)
      )
    )
  )
