import React from 'react'
import { Row, Col, Button, PageHeader, Tag, Statistic } from 'antd'
import { EditOutlined, SendOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase'

import numeral from 'numeral'
import './../../../util/Maths/localNumeral.js'

import { Bloc } from './../../../util/Bloc'

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  devis: state.firestore.ordered.devis,
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  loading: state.firestore.status.requested
})

const StatutDevisIcon = ({ state }) => {
  switch (state) {
    case 'redaction':
      return <Tag color="blue">En cours de rédaction</Tag>;
    case 'sent':
      return <Tag color="purple">Devis envoyé</Tag>;
    case 'declined':
      return <Tag color="red">Devis refusé</Tag>;
    case 'validated':
      return <Tag color="green">Devis validé</Tag>;
    case 'factured':
      return <Tag color="green">Facture Généré</Tag>;
    default:
      return '';
  }
}

class Statut extends React.Component {
  render() {
    const { editId, devis, data } = this.props
    console.log(data);
    if (editId) {
      return (
        <Bloc
          footer={
            <div>
              <Row justify="space-between">
                <Col>Montant HT</Col><Col>{data.totalHT ? numeral(data.totalHT).format('0,0.00 $') : "NaN"}</Col>
              </Row>
              <Row justify="space-between">
                <Col>Statut</Col><Col><StatutDevisIcon state={data.statut} /></Col>
              </Row>
            </div>
          }>
          <div style={{ height: '40vh' }}>
            <h2>{data.title}&nbsp; <Tag color="var(--color1)">{"Devis N°" + data.number}</Tag></h2>
            <h3>Ce devis concerne {data.details}</h3>
          </div>
        </Bloc>
      )
    } else {
      return (
        <Bloc>
          Résumé Analytics
          (mettre un graphique en barre avec le nombre de devis en rédaction, envoyé, validé, refusé)
          <Statistic title="En cours de Rédactions" value={18} prefix={<EditOutlined />} />
        </Bloc>
      )
    }
  }
}

export default withFirestore(connect(mapState)(Statut))
