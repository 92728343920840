import { combineReducers } from 'redux'

import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { reducer as FormReducer } from 'redux-form'

import fetchSociety from './fetchSocietyReducer.js'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: FormReducer,
  fetchSociety : fetchSociety,
})

export default rootReducer
