import { createReducer } from './../util/reducerUtils'
import { FETCH_API_SOCIETY } from './../actions/companyConstants'

const apiSociety = (state, payload) => {
  return {
    ...state,
    ...payload
  }
}

export default createReducer(null, {
  [FETCH_API_SOCIETY] : apiSociety,
})
