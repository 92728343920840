import React from 'react'
import { Layout, Menu, Popconfirm, Button, message } from 'antd';

import { DashboardOutlined, VideoCameraOutlined, BankOutlined, TeamOutlined, ControlOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Router, Link, withRouter } from "react-router-dom";

import { connect } from 'react-redux'
import { logout } from './../../actions/authActions'

const { Sider } = Layout;
const { SubMenu } = Menu;

const mapState = (state) => ({
  profile: state.firebase.profile
})

const actions = {
  logout
}

class MenuDisplay extends React.Component {

  render() {
    const { logout, profile, location } = this.props
    const route = location.pathname && location.pathname.split('/')

    return (
      <div>
        <Sider
          style={{ height: '100%' }}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <Link to="/" style={{ color: 'white' }} >
            <div style={{ height: '32px', margin: '16px', padding: '5px', textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
              Kompta
            </div>
          </Link>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['/']}
            selectedKeys={[location.pathname]}
          >
            <Menu.Item key="/">
              <Link to={"/"}>
                <DashboardOutlined />
                <span className="nav-text">Accueil</span>
              </Link>
            </Menu.Item>
            {profile.companies && profile.companies.map((company, i) =>
              <SubMenu
                key={i}
                title={
                  <span>
                    <BankOutlined />
                    <span>{company.companyName}</span>
                  </span>
                }
              >
                <Menu.Item key={"/" + company.publicId + "/TableauDeBord/"}>
                  <Link to={"/" + company.publicId + "/TableauDeBord/"}>Tableau de Bord</Link>
                </Menu.Item>
                <Menu.Item key={"/" + company.publicId + "/OKR/"}>
                  <Link to={"/" + company.publicId + "/OKR/"}>OKR</Link>
                </Menu.Item>
                <Menu.Item key={"/" + company.publicId + "/Devis/"}>
                  <Link to={"/" + company.publicId + "/Devis/"}>Devis</Link>
                </Menu.Item>
                <Menu.Item key={"/" + company.publicId + "/BonsDeCommande/"}>
                  <Link to={"/" + company.publicId + "/BonsDeCommande/"}>Bons de commande</Link>
                </Menu.Item>
                <Menu.Item key={"/" + company.publicId + "/Factures/"}>
                  <Link to={"/" + company.publicId + "/Factures/"}>Factures</Link>
                </Menu.Item>
                <Menu.Item key={"/" + company.publicId + "/Depenses/"}>
                  <Link to={"/" + company.publicId + "/Depenses/"}>Dépenses</Link>
                </Menu.Item>
                <Menu.Item key={"/" + company.publicId + "/Comptabilite/"}>
                  <Link to={"/" + company.publicId + "/Comptabilite/"}>Comptabilité</Link>
                </Menu.Item>
                <Menu.Item key={"/" + company.publicId + "/Parametres/"}>
                  <Link to={"/" + company.publicId + "/Parametres/"}>Paramêtres</Link>
                </Menu.Item>
              </SubMenu>
            )}
            <Menu.Item key="/Clients/">
              <Link to={"/Clients/"}>
                <TeamOutlined />
                <span className="nav-text">Clients</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/Articles/">
              <Link to={"/Articles/"}>
                <VideoCameraOutlined />
                <span className="nav-text">Articles</span>
              </Link>
            </Menu.Item>
            {/*<Menu.Item key="/Abonnement/">
            <Link to={"/Abonnement/"}>
              <ControlOutlined />
              <span className="nav-text">Abonnement</span>
            </Link>
            </Menu.Item>*/}
            <Menu.Item key="/Parametres/">
              <Link to={"/Parametres/"}>
                <SettingOutlined />
                <span className="nav-text">Paramêtres</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="Logout" onClick={logout}>
              <LogoutOutlined />
              <span className="nav-text">Déconnexion</span>
            </Menu.Item>
          </Menu>
        </Sider>
      </div>
    )
  }
}

export default withRouter(connect(mapState, actions)(MenuDisplay))
