import React from 'react'
import {StyleSheet, Font} from '@react-pdf/renderer';

import robotoReg from './fonts/Roboto-Regular.ttf'
import robotoBold from './fonts/Roboto-Bold.ttf'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: robotoReg},
    { src: robotoBold, fontWeight: 'bold'},
  ]
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    fontFamily:'Roboto',
    paddingBottom:'2cm'
  },
  header:{
    margin:'0 15 15 15',
    flexDirection:'row',
    justifyContent:'space-between',
    fontSize:'.4cm',
    paddingBottom:3,
    borderBottom:'1',
    top:'.5cm',
  },
  link:{
    color:'#483D8B',
  },
  section: {
    margin: '0 15 0 15',
    padding: 0,
  },
  ids:{
    margin:'10 15 0 15',
    flexDirection:'row',
    justifyContent:'space-between',
  },
    myEntity:{
      fontSize:'0.4cm',
      lineHeight:1.4
    },
      suiviDossier:{
        textTransform:'uppercase',
        fontSize:'0.35cm',
        marginBottom:7,
      },
    client:{
      fontSize:'0.4cm',
      width:'10cm',
      padding:10,
      border:1,
    },
    bold:{
      textTransform:'uppercase',
      fontFamily: 'Roboto',
      fontWeight:'bold',

    },
  sectionTitle:{
    backgroundColor:'#D3D3D3',
    margin:'10 15 10 15',
    padding:5
  },
    sectionTitleflex:{
      flexDirection:'row',
      justifyContent:'space-between',
    },
    sectionInTitle:{
      padding: 12,
    },
    numDevisTitle:{
      fontFamily:'Oswald',
      textTransform:'uppercase'
    },
    dateTitle:{
      fontFamily:'Oswald',
      textTransform:'uppercase',
      fontSize:'0.5cm'
    },
    title:{
      fontSize:'0.6cm',
      textTransform:'uppercase',
      fontFamily: 'Oswald',
    },
    subtitle:{
      fontSize:'0.42cm',
      marginTop:'5'
    },
  image:{
    marginVertical: 15,
    marginHorizontal: 100,
  },


  sectionTotaux:{
    margin:'20 15 0 15',
    flexDirection:'row-reverse',
  },
    blocTotaux:{
      fontSize:'0.4cm',
      width:'14cm',
      padding:10,
      border:1,
      display:'flex',
      flexDirection: "row"
    },
      blocTitlePrices:{
        flex:3,
        lineHeight:1.4,
      },
      blocPrices:{
        flex:1,
        textAlign:'right',
        lineHeight:1.4,
      },

  footer:{
    fontSize:'0.32cm',
    margin:'10 15 0 15',
    lineHeight:1.2,
  },

  signature:{
    margin:'20 60 15 30',
    flexDirection:'row',
    justifyContent:'space-between',
    fontSize:'.4cm',
  },

  divTabHeader:{
    flexDirection:'row',
  },
  divTabContent:{
    fontSize:'0.4cm',
    borderLeft:1,
    borderRight:1
  },

  tabCellTitleLeft:{
    fontSize:'0.4cm',
    paddingLeft:3,
    paddingTop:2,
    paddingBottom:2,
    backgroundColor:'#D3D3D3'
  },
  tabCellTitleCenter:{
    fontSize:'0.4cm',
    textAlign:'center',
    paddingTop:2,
    paddingBottom:2,
    backgroundColor:'#D3D3D3'
  },
  tabCellItemLeft:{
    fontSize:'0.4cm',
    paddingLeft:3,
    paddingTop:2,
    paddingBottom:2,
    border:0,
  },
  tabCellItemRight:{
    fontSize:'0.4cm',
    textAlign:'right',
    paddingRight:1,
    paddingTop:2,
    paddingBottom:2,
    border:0,
  },
  category:{
    fontSize:'0.45cm',
    paddingTop:'3em',
    paddingBottom:'3em',
    paddingLeft:'2cm',
    marginTop:'0',
    marginBottom:'2em',
    backgroundColor:'#ADD8E6',
  },

  description:{
    fontSize:'0.35cm',
    color:'grey',
  },

  bottom: {
    flexDirection:'row',
    justifyContent:'space-between',
    fontSize:'.3cm',
    position:'absolute',
    top:'28cm',
    left:'.5cm',
    right:'.5cm',
    bottom:'.5cm',
    borderTop:'1',
  },
});

export default styles
