import React, { PureComponent } from 'react';
import { ResponsiveContainer, ComposedChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


import { sumTotalbyMonth } from './../../util/sort'

const dataToChart = (data, year) => {
  const lastYear = year-1;
  return [
        {name: 'Janvier', CAN1: sumTotalbyMonth(data, "01", lastYear, true), CAN: sumTotalbyMonth(data, "01", year, true, false), ACN: sumTotalbyMonth(data, "01", year, true, true), PENDINGN: sumTotalbyMonth(data, "01", year, false)},
        {name: 'Février', CAN1: sumTotalbyMonth(data, "02", lastYear, true), CAN: sumTotalbyMonth(data, "02", year, true, false), ACN: sumTotalbyMonth(data, "02", year, false, true), PENDINGN: sumTotalbyMonth(data, "02", year, false)},
        {name: 'Mars', CAN1: sumTotalbyMonth(data, "03", lastYear, true), CAN: sumTotalbyMonth(data, "03", year, true, false), ACN: sumTotalbyMonth(data, "03", year, false, true), PENDINGN: sumTotalbyMonth(data, "03", year, false)},
        {name: 'Avril', CAN1: sumTotalbyMonth(data, "04", lastYear, true), CAN: sumTotalbyMonth(data, "04", year, true, false), ACN: sumTotalbyMonth(data, "04", year, false, true), PENDINGN: sumTotalbyMonth(data, "04", year, false)},
        {name: 'Mai', CAN1: sumTotalbyMonth(data, "05", lastYear, true), CAN: sumTotalbyMonth(data, "05", year, true, false), ACN: sumTotalbyMonth(data, "05", year, false, true), PENDINGN: sumTotalbyMonth(data, "05", year, false)},
        {name: 'Juin', CAN1: sumTotalbyMonth(data, "06", lastYear, true), CAN: sumTotalbyMonth(data, "06", year, true, false), ACN: sumTotalbyMonth(data, "06", year, false, true), PENDINGN: sumTotalbyMonth(data, "06", year, false)},
        {name: 'Juillet', CAN1: sumTotalbyMonth(data, "07", lastYear, true), CAN: sumTotalbyMonth(data, "07", year, true, false), ACN: sumTotalbyMonth(data, "07", year, false, true), PENDINGN: sumTotalbyMonth(data, "07", year, false)},
        {name: 'Aout', CAN1: sumTotalbyMonth(data, "08", lastYear, true), CAN: sumTotalbyMonth(data, "08", year, true, false), ACN: sumTotalbyMonth(data, "08", year, false, true), PENDINGN: sumTotalbyMonth(data, "08", year, false)},
        {name: 'Septembre', CAN1: sumTotalbyMonth(data, "09", lastYear, true), CAN: sumTotalbyMonth(data, "09", year, true, false), ACN: sumTotalbyMonth(data, "09", year, false, true), PENDINGN: sumTotalbyMonth(data, "09", year, false)},
        {name: 'Octobre', CAN1: sumTotalbyMonth(data, "10", lastYear, true), CAN: sumTotalbyMonth(data, "10", year, true, false), ACN: sumTotalbyMonth(data, "10", year, false, true), PENDINGN: sumTotalbyMonth(data, "10", year, false)},
        {name: 'Novembre', CAN1: sumTotalbyMonth(data, "11", lastYear, true), CAN: sumTotalbyMonth(data, "11", year, true, false), ACN: sumTotalbyMonth(data, "11", year, false, true), PENDINGN: sumTotalbyMonth(data, "11", year, false)},
        {name: 'Décembre', CAN1: sumTotalbyMonth(data, "12", lastYear, true), CAN: sumTotalbyMonth(data, "12", year, true, false), ACN: sumTotalbyMonth(data, "12", year, false, true), PENDINGN: sumTotalbyMonth(data, "12", year, false)}
  ]};

export default class Example extends PureComponent {

	render () {
  	return (
      <div style={{width: '100%', height: '50%'}}>
        <ResponsiveContainer>
        	<ComposedChart data={dataToChart(this.props.data, new Date().getFullYear())} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="CAN1" fill="#87CBAC" />
            <Area type="monotone" dataKey="ACN" fill="#8884d8" stroke="#8884d8" />
            <Bar dataKey="CAN" stackId="a" fill="#00B87D" />
            <Bar dataKey="DEPN" stackId="a" fill="#7A7978" />
            <Bar dataKey="PENDINGN" stackId="a" fill="rgb(232, 158, 122)" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
