export const toLowCase = (data) => {
  return data.charAt(0).toUpperCase() + data.substring(1).toLowerCase()
}

export const publicId = (companyName, creationDate) => {

  let name = companyName
  name = name.toString().toLowerCase()
  name = name.replace(/[^a-zA-Z0-9 ]/g,'')
  name = name.replace(/ /g,"_");

  let date = creationDate.toString().substring(9, 13);

  let publicId = null
  publicId = name+'.'+date

  return publicId
}
