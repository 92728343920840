import firebase from './../config/firebase'
import { message } from 'antd'
import { FETCH_API_SOCIETY } from './companyConstants'

import { publicId } from './../util/publicIdGenerator'

export const createCompany = (data) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const userQuery = firestore.collection('users').doc(user.uid);
  let newCompany = {}
  let companies = []
  let dateNow = Date.now();
  let publicKey = publicId(data.companyName, dateNow)
  const {lastName, firstName, sex, job, ...rest} = data
  newCompany = {
    ...rest,
    uid: user.uid,
    publicId: publicKey,
    entities:[
      {
        sex: data.sex,
        job: data.job,
        lastName: data.lastName,
        firstName: data.firstName,
      }
    ],
    settings:{
      timeFrameDashBoard:'3m'
    },
    settingsDevis:{
      footer:'footer',
      lasNumber:null,
      lastNumberFormat:'YYYY-NNN-V',
      paiement:1,
      tva:false,
      type:0
    }
  }
  try{
    await firestore.set(`companies/${dateNow}`,  {...newCompany})
    await userQuery.update({
      companies: firebase.firestore.FieldValue.arrayUnion({
        id: dateNow,
        companyName: data.companyName,
        publicId: publicKey
      }),
      isNewUser:false
    })
    message.success('Nouvelle société enregistrée')
  }catch (e){
    message.error(e)
  }
}

export const updateTimeFrameDashBoard = (data, compid) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  console.log(data, compid);
  const firestore = getFirestore()
  const firebase = getFirebase();
  const compRef = firestore.collection('companies').doc(compid)
  try{
    await compRef.update({
      settings:{
        timeFrameDashBoard: data,
      }
    })
    console.info("Passer les autres réglages sinon ils seront écrasés");
  }catch (e){
    message.error(e)
  }
}

export const updateCompanyEntities = (data, compId, type) =>
async (dispatch, getState, {getFirebase, getFirestore}) => {
  const firestore = getFirestore()
  const firebase = getFirebase();
  const compRef = firestore.collection('companies').doc(compId)
  try{
    if(type == "devis"){
    await compRef.update({
      settingsDevis: data
    })
    } else{
    await compRef.update({
      settingsFactures: data
    })
    }
    message.success('Type MAJ')
  }catch (e){
    message.error(e)
  }
}


export const fetchSociety = (data) =>
  async (dispatch) => {
      try{
        fetch(`https://entreprise.data.gouv.fr/api/sirene/v3/etablissements/${data.siret}`)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.message){
              message.error(result.message)
            } else {
              const { etablissement } = result
              const infos = {
                siren: etablissement.siren,
                siret: etablissement.siret,
                nic: etablissement.nic,
                companyName: etablissement.denomination_usuelle,
                creationDate: etablissement.date_creation,
                adress: etablissement.geo_l4,
                adressComplement: etablissement.complement_adresse,
                zipcode: etablissement.code_postal,
                code: etablissement.activite_principale,
                city: etablissement.libelle_commune,
                sex: etablissement.unite_legale.sexe,
                firstName: etablissement.unite_legale.prenom_1,
                lastName: etablissement.unite_legale.nom,
              }
              dispatch({ type: FETCH_API_SOCIETY, payload:infos })
              message.success('données chargées')
            }
          },
          (error) => {
            console.log(error);
          })
      } catch(e) {
        console.log(e);
      }
  }
