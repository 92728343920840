import React from 'react'
import {Button, Input, Form, Row, Col} from 'antd'

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'

import { TextInput } from './../../../util/Form/field'

import {fetchSociety } from './../../../actions/companyActions'

const validate = combineValidators({
  siret: composeValidators(
    isNumeric({message: 'Nombres seulements'}),
    hasLengthGreaterThan(13)({message:'Trop court'}),
    hasLengthLessThan(15)({message:'Trop long'})
  )(),
})

const actions = {
  fetchSociety
}

class CompanyApiForm extends React.Component {
  state={
    siret:null,
    isLoaded:true,
    items:null,
    error:null
  }

  render(){
    const { items, isLoaded } = this.state
      const { pristine, submitting, handleSubmit } = this.props
      return(
        <Form onFinish={handleSubmit(values => this.props.fetchSociety(values))}>
        <Row gutter={8} align="middle">
          <Col flex="auto">
            <Field
              name="siret"
              labelTitle="Chercher votre société (SIRET)"
              placeholder="SIRET"
              component={TextInput}
            />
          </Col>
          <Col flex="150px">
            <Button
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              Charger données
            </Button>
          </Col>
        </Row>
        </Form>
      )
  }
}

export default connect(null, actions)(reduxForm({form:'fetchSociety', validate})(CompanyApiForm))
