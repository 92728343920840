import React from 'react'
import { Row, Col, Button, Form, Tooltip, Input, Select } from 'antd'

import { useSelector, connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'
import { combineValidators, isRequired } from 'revalidate'


import { updateDevisInformations } from './../../../actions/devisActions'

import { TextInput, TextAreaInput, SelectInput, DatePickerInput } from './../../../util/Form/field'
import { Loading } from './../../../util/Errors'

const { TextArea } = Input;
const { Option } = Select;

const typeOptions = [
  {
    key: '0',
    value: '0',
    name: '+0% (Aucunes charges)',
  },
  {
    key: '1',
    value: '12.8',
    name: '+12,8% (Commerce, Hébergement)',
  },
  {
    key: '2',
    value: '22',
    name: '+22% (Prestation de services, Profession libérales)',
  },
  {
    key: '3',
    value: '30',
    name: '+30% (Charges personnalisées)',
  },
  {
    key: '3',
    value: '40',
    name: '+40% (Charges personnalisées)',
  }
]
const tvaOptions = [
  {
    key: '0',
    value: false,
    name: 'Non'
  },
  {
    key: '1',
    value: true,
    name: 'Oui'
  },
]

const splitOptions = [
  {
    key: '1',
    value: 1,
    name: '1'
  },
  {
    key: '2',
    value: 2,
    name: '2'
  },
  {
    key: '3',
    value: 3,
    name: '3'
  },
  {
    key: '4',
    value: 4,
    name: '4'
  },
  {
    key: '5',
    value: 5,
    name: '5'
  },
  {
    key: '6',
    value: 6,
    name: '6'
  },
]

const paiementOptions = [
  {
    key: '1',
    value: 1,
    name: '1 paiement lors de la facturation'
  },
  {
    key: '2',
    value: 2,
    name: '2 paiements (*50%)'
  },
  {
    key: '3',
    value: 3,
    name: '3 paiments (*33%)'
  },
  {
    key: '4',
    value: 4,
    name: '4 paiements (*25%)'
  },
  {
    key: '5',
    value: 5,
    name: '5 paiements (*20%)'
  },
  {
    key: '6',
    value: 6,
    name: '6 paiements (*15%)'
  },
]

const validate = combineValidators({
  title: isRequired({ message: 'Requis' }),
  number: isRequired({ message: 'Requis' }),
  client: isRequired({ message: 'Requis' }),
  tva: isRequired({ message: 'Requis' }),
  type: isRequired({ message: 'Requis' }),
  paiement: isRequired({ message: 'Requis' })
})

const mapState = (state) => {
  let clientsEdited = []
  clientsEdited = state.firestore.ordered.clients && state.firestore.ordered.clients.map(client => {
    return {
      name: client.society + ' (' + client.surname + ' ' + client.name + ')',
      value: client.id
    }
  }
  )

  return {
    initialValues: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
    auth: state.firebase.auth,
    clientsEdited,
    loading: state.form && state.form.devisInfosForm,
  }
}

const actions = {
  updateDevisInformations
}

class DevisInfosForm extends React.Component {

  onFormSubmit = values => {
    this.props.updateDevisInformations(values, values.id).then(() => this.props.initialize(values))
  };

  render() {
    console.info('mettre en place les dates');
    const { handleSubmit, clientsEdited, loading, pristine, submitting } = this.props
    return (
      <Form onFinish={handleSubmit(this.onFormSubmit)}>
        <Row type="flex" justify="space-around" gutter={8}>
          <Col span={8}>
            <Field
              name="title"
              labelTitle="Titre du Devis"
              placeholder="Montage de 4 capsules vidéos"
              component={TextInput}
            />
          </Col>
          <Col span={4}>
            <Field
              name="eventDate"
              labelTitle="Date de l'évènement"
              placeholder="12/12/2020"
              component={TextInput}
            />
          </Col>
          <Col span={12}>
            <Field
              name="clientId"
              labelTitle="Client"
              component={SelectInput}
              options={clientsEdited}
              showSearch
              placeholder="Client"
            />
          </Col>
          <Col span={24}>
            <Field
              name="details"
              labelTitle="Description (Ce devis concerne ...)"
              placeholder="la création du site internet, la conception et le design du site, la maintenance et la location sur 1 an du site."
              component={TextAreaInput}
            />
          </Col>

          <Col span={8}>
            <Field
              name="number"
              labelTitle="Numéro"
              labelDetails="Le numéro se met à jour automatiquement, veuillez à garder une continuité dans vos numéros de devis et factures."
              placeholder="019"
              component={TextInput}
            />
          </Col>
          <Col span={6}>
            <Field
              name="type"
              labelTitle="Type de Devis"
              component={SelectInput}
              options={typeOptions}
              defaultValue="0"
              placeholder="type"
            />
          </Col>
          <Col span={6}>
            <Field
              name="tva"
              labelTitle="TVA"
              labelDetails="La TVA est applicable à partir du premier jour du mois de dépassement de la 1ère limite d'auto-entreprise"
              component={SelectInput}
              options={tvaOptions}
              defaultValue="Non"
              placeholder="type"
            />
          </Col>
          <Col span={4}>
            <Field
              name="split"
              labelTitle="Split"
              component={SelectInput}
              options={splitOptions}
            />
          </Col>

          <Col span={8}>
            <Field
              name="creationDate"
              labelTitle="Date de Création"
              placeholder="12/12/2020"
              component={TextInput}
            />
          </Col>
          <Col span={8}>
            <Field
              name="limitDate"
              labelTitle="Date Limite"
              placeholder="12/12/2020"
              component={TextInput}
            />
          </Col>
          <Col span={8}>
            <Field
              name="paiement"
              labelTitle="Echéance du Paiement"
              component={SelectInput}
              options={paiementOptions}
            />
          </Col>
          <Col span={24}>
            <Field
              name="footer"
              labelTitle="Pied de Page"
              placeholder="Paiement dans un délai de 60 jours maximum à compter de la date de réception de la facture, si les modalités de facturation sont conformes et valides. Au-delà de ce délai une pénalité de retard de 10% du montant total vous sera facturée."
              component={TextAreaInput}
            />
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button
              loading={loading && loading.submitting}
              type="primary"
              htmlType="submit"
              disabled={pristine || submitting}
            >
              Sauvegarder
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default
  withFirestore(
    connect(mapState, actions)(
      reduxForm({ form: 'devisInfosForm', validate })
        (DevisInfosForm)
    )
  )
