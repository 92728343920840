import React from 'react'
import { Steps, Button, Result } from 'antd'
import { FileAddOutlined, SendOutlined, SmileOutlined, } from '@ant-design/icons';

import { connect } from 'react-redux'

import { Bloc } from './../../../util/Bloc'

const { Step } = Steps;

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  devisSelected: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
  loading: state.firestore.status.requested
})

class Status extends React.Component {
  render() {
    const { devisSelected } = this.props
    return (
      <Bloc>

        {devisSelected.statut == 'redaction' ?
          <div>
            <Steps>
              <Step status="finish" icon={<FileAddOutlined />} />
              <Step status="process" title="Rédaction" />
              <Step status="wait" title="Envoyé" icon={<SendOutlined />} />
              <Step status="wait" title="Validé" icon={<SmileOutlined />} />
            </Steps>
            <Result
              icon={<FileAddOutlined />}
              title="Devis en cours de Rédaction"
              extra={<Button type="primary">Envoyer</Button>}
            />
          </div>
          : devisSelected.statut == 'sent' ?
            <div>
              <Steps>
                <Step status="finish" title="Rédaction" />
                <Step status="finish" title="Envoyé" icon={<SendOutlined />} />
                <Step status="wait" title="Validé" icon={<SmileOutlined />} />
              </Steps>
              <Result
                icon={<FileAddOutlined />}
                title="Devis en cours de Rédaction"
                extra={<Button type="primary">Envoyer</Button>}
              />
            </div>
            :
            <div>
              <Steps>
                <Step status="finish" icon={<FileAddOutlined />} />
                <Step status="process" title="Rédaction" />
                <Step status="wait" title="Envoyé" icon={<SendOutlined />} />
                <Step status="wait" title="Validé" icon={<SmileOutlined />} />
              </Steps>
              <Result
                icon={<FileAddOutlined />}
                title="Devis en cours de Rédaction"
                extra={<Button type="primary">Envoyer</Button>}
              />
            </div>
        }

        {/*<Step status="finish" title={<Button>Validé</Button>} icon={<SmileOutlined />} />*/}
      </Bloc>

    )
  }
}

export default connect(mapState)(Status)
