import moment from 'moment'

/*
format (optionnel):
YYYY-NNN (pour les notes de frais et les devis/factures)
YYYY-MM-NNN (pour les notes de frais mensuelle)
YYYY-NNN-V (pour les devis/factures)

newVersion true/false uniquement pour indenter les versions d'un devis

separator (optionnel si différent de '-')
*/

export const nextId = (prevIdSelected, formatSelected, newVersionSelected, separatorSelected) => {

  let format = formatSelected ? formatSelected : 'YYYY-MM-NNN'
  let separator = separatorSelected ? separatorSelected : '-'
  let prevId = prevIdSelected ? prevIdSelected.split(separator) : '0'
  let newVersion = newVersionSelected ? true : false

  let YYYY = moment().format('YYYY')
  let MM = moment().format('MM')

  let result = 'NaN'

  if(format === "YYYY-NNN"){
    let prevYYYY = prevId[0]
    prevId = parseFloat(prevId[1])

    if(prevYYYY === YYYY) { // si l'année est la même on continue l'indentation, sinon on recommence à 001
      let newId = prevId +1
      let nextId = newId > 99 ? newId : (newId > 9) ? '0'+newId : '00'+newId
      result = YYYY+separator+nextId
    } else {
      result = YYYY+separator+'001'
    }

  }else if(format === "YYYY-MM-NNN"){
    let prevYYYY = prevId[0]
    let prevMM = prevId[1]
    prevId = parseFloat(prevId[2])

    if(prevYYYY === YYYY && prevMM === MM){ // si même année et même mois
      let newId = prevId +1
      let nextId = newId > 99 ? newId : (newId > 9) ? '0'+newId : '00'+newId
      result = YYYY+separator+MM+separator+nextId
    } else {
      result = YYYY+separator+MM+separator+'001'
    }

  }else if(format === "YYYY-NNN-V"){
    let prevYYYY = prevId[0]
    let prevV = parseFloat(prevId[2])
    prevId = parseFloat(prevId[1])

    let newId = newVersion ? prevId : prevId +1 //si newVersion on indente pas l'id
    let nextId = newId > 99 ? newId : (newId > 9) ? '0'+newId : '00'+newId
    let nextV = newVersion ? prevV+1 : prevV //si newVersion on indente newV

    if(prevYYYY === YYYY) { // si l'année est la même on continue l'indentation, sinon on recommence à 001
      result = YYYY+separator+nextId+separator+nextV
    } else {
      result = newVersion? prevYYYY+separator+nextId+separator+nextV : YYYY+separator+'001'+separator+'1'
    }

  }

  return result
}
