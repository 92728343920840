import React from 'react'
import { Button, Divider, Radio, Tooltip } from 'antd'
import { EditOutlined, SendOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { Link } from "react-router-dom";

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

import { copyDevis, newDevis, updateState, newFactureFromDevis, newFactureAcompte } from './../../../actions/devisActions'

const mapState = (state) => ({
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],

})

const actions = {
  newDevis,
  copyDevis,
  updateState,
  newFactureFromDevis,
  newFactureAcompte
}

class ActionBar extends React.Component {

  render() {
    const { editId, data, newDevis, myCompany, updateState, newFactureFromDevis } = this.props
    return (
      <div style={{ marginBottom: '15px' }}>
        <Button style={{ marginRight: '5px' }} onClick={() => newDevis(myCompany.id, myCompany.publicId, myCompany.settingsDevis)}>Nouveau</Button>
        <Button style={{ marginRight: '5px' }} type='primary' disabled={!editId}><Link to={editId}>Ouvrir</Link></Button>
        <Button style={{ marginRight: '5px' }} onClick={() => console.log('afaire')} disabled={!editId}>Nouvelle version</Button>
        <Button style={{ marginRight: '5px' }} disabled={!editId}>Copier</Button>
        <Button style={{ marginRight: '5px' }}
          onClick={() => newFactureFromDevis(myCompany.id, myCompany.publicId, data, myCompany.settingsDevis, myCompany.settingsFactures)}
          disabled={!editId}>
          Générer la facture
        </Button>
        <Button style={{ marginRight: '5px' }}
          onClick={() => newFactureAcompte(myCompany.id, myCompany.publicId, data, myCompany.settingsDevis, myCompany.settingsFactures)}
          disabled={!editId, data.statut != "validated"}>
          Facture d'acompte
        </Button>
        <Button style={{ marginRight: '10px' }} danger disabled={!editId, data.statut != "redaction"}>Supprimer</Button>
        <Radio.Group disabled={!editId} onChange={(e) => updateState(editId, e.target.value)} >
          <Radio.Button value="redaction"><Tooltip title="Rédaction"><EditOutlined /></Tooltip> </Radio.Button>
          <Radio.Button value="sent"><Tooltip title="Envoyé"><SendOutlined /></Tooltip> </Radio.Button>
          <Radio.Button value="validated"><Tooltip title="Validé"><CheckOutlined /></Tooltip> </Radio.Button>
          <Radio.Button value="declined"><Tooltip title="Refusé"><CloseOutlined /></Tooltip> </Radio.Button>
        </Radio.Group>
      </div>
    )
  }
}

export default withFirestore(connect(mapState, actions)(ActionBar))
