import React from 'react'
import {Row, Col, Button, Divider} from 'antd'
import { slide as Menu } from 'react-burger-menu'
import {Redirect, Link} from 'react-router-dom'

import { DashboardOutlined, VideoCameraOutlined, BankOutlined, TeamOutlined, ControlOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
import { logout } from './../../actions/authActions'

import './menu.css'

const mapState = (state) => ({
  profile: state.firebase.profile
})

const actions = {
logout
}

class MenuBurger extends React.Component {
  state={
    menuVisible:false,
  }

  closeMenu () {
    this.setState({menuVisible: false})
  }

  handleStateChange (state) {
    this.setState({menuVisible: state.isOpen})
  }


  render(){
    const { redirect, menuVisible, } = this.state;
    const {  profile, logout} = this.props
    return(
      <Menu isOpen={menuVisible} onStateChange={(state) => this.handleStateChange(state)}>

        <Link to="/" style={{color:'white'}} onClick={() => this.closeMenu()}>
          <div style={{height: '32px', margin: '10px', textAlign:'center', color:'white', fontWeight:'bold'}}>
          Kompta.fr
          </div>
        </Link>

        <Link className="menu-item" to={"/about/"} onClick={() => this.closeMenu()}>
          <div style={{marginLeft:'10px'}}>
            <DashboardOutlined style={{marginRight:'15px'}}/>
            <span className="nav-text">Accueil</span>
          </div>
        </Link>
        <Divider style={{backgroundColor:'#40a9ff', margin:'10px 0 10px 0'}}/>

        {profile.companies && profile.companies.map(company =>
        <div key={company.id}>
          <div className="menu-title" style={{marginLeft:'10px'}}>
            <BankOutlined style={{marginRight:'15px'}}/>
            <span className="nav-text">{company.companyName}</span>
          </div>
            <Link className="menu-sub-item" to={"/"+company.publicId+"/TableauDeBord/"} onClick={() => this.closeMenu()}>
              <div style={{marginLeft:'30px'}}>
                <span className="nav-text">Tableau de Bord</span>
              </div>
            </Link>
            <Link className="menu-sub-item" to={"/"+company.publicId+"/Devis/"} onClick={() => this.closeMenu()}>
              <div style={{marginLeft:'30px'}}>
                <span className="nav-text">Devis</span>
              </div>
            </Link>
            <Link className="menu-sub-item" to={"/"+company.publicId+"/Factures/"} onClick={() => this.closeMenu()}>
              <div style={{marginLeft:'30px'}}>
                <span className="nav-text">Factures</span>
              </div>
            </Link>
            <Link className="menu-sub-item" to={"/"+company.publicId+"/Depenses/"} onClick={() => this.closeMenu()}>
              <div style={{marginLeft:'30px'}}>
                <span className="nav-text">Depenses</span>
              </div>
            </Link>
            <Link className="menu-sub-item" to={"/"+company.publicId+"/Comptabilite/"} onClick={() => this.closeMenu()}>
              <div style={{marginLeft:'30px'}}>
                <span className="nav-text">Comptabilité</span>
              </div>
            </Link>
            <Link className="menu-sub-item" to={"/"+company.publicId+"/Parametres/"} onClick={() => this.closeMenu()}>
              <div style={{marginLeft:'30px'}}>
                <span className="nav-text">Paramêtres</span>
              </div>
            </Link>
            <Divider style={{backgroundColor:'#40a9ff', margin:'10px 0 10px 0'}}/>
          </div>
        )}

        <Link className="menu-item" to={"/Clients/"} onClick={() => this.closeMenu()}>
          <div style={{marginLeft:'10px'}}>
            <TeamOutlined style={{marginRight:'15px'}}/>
            <span className="nav-text">Clients</span>
          </div>
        </Link>
        <Divider style={{backgroundColor:'#40a9ff', margin:'10px 0 10px 0'}} />

        <Link className="menu-item" to={"/Articles/"} onClick={() => this.closeMenu()}>
          <div style={{marginLeft:'10px'}}>
            <VideoCameraOutlined style={{marginRight:'15px'}}/>
            <span className="nav-text">Articles</span>
          </div>
        </Link>
        <Divider style={{backgroundColor:'#40a9ff', margin:'10px 0 10px 0'}} />

        <Link className="menu-item" to={"/Abonnement/"} onClick={() => this.closeMenu()}>
          <div style={{marginLeft:'10px'}}>
            <ControlOutlined style={{marginRight:'15px'}}/>
            <span className="nav-text">Abonnement</span>
          </div>
        </Link>
        <Divider style={{backgroundColor:'#40a9ff', margin:'10px 0 10px 0'}} />

        <Link className="menu-item" to={"/Parametres/"} onClick={() => this.closeMenu()}>
          <div style={{marginLeft:'10px'}}>
            <SettingOutlined style={{marginRight:'15px'}}/>
            <span className="nav-text">Paramêtres</span>
          </div>
        </Link>
        <Divider style={{backgroundColor:'#40a9ff', margin:'10px 0 10px 0'}} />

        <Link className="menu-item" to={"/"} onClick={() => logout.then(this.closeMenu())}>
          <div style={{marginLeft:'10px'}}>
            <LogoutOutlined style={{marginRight:'15px'}}/>
            <span className="nav-text">Déconnexion</span>
          </div>
        </Link>
      </Menu>
    )
  }
}

export default connect(mapState, actions)(MenuBurger)
