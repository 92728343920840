import React from 'react'
import {Row, Col, Button, Switch} from 'antd'
import { MinusCircleOutlined, PlusOutlined, DragOutlined, DownSquareOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'

import { Field, reduxForm, FieldArray, formValueSelector} from 'redux-form'
import { TextInputWoError, NumberInputWoError, SelectInputWoError} from './../../../util/Form/field'

const selector = formValueSelector('devisArticlesForm') // on récupère les values du form

const unite = [
  {
    key:'h',
    value:'h',
    name:'h',
  },
  {
    key:'dj',
    value:'½j',
    name:'½j',
  },
  {
    key:'j',
    value:'j',
    name:'j',
  },
  {
    key:'s',
    value:'s',
    name:'s',
  },
  {
    key:'m',
    value:'m',
    name:'m',
  },
  {
    key:'km',
    value:'km',
    name:'km',
  },
  {
    key:'m2',
    value:'m²',
    name:'m²',
  },
  {
    key:'m3',
    value:'m³',
    name:'m³',
  },
  {
    key:'kg',
    value:'kg',
    name:'kg',
  },
  {
    key:'u',
    value:'u',
    name:'u',
  }
]

class ArticlesFormField extends React.Component {

  calcHC = (articleSelected, qSelected, rSelected, articles, chargesSelected) => {

    let refArt = articleSelected && articles && articles.find(art => art.id === articleSelected)
    let somme
    let totalHC
    let total
    let taxesInt
    let quantite = qSelected ? parseFloat(qSelected) : 1
    let remise = rSelected ? parseFloat(rSelected) : 0
    let charges = chargesSelected ? parseFloat(chargesSelected) : 0

    if(refArt){
      let prix = parseFloat(refArt.price)
      taxesInt = refArt.taxes ? (prix * parseFloat(refArt.taxes) / 100) : 0
      somme = (prix + taxesInt) * quantite
      totalHC = somme - (somme * remise / 100 )
      total = totalHC + (totalHC * charges / 100 )
      total = Math.round(total * 100) / 100;
    } else {
      total = 0
    }

    return total
  }


  render(){
    console.info('faire validate');
    const { fields, field, i, articlesEdited, articles, categorySelected, descSelected, articleSelected, qSelected, rSelected, devisSelected } = this.props
    return(
      <Row type="flex" key={i} justify="space-around" gutter={4}>
        <Col span={descSelected ? 8 : 11}>
        <Field
          name={`${field}.article`}
          component={SelectInputWoError}
          options={articlesEdited}
          showSearch
          placeholder="Article"
        />
        </Col>
        <Col span={descSelected ? 7 : 4}>
        <Field
          name={`${field}.desc`}
          placeholder="Titre personnalisé"
          component={TextInputWoError}
          allowClear
        />
        </Col>
        <Col span={2}>
        <Field
          name={`${field}.q`}
          placeholder="1"
          defaultValue="1"
          component={NumberInputWoError}
        />
        </Col>
        <Col span={2}>
        <Field
          name={`${field}.u`}
          defaultValue='u'
          placeholder="J"
          options={unite}
          component={SelectInputWoError}
        />
        </Col>
        <Col span={2}>
        <Field
          name={`${field}.r`}
          placeholder="0"
          defaultValue='0'
          component={NumberInputWoError}
          max={100}
        />
        </Col>
        <Col span={2} style={{marginTop:'10px', textAlign:'right'}}>
          {/*this.calcHC(articleSelected, qSelected, rSelected, articles, devisSelected.type)*/}
          <Field
            name={`${field}.totalHC`}
            props={{
              type:'text'
            }}
            value="e"
            component={(props) => <div>{props.data} €</div>}
            {...{
              data:this.calcHC(articleSelected, qSelected, rSelected, articles, devisSelected.type)
            }}
          />
        </Col>
        <Col span={1} style={{marginTop:'10px', textAlign:'center'}}>
          <MinusCircleOutlined
            onClick={() => fields.remove(i)}
          />
        </Col>
      </Row>
    )
  }
}

export default
  connect(
    (state, props) => ({
      categorySelected: selector(state, `${props.field}.category`),
      articleSelected: selector(state, `${props.field}.article`),
      descSelected: selector(state, `${props.field}.desc`),
      qSelected: selector(state, `${props.field}.q`),
      rSelected: selector(state, `${props.field}.r`),
      devisSelected: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
    })
  )(ArticlesFormField)
