import React from 'react'
import {Radio} from 'antd'

import { connect } from 'react-redux'
import { updateTimeFrameDashBoard } from './../../../actions/companyActions'

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    loading: state.firestore.status.requested
})

const actions = {
  updateTimeFrameDashBoard
}

class TimeframeSelect extends React.Component {
  render(){
    const { myCompany } = this.props
    return(
      <Radio.Group onChange={e => this.props.updateTimeFrameDashBoard(e.target.value, myCompany.id)} defaultValue={myCompany.settings.timeFrameDashBoard ? myCompany.settings.timeFrameDashBoard : '3m'}>
        <Radio.Button value="1y">1 an</Radio.Button>
        <Radio.Button value="6m">6 mois</Radio.Button>
        <Radio.Button value="3m">3 mois</Radio.Button>
        <Radio.Button value="1m">30 jours</Radio.Button>
        <Radio.Button value="7d">7 jours</Radio.Button>
      </Radio.Group>
    )
  }
}

export default connect(mapState, actions)(TimeframeSelect)
