import React from 'react'
import {Row, Col, Button, Breadcrumb} from 'antd'
import { HomeOutlined, ArrowsAltOutlined } from '@ant-design/icons';

import { withRouter, Link } from "react-router-dom";

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, withFirestore } from 'react-redux-firebase'

import { Bloc } from './../../../util/Bloc'

import Depenses from './modules/depenses'
import Resultat from './modules/resultat'

import TimeframeSelect from './timeframeSelect'

const query = (props) => {
  return [
    {
      collection:'depenses',
      where:['compId', '==', props.match.params.idCompany],
      orderBy:'date',
      storeAs:'depenses'
    },
  ]
}

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    depenses: state.firestore.ordered.depenses,
    loading: state.firestore.status.requested
})

class Dashboard extends React.Component {

  render(){
    const { myCompany, depenses } = this.props
    console.log(depenses);
    return(
      <div>
      <Row type='flex' justify="space-between">
      <Col>
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined/>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      </Col>
      <Col>
        <TimeframeSelect/>
      </Col>
      </Row>

        <Row type="flex" gutter={[8,10]} justify="space-around" align="middle" style={{marginTop:'50px'}}>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6} key="1">
          <Resultat/>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6} key="2">
          <Depenses data={depenses} />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6} key="3">
          <Resultat/>
        </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(withFirestore(connect(mapState)(firestoreConnect(query)(Dashboard))))
