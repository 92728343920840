import React from 'react'
import { Form, Input, Select, Popover, InputNumber, DatePicker} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

export const TextInput = ({labelTitle, prefix, style, labelDetails, input, allowClear, type, placeholder, disabled, meta:{touched, error}}) => {
  return(
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        validateStatus={touched && error ? "error" : "success"}
        help={touched && error && error}
      >
        <Input
          style={{marginBottom:4}}
          {...input}
          style={style && style}
          prefix={prefix}
          allowClear={allowClear ? true : false}
          placeholder={placeholder ? placeholder : labelTitle}
          type={type ? type : "text"}
          disabled={disabled ? true : false}
        />
      </Form.Item>
    </div>
  )
}

export const TextInputWoError = ({labelTitle, style, prefix, labelDetails, input, allowClear, type, placeholder, disabled, meta:{touched, error}}) => {
  return(
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        style={{marginBottom:0}}
        validateStatus={touched && error ? "error" : "success"}
      >
        <Input
          style={{marginBottom:4}}
          {...input}
          prefix={prefix}
          style={ style && style }
          allowClear={allowClear ? true : false}
          placeholder={placeholder ? placeholder : labelTitle}
          type={type ? type : "text"}
          disabled={disabled ? true : false}
        />
      </Form.Item>
    </div>
  )
}

export const TextAreaInput = ({labelTitle, prefix, labelDetails, input, type, allowClear, placeholder, disabled, meta:{touched, error}}) => {
  return(
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        validateStatus={touched && error ? "error" : "success"}
        help={touched && error && error}
      >
        <TextArea
          {...input}
          style={{marginBottom:4}}
          allowClear={allowClear ? true : false}
          placeholder={placeholder ? placeholder : labelTitle}
          disabled={disabled ? true : false}
        />
      </Form.Item>
    </div>
  )
}

export const DatePickerInput = ({labelTitle, prefix, input, labelDetails, allowClear, placeholder, disabled, meta:{touched, error}}) => {
  console.log(input.value);
  return(
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        validateStatus={touched && error ? "error" : "success"}
        help={touched && error && error}
      >
        <DatePicker
          {...input}
          format="DD-MM-YYYY"
          style={{marginBottom:4, width:'100%'}}
          allowClear={allowClear ? true : false}
          placeholder={placeholder ? placeholder : 'JJ-MM-AAAA'}
          disabled={disabled ? true : false}
          defaultValue=""
          //defaultValue={moment.unix(input.value.seconds).format('DD-MM-YYYY')}
          onFocus={e => e.preventDefault()}
          onBlur={e => e.preventDefault()}
        />
      </Form.Item>
    </div>
  )
}

export const NumberInput = ({labelTitle, labelDetails, input, max, formatter, parser, placeholder, disabled, meta:{touched, error}}) => {
  return(
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        validateStatus={touched && error ? "error" : "success"}
        help={touched && error && error}
      >
        <InputNumber
          style={{marginBottom:4, width:'100%'}}
          min={0}
          max={max && max}
          {...input}
          {...formatter}
          {...parser}
          placeholder={placeholder ? placeholder : labelTitle}
          disabled={disabled ? true : false}
        />
      </Form.Item>
    </div>
  )
}

export const NumberInputWoError = ({labelTitle, labelDetails, max, input, formatter, parser, placeholder, disabled, meta:{touched, error}}) => {
  return(
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        validateStatus={touched && error ? "error" : "success"}
        style={{marginBottom:0}}
      >
        <InputNumber
          style={{marginBottom:4, width:'100%'}}
          min={0}
          max={max && max}
          {...input}
          {...formatter}
          {...parser}
          placeholder={placeholder ? placeholder : labelTitle}
          disabled={disabled ? true : false}
        />
      </Form.Item>
    </div>
  )
}

export const SelectInput = ({name, labelTitle, defaultValue, labelDetails, input, type, placeholder, showSearch, options, meta:{touched, error}}) => {
  return (
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        validateStatus={touched && error ? "error" : "success"}
        help={touched && error && error}
      >
        <Select
          name={name}
          value={input.value ? input.value : (defaultValue ? defaultValue : null)}
          onChange={(e, data) => input.onChange(data.props.value)}
          showSearch={showSearch ? showSearch : false}
          filterOption={
            (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{width:"100%", marginBottom:'4px'}}
          placeholder={placeholder}
        >
        {options && options.map(option =>
          <Option key={option.value} value={option.value} >{option.name}</Option>
        )}
        </Select>
      </Form.Item>
    </div>
  )
}

export const SelectInputWoError = ({name, allowClear, labelTitle, defaultValue, labelDetails, input, type, placeholder, showSearch, options, meta:{touched, error}}) => {
  return (
    <div>
      <h5 style={{margin:7, marginBottom:2, fontWeight:'bold'}}>{labelTitle}
      {labelDetails &&
        <Popover content={labelDetails} trigger="hover">
          <InfoCircleOutlined style={{paddingLeft:'7px'}} />
        </Popover>
      }
      </h5>
      <Form.Item
        validateStatus={touched && error ? "error" : "success"}
        style={{marginBottom:0}}
      >
        <Select
          value={input.value ? input.value : (defaultValue ? defaultValue : null)}
          onChange={(e, data) => input.onChange(data.props.value)}
          showSearch={showSearch ? showSearch : false}
          allowClear={allowClear ? allowClear : false}
          filterOption={
            (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{width:"100%", marginBottom:'4px'}}
          placeholder={placeholder}
        >
        {options && options.map(option =>
          <Option key={option.value} value={option.value} data={option} >{option.name}</Option>
        )}
        </Select>
      </Form.Item>
    </div>
  )
}
