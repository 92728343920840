import React from 'react'
import { Card, Form, Button, Col, Row, Alert } from 'antd';

import { MinusCircleOutlined, PlusOutlined, } from '@ant-design/icons';
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { createValidator, composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric } from 'revalidate'

import { postArticle, updateArticle } from './../../actions/articlesActions'

import { TextInput, SelectInput, NumberInput } from './../../util/Form/field'
import { TextInputWoError, NumberInputWoError, SelectInputWoError } from './../../util/Form/field'


const isValidNumber = createValidator(
  number => value => {
    if (value && !/^[0-9,\.]*$/.test(value)) {
      return number
    }
  },
  'Nombre invalide'
)

const validate = combineValidators({
  title: composeValidators(
    isRequired({ message: 'Requis' }),
    hasLengthGreaterThan(3)({ message: 'Trop court' }),
    hasLengthLessThan(150)({ message: 'Trop long' })
  )(),
  price: composeValidators(
    isRequired({ message: 'Requis' }),
    isValidNumber,
  )(),
  taxes: composeValidators(
    isRequired({ message: 'Requis' }),
    isValidNumber,
  )(),
  ref: composeValidators(
    hasLengthLessThan(7)({ message: 'Trop long' })
  )(),
})

const mapState = (state, props) => {
  let initialValues = {}

  if (props.editId && state.firestore.data.articles) {
    initialValues = state.firestore.data.articles[props.editId]
  } else {
    initialValues = null
  }
  return {
    initialValues,
    loading: state.form && state.form.article
  }
}

const actions = {
  postArticle, updateArticle
}

let rendreContentForm = ({ fields }) => (
  <div>
    {fields.map((field, i) => (
      <Row type="flex" key={i} justify="space-around" gutter={4}>
        <Col span={2}>
          <Field
            name={`${field}.q`}
            placeholder="1"
            defaultValue="1"
            component={NumberInputWoError}
          />
        </Col>
        <Col span={19}>
          <Field
            name={`${field}.title`}
            placeholder="Contenu"
            component={TextInputWoError}
            allowClear
          />
        </Col>
        <Col span={2}>
          <Field
            name={`${field}.price`}
            placeholder="100"
            component={TextInputWoError}
          />
        </Col>

        <Col span={1} style={{ marginTop: '10px', textAlign: 'center' }}>
          <MinusCircleOutlined
            onClick={() => fields.remove(i)}
          />
        </Col>
      </Row>
    ))}
    <Row justify="center" gutter={8} style={{ marginTop: '4px' }}>
      <Col>
        <Button
          onClick={() => fields.push({})}
          block
        >
          <PlusOutlined /> Ajouter un article
        </Button>
      </Col>
    </Row>
  </div>
)

class ArticleForm extends React.Component {

  componentDidUpdate() {
    if (!this.props.editId && this.props.cancelEdit) {
      this.props.destroy()
    }
  }

  onFormSubmit = values => {
    if (!this.props.editId) {
      console.log('add');
      this.props.postArticle(values)
        .then(this.props.reset)
    } else {
      console.log('edit');
      this.props.updateArticle(values, this.props.editId)
      this.props.destroy()
      this.props.resetParent()
    }
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, editId, loading, fields } = this.props
    return (
      <Form onFinish={handleSubmit(this.onFormSubmit)} > {/* this.props.handleSubmit(this.onFormSubmit) on réalise la fonction handleSubmit de Redux sur onFormSubmit*/}
        <Row gutter={16}>
          <Col span={6}>
            <Field
              name="ref"
              labelTitle="Ref."
              placeholder="CAM"
              component={TextInput}
            />
          </Col>
          <Col span={18}>
            <Field
              name="title"
              labelTitle="Nom de l'article"
              placeholder="Une unité de tournage"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Field
              name="description"
              labelTitle="Description"
              placeholder="Canon 5D mark IV, trépied, objectifs"
              component={TextInput}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FieldArray name='content' component={rendreContentForm} />

          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field
              name="price"
              labelDetails="Prix net de l'article."
              labelTitle="Prix HC/U"
              placeholder="25"
              component={NumberInput}
            />
          </Col>
          <Col span={12}>
            <Field
              name="taxes"
              labelTitle="Marge"
              labelDetails={
                <p>
                  Ce pourcentage optionnel est la marge net de l'entreprise sur l'article. <br />
                  Attention, ce taux ne représente pas les cotisations, <br />
                  qui s'appliquent automatiquement lors du devis.
                  {/*Taux AutoEntreprises: <br/>
                   - Vente de marchandises <b>+13 %</b><br/>
                   - Prestation de services commerciale ou artisanale <b>+22,3 %</b><br/>
                   - Prestation de services libérale <b>+22,2 %</b>*/}
                </p>
              }
              /*formatter={value => `${value}%`}*/
              placeholder="20"
              component={NumberInput}
            />
          </Col>
        </Row>
        {/*editId &&
            <Alert
              style={{marginBottom:'10px'}}
              message="Si vous modifiez un article, ses occurances seront modifiées si vous réengestrez. Par exemple les entêtes dans les devis existants seront modifiés."
              type="warning"
              showIcon
            />*/}
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={reset}
            style={{ marginRight: 8 }}
            disabled={pristine || submitting}
          >
            Effacer
          </Button>
          <Button
            loading={loading && loading.submitting}
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            {editId ? 'Modifier' : 'Sauvegarder'}
          </Button>
        </div>
      </Form>
    );
  }
}

export default connect(mapState, actions)(reduxForm({ form: 'article', validate })(ArticleForm))
