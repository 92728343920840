import React from 'react'
import {Row, Col, Form, Modal, Button, Divider, message } from 'antd';

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'

import { TextInput, SelectInput } from './../../../util/Form/field'
import CallToAction from './../../Home/callToAction'
import CompanyApi from './CompanyAPI'

import { createCompany } from './../../../actions/companyActions'

const validate = combineValidators({
  siren: composeValidators(
    isRequired({message: 'Requis'}),
    isNumeric({message: 'Nombre seulement'}),
    hasLengthGreaterThan(8)({message:'Trop court'}),
    hasLengthLessThan(10)({message:'Trop long'})
  )(),
  nic: composeValidators(
    isRequired({message: 'Requis'}),
    isNumeric({message: 'Nombre seulement'}),
    hasLengthGreaterThan(4)({message:'Trop court'}),
    hasLengthLessThan(6)({message:'Trop long'})
  )(),
  adress: isRequired({message: 'Requis'}),
  zipcode: composeValidators(
    isRequired({message: 'Requis'}),
    isNumeric({message: 'Nombre seulement'}),
    hasLengthGreaterThan(4)({message:'Trop court'}),
    hasLengthLessThan(6)({message:'Trop long'})
  )(),
  city: composeValidators(
    isRequired({message: 'Requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(25)({message:'Trop long'})
  )(),
  firstName: composeValidators(
    isRequired({message: 'Requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(25)({message:'Trop long'})
  )(),
  lastName: composeValidators(
    isRequired({message: 'Requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(25)({message:'Trop long'})
  )(),
})

const mapState = (state, props) => {
  let initialValues = {}

  if(state.fetchSociety){
    initialValues = state.fetchSociety
  } else {
    initialValues = null
  }
  return{
    initialValues,
    profile : state.firebase.profile
  }
}

const actions = {
  createCompany
}

class AddCompany extends React.Component {
  state = {
    visible: false,
    isLoaded: true,
  };

  componentDidMount(){
    if(this.props.profile.isNewUser === true){
      this.setState({
        visible:true
      })
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = values => {
    this.props.createCompany(values)
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { handleSubmit, type } = this.props
    return (
      <div>
        {type == "callToAction" ?
        <a onClick={this.showModal}>
          <CallToAction icon="bank" text="Pour commencer," callToAction="Ajouter votre entreprise" />
        </a>
        :
        <Button type="primary" onClick={this.showModal}>
          Ajouter une entreprise
        </Button>
        }
        <Modal
          title="Ajouter une société"
          visible={this.state.visible}
          style={{ top: 65 }}
          bodyStyle={{padding:'12px'}}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
           <Button key="back" onClick={this.handleCancel}>
             Annuler
           </Button>,
           <Button key="submit" type="primary" onClick={handleSubmit(this.handleOk)}>
             Valider
           </Button>,
         ]}
        >
          <CompanyApi request={this.request}/>
          <Divider>Informations Société</Divider>
          <Form>
            <Row gutter={8}>
              <Col flex="40%">
              <Field
                name="siren"
                labelTitle="SIREN"
                placeholder="111222333"
                component={TextInput}
              />
              </Col>
              <Col flex="30%">
              <Field
                name="nic"
                labelTitle="NIC"
                placeholder="12345"
                component={TextInput}
              />
              </Col>
              <Col flex="30%">
              <Field
                name="creationDate"
                labelTitle="Creation"
                placeholder="DD/MM/YYYY"
                component={TextInput}
              />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col flex="auto">
              <Field
                name="companyName"
                labelTitle="Nom de la société"
                labelDetails="Ce nom sera visible sur les devis et factures"
                placeholder="Apple"
                component={TextInput}
              />
              </Col>
              <Col flex="20%">
              <Field
                name="code"
                labelTitle="Code"
                placeholder="71.11Z"
                component={TextInput}
              />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col flex="50%">
              <Field
                name="adress"
                labelTitle="Adresse"
                placeholder="15 rue des capucins"
                component={TextInput}
              />
              </Col>
              <Col flex="50%">
              <Field
                name="adressComplement"
                labelTitle="Complément"
                placeholder="Lieu dit des patates"
                component={TextInput}
              />
              </Col>
              </Row>
            <Row gutter={8}>
              <Col flex="30%">
              <Field
                name="zipcode"
                labelTitle="Code Postal"
                placeholder="13000"
                component={TextInput}
              />
              </Col>
              <Col flex="70%">
              <Field
                name="city"
                labelTitle="Ville"
                placeholder="Marseille"
                component={TextInput}
              />
              </Col>
            </Row>
            {/*<Field
              name="societyType"
              labelTitle="Forme Juridique"
              labelDetails="Actuellement seuls les AE sont pris en charge"
              component={SelectInput}
              options={[
                {value:'AE', name:'Auto-Entreprise'},
              ]}
            />
            <Row gutter={8}>
              <Col flex="30%">
              <Field
                name="tva"
                labelTitle="TVA"
                component={SelectInput}
                options={[
                  {value:'true', name:'Oui'},
                  {value:'false', name:'Non'},
                ]}
              />
              </Col>
              <Col flex="70%">
              <Field
                name="tvaNumber"
                labelTitle="Numéro TVA"
                placeholder="FR11 111 222 333"
                component={TextInput}
              />
              </Col>
            </Row>*/}

            <Divider>Informations Personnelles</Divider>
            <Row gutter={8}>
              <Col flex="20%">
              <Field
                name="sex"
                labelTitle="Sexe"
                component={SelectInput}
                options={[
                  {value:'M', name:'Mr'},
                  {value:'F', name:'Mme'},
                  {value:'', name:'NN'}
                ]}
              />
              </Col>
              <Col flex="40%">
              <Field
                name="firstName"
                labelTitle="Prénom"
                placeholder="Jacques"
                component={TextInput}
              />
              </Col>
              <Col flex="40%">
              <Field
                name="lastName"
                labelTitle="Nom"
                placeholder="Dupon"
                component={TextInput}
              />
              </Col>
            </Row>
            <Field
              name="job"
              labelTitle="Fonction"
              labelDetails="Votre fonction sera visible sur les devis et factures"
              placeholder="Chargé de Projet"
              component={TextInput}
            />
          </Form>
        </Modal>
      </div>
    );
  }
}

export default connect(mapState, actions)(reduxForm({form:'createCompany', validate})(AddCompany))
