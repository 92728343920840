import React from 'react'
import {Timeline} from 'antd'

import  {Bloc} from './../../util/Bloc'

class RoadMap extends React.Component {
  render(){
    return(
      <Bloc title="Roadmap">
        <Timeline mode="left">
          <Timeline.Item label="2019-09-01" color="green">Création du Projet</Timeline.Item>
          <Timeline.Item label="2020-05-01" color="green">Création de la structure globale</Timeline.Item>
          <Timeline.Item color="green">Intégration de l'espace clients, dépenses, devis</Timeline.Item>
          <Timeline.Item color="grey">Création de l'espace Facture</Timeline.Item>
          <Timeline.Item color="grey">Intégration de l'envoie de mail devis/factures</Timeline.Item>
        </Timeline>
      </Bloc>
    )
  }
}

export default RoadMap
