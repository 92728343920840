import React from 'react'
import {Row, Col, Button} from 'antd'
import './background.css'

import { Bloc } from './../../util/Bloc'
import LoginForm from './../Login/LoginForm'
import SignInForm from './../Login/SignInForm'


class HomeLogin extends React.Component {
  state={
    showSignIn:false,
  }

  render(){
    const { showSignIn } = this.state
    return(
      <div style={{backgroundColor:'grey', height:'100vh'}} className="parallax">
        <div style={{position: 'absolute', left: '50vw', top: '45vh', transform: 'translate(-50%, -50%)'}}>
          <Bloc style={{marginBottom:'10px', width:'320px', textAlign:'center'}}>
            <b>Kompta</b>
          </Bloc>
          {showSignIn ?
            <SignInForm showLogin={() => this.setState({showSignIn:false})} />
            :
            <LoginForm showSignIn={() => this.setState({showSignIn:true})} />
          }
        </div>
      </div>
    )
  }
}

export default HomeLogin
