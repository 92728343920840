import { Row, Col, Button, Affix } from 'antd';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Link, } from 'react-router-dom'

import { connect } from 'react-redux'
import { withFirestore, withFirebase } from 'react-redux-firebase'

import { Loading } from './../../../util/Errors'

import numeral from 'numeral'
import './../../../util/Maths/localNumeral.js'
import { calcValeur, calcTotalHT, calcTVA, calcTTC, sumWamount } from './../../../util/Maths/calculsPrixDevis'

import Header from './MV2.png'
import Watermark from '../ReactToPrint/watermark';

const mapState = (state) => {
  let clientSelected = []
  clientSelected = state.firestore.ordered.devisSelected &&
    state.firestore.ordered.devisSelected[0] &&
    state.firestore.ordered.clients &&
    state.firestore.ordered.clients.find(
      client => client.id === state.firestore.ordered.devisSelected[0].clientId
    )

  return {
    auth: state.firebase.auth,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    devisSelected: state.firestore.ordered.devisSelected && state.firestore.ordered.devisSelected[0],
    client: clientSelected,
    loading: state.firestore.status.requested
  }
}

function CSA4({ myCompany, devisSelected, client }) {
  const printPreviewRef = useRef();

  if (!devisSelected && !myCompany && !client) return <Loading title="Ouverture du PDF" />

  const paiement = () => {
    let result = devisSelected.debours
      ? (devisSelected.tva
        ? calcTTC(calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))
        : calcTotalHT(devisSelected.content, devisSelected.type) - sumWamount(devisSelected.debours))
      : (devisSelected.tva
        ? calcTTC(calcTotalHT(devisSelected.content, devisSelected.type))
        : calcTotalHT(devisSelected.content, devisSelected.type));
    return result / devisSelected.paiement;
  }

  return (
    <>
      <ReactToPrint
        trigger={() => <div style={{ position: 'absolute', top: '-36px', right: '172px' }}><Affix offsetTop={10}><Button>Imprimer !</Button></Affix></div>}
        content={() => printPreviewRef.current}
        documentTitle={"BonDeCommande_" + devisSelected.number + "_" + devisSelected.title}
      />
      <div className="print-preview" ref={printPreviewRef}>

        <Watermark
          title="Bon De Commande"
          subtitle={'Ref N° ' + devisSelected.number + " - " + devisSelected.eventDate}
          logoUrl={Header}
          leftFooter={'Ref N°' + devisSelected.number}
          centerFooter={myCompany.companyName + ', ' + myCompany.adress + ' ' + myCompany.zipcode + ' — EURL au capital de ' + myCompany.capital + ' €'}
          centerFooterSub={'SIRET : ' + myCompany.siret + ' — TVA : ' + myCompany.tva}
          rightFooter="."
        />

        <div className='page-content'>
          {/*--- BLOC DE HEADER --- */}
          <div style={{ margin: '30px 0 40px 0' }}>
            {/*--- BLOCS DE CLIENT + SUIVI DE DOSSIER --- */}
            <Row >
              <Col span={10}>
                <div className="small up marBot10">Suivi de dossier :</div>
                <span style={{ paddingTop: '10px' }}>
                  {myCompany.entities[0].firstName ? myCompany.entities[0].firstName : 'Prénom'} {myCompany.entities[0].lastName ? myCompany.entities[0].lastName : 'Nom'} </span><br />
                <span >{myCompany.entities[0].function ? myCompany.entities[0].function : "Fonction"}</span> <br />
                <span ><Link src={'mailto:' + myCompany.entities[0].mail ? myCompany.entities[0].mail : 'mailAdress'}>{myCompany.entities[0].mail ? myCompany.entities[0].mail : "adress mail"}</Link></span> <br />
                <span>{myCompany.entities[0].phone && myCompany.entities[0].phone}</span> <br />
              </Col>
              <Col span={14} className="font">
                {client ?
                  <div className="border pad10" style={{ flexDirection: 'column' }}>
                    <div className=" bold up">{client.society} </div>
                    <div >{client.acronyme} <span className='up'>{client.name}</span> {client.surname} </div>
                    <div >{client.adress}</div>
                    <div >{client.zipcode} {client.city}</div>
                    <div ><Link src={'mailto:' + client.mail ? client.mail : 'mailAdress'}>{client.mail ? client.mail : "adress mail"}</Link></div>
                    {client.phone && <div> {client.phone}</div>}
                  </div>
                  :
                  <div>
                    Aucun Client Sélectionné
                  </div>
                }
              </Col>
            </Row>
          </div>

          <div className='border small'>

            {/* --- TITLE --- */}
            <div className='borderBot pad10'>
              <div className="title center">{devisSelected.title} {devisSelected.eventDate && (" – le " + devisSelected.eventDate)} </div>
              <div className='small center'>Ce projet concerne {devisSelected.details}</div>
            </div>

            {/* --- HEADER --- */}
            <div className='borderBot space-between ' style={{ padding: '3px 10px 3px 10px' }}>
              <div className='center wtab1'>
                Ref.
              </div>
              <div className='wtab6'>
                Description
              </div>
              <div className='center wtab1'>
                Q
              </div>
              <div className='center wtab2'>
                Sortie
              </div>
              <div className='center wtab2'>
                Retour
              </div>
            </div>

            {/* --- CONTENT --- */}
            <>
              {devisSelected.content && devisSelected.content.map((cat, i) =>
                <>
                  <div key={i} style={{ display: 'block' }}>
                    {cat.title &&
                      <div className=" up bold center" style={{ padding: '1px' }}>
                        <div className="pad5" style={{ backgroundColor: '#ADD8E6' }}>{cat.title && cat.title}</div>
                      </div>
                    }
                    {cat && cat.articles.map((art, i) =>
                      art.showBdC &&
                      <div key={i} className='space-between' style={{ alignItems: 'center', padding: '5px 10px 5px 10px' }}>
                        <div className='wtab1 xx-small grey' >
                          {art.articleDetails.ref}
                        </div>
                        <div className='wtab6' >
                          {art.desc ? art.desc :
                            <div>
                              <div>{art.articleDetails.title} </div>
                              {art.articleDetails.content &&
                                <div className='xx-small pad5'>
                                  {art.articleDetails.content.map(el =>
                                    <div>- {el.q} * {el.title}</div>
                                  )}
                                </div>
                              }
                            </div>
                          }
                        </div>
                        <div className='wtab4 right '>
                          {art.q}
                        </div>
                        <div className='wtab5 '>
                          &nbsp;{art.u}
                        </div>
                        <div className='wtab2 right border pad5 marR5' >
                          &nbsp;
                        </div>
                        <div className='wtab2 right border pad5' >
                          &nbsp;
                        </div>
                      </div>
                    )}
                  </div>

                </>
              )}
            </>
          </div>
        </div>
      </div >
    </ >
  );
};

export default withFirebase(withFirestore(connect(mapState)(CSA4)))