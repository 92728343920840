import React from 'react'
import {Row, Col, Button} from 'antd'
import { Redirect, BrowserRouter as Router, Link } from "react-router-dom";

import { connect } from 'react-redux'

import CallToAction from './callToAction'
import AddCompany from './../Settings/Companies/AddCompany'
import RoadMap from './Roadmap'

import { Loading } from './../../util/Errors'


const mapState = (state) => ({
  profile: state.firebase.profile
})

const actions = {}

class HomeLogged extends React.Component {

  render(){
    const { profile } = this.props
    if(profile.isLoaded){
    return(
      <div style={{height:'100vw'}}>
        {profile.isNewUser ? <center><h3>Bienvenue sur Kompta le site de compta <br/> fait par un Freelance pour les Freelances !</h3></center> : <h3>Actions Rapides</h3>}
        <Row>
          <Col xs={24} md={14}>
            <Row type="flex" gutter={[8,8]} justify="space-around" align="middle" style={{marginTop:'50px'}}>
              <Col xs={24} sm={12} md={8} lg={7}  key="1">
                <AddCompany type="callToAction" />
              </Col>
              <Col xs={24} sm={12} md={8} lg={7}  key="2">
                <Link to="/Clients/"><CallToAction icon="userAdd" text="ou encore :" callToAction="Ajouter votre premier client" /></Link>
              </Col>
              <Col xs={24} sm={12} md={8} lg={7}  key="3">
                <Link to="/Articles/"><CallToAction icon="cameraAdd" text="et aussi"callToAction="Ajouter vos premiers articles"/></Link>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={10}>
            <RoadMap />
          </Col>
        </Row>
      </div>
    )
  } else { return <Loading title ="Récupération des données" />}
  }
}

export default connect(mapState, actions)(HomeLogged)
