import React from 'react'
import { Row, Col } from 'antd'
import { Loading } from './Errors'

export const Bloc = ({ children, title, action, loading, loadingDetails, style, footer }) => {
  return (
    <div style={{
      ...style,
      border: '1px solid #80808066',
      overflow: 'auto',
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(85, 85, 85, 0.15) 1px 1px 12px',
    }}>
      {title &&
        <div style={{ padding: '10px', backgroundColor: 'var(--color1)', color: 'white', fontSize: '1.2em' }}>
          {action ?
            <Row justify="space-between">
              <Col>{title}</Col>
              <Col>{action}</Col>
            </Row>
            :
            <div>{title}</div>
          }
        </div>}

      {loading ?
        <Loading title={loadingDetails ? loadingDetails : "Chargement des Informations"} />
        :
        <div style={{ padding: '10px 15px 10px 15px' }}>
          {children}
        </div>
      }

      {footer &&
        <div style={{ backgroundColor: '#e1e1e1', padding: '10px', fontSize: '1.2em' }}>
          {footer}
        </div>
      }
    </div>
  )
}
