import React from 'react';
import { Row, Col, Breadcrumb, message, List, Popconfirm, Button, Input, Tag, Divider, Radio, Progress, Tooltip } from 'antd'
import { HomeOutlined, ArrowLeftOutlined, SearchOutlined, DeleteOutlined, SubnodeOutlined, DownOutlined, PushpinOutlined, PushpinFilled } from '@ant-design/icons';

import { Link } from "react-router-dom";

import { Bloc } from './../../../util/Bloc'
import { NotAllowed } from './../../../util/Errors'
import { sortByNumber } from './../../../util/sort'

import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'

import { pinElement } from './../../../actions/devisActions'

import Statut from './Statut'
//import DevisForm from './DevisForm'
import ActionBar from './ActionBar'

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  devis: state.firestore.ordered.devis,
  myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
  loading: state.firestore.status.requested
})

const actions = {
  pinElement
}

const StatutDevisProgress = ({ state }) => {
  switch (state) {
    case 'redaction':
      return <Tooltip title="Rédaction"><Progress style={{ width: '100px' }} percent={30} status="active" showInfo={false} /></Tooltip>;
    case 'sent':
      return <Tooltip title="Envoyé"><Progress style={{ width: '100px' }} percent={70} status="active" strokeColor="#49e8b4" showInfo={false} /></Tooltip>;
    case 'declined':
      return <Tooltip title="Refusé"><Progress style={{ width: '100px' }} percent={70} status="exception" strokeColor="rgb(185, 161, 161)" showInfo={false} /></Tooltip>;
    case 'validated':
      return <Tooltip title="Validé"><Progress style={{ width: '100px' }} percent={85} status="active" strokeColor="rgb(0, 204, 61)" showInfo={false} /></Tooltip>;
    case 'factured':
      return <Tooltip title="Facture Générée"><Progress style={{ width: '100px' }} percent={100} showInfo={false} /></Tooltip>;
    default:
      return '';
  }
}

const PinnedElement = ({ state, id }) => {
  if (state && state == true) {
    return <PushpinFilled onClick={() => this.pinElement(id, true)} />
  } else {
    return <PushpinOutlined onClick={() => this.pinElement(id, false)} />
  }
}

class Devis extends React.Component {
  state = {
    editId: null,
    data: {},
    cancelEdit: false,
    devisFiltered: []
  }

  componentDidMount() {
    let devisFiltered = sortByNumber(this.props.devis ? this.props.devis : [])
    this.setState({
      devisFiltered: devisFiltered
    });
  }
  componentWillReceiveProps(nextProps) {
    let devisFiltered = sortByNumber(nextProps.devis)
    this.setState({
      devisFiltered: devisFiltered
    });
  }

  cancelEdit = () => { //si cancelEdit alors on réinitinialise le form, d'ou désactiver cancelEdit après 10ms
    this.setState({ editId: null, cancelEdit: true })
    setTimeout(() => this.setState({ cancelEdit: false }), 100)
  }

  handleChange = e => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      currentList = this.props.devis;
      newList = currentList.filter(devis =>
        (devis.title && devis.title.toLowerCase().includes(e.target.value.toLowerCase()))
      )

      this.setState({
        devisFiltered: newList
      })
    } else {
      newList = this.props.devis;
      this.setState({
        devisFiltered: newList
      });
    }
  }

  selectElement = (newId, stateId, data) => {
    if (stateId == null || stateId != newId) { // si on a pas d'élément sélectionné ou si l'élément sélectionné n'est pas le même
      this.setState({ editId: newId, data: data, cancelEdit: false })
    }
    else {
      this.setState({ editId: null, cancelEdit: false })
    }
  }

  render() {
    const { devis, loading, auth, profile, myCompany, pinElement } = this.props
    const { editId, data, devisFiltered, cancelEdit } = this.state
    return (
      <>
        <Breadcrumb style={{ marginBottom: '15px' }}>
          <Breadcrumb.Item href="/">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={'/' + myCompany.publicId + '/TableauDeBord/'}>
              <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Devis</span>
          </Breadcrumb.Item>
        </Breadcrumb>

        <ActionBar editId={editId} data={data} />

        <Row type="flex" gutter={[14, 16]}>
          <Col xs={24} md={12} lg={13} xl={14} xxl={15} >
            <Bloc
              title="Devis"
              action={<Input placeholder="Chercher un devis" allowClear onChange={this.handleChange} prefix={<SearchOutlined />} />}
              loading={false} > {/*//!loading['clients?where=uid:==:'+ auth.uid +'&orderBy=society']*/}
              <List
                style={{ height: '70vh', overflow: 'auto' }}
                size="small"
                dataSource={devisFiltered}
                renderItem={devis =>
                  <List.Item
                    style={{ borderBottom: '1px solid #80808066', padding: '5px' }}
                    actions={[<PinnedElement state={devis.pinned} id={devis.id} />, <StatutDevisProgress state={devis.statut} />]}
                  >
                    <List.Item.Meta
                      title={
                        <Link onClick={() => this.selectElement(devis.id, editId, devis)}
                          style={{ color: editId === devis.id ? '' : devis.statut == "declined" ? 'grey' : devis.statut == "factured" ? 'rgba(24, 68, 0, 0.7)' : 'rgba(0, 0, 0, 0.7)' }} >
                          {devis.title} {devis.statut == "declined" && <span>(Devis N°{devis.number})</span>}
                        </Link>
                      }
                      description={
                        (devis.statut != "declined" && devis.statut != "factured") &&
                        <div>
                          <Link onClick={() => this.selectElement(devis.id, editId, devis)}
                            style={{ color: editId === devis.id ? '' : 'rgba(0, 0, 0, 0.7)' }} >
                            Devis N°{devis.number}{devis.creationDate && " créé le " + devis.creationDate}
                          </Link>
                        </div>
                      }
                    />
                  </List.Item>}
              />
            </Bloc>
          </Col>
          <Col xs={24} md={12} lg={11} xl={10} xxl={9}>
            <Statut editId={editId} data={data} />
          </Col>
        </Row>
      </>
    )
  }
}

export default withFirestore(connect(mapState, actions)(Devis))
