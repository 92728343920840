import React from 'react'
import {Row, Col, Button, Form, Divider} from 'antd'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, isNumeric} from 'revalidate'
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { TextInputWoError, SelectInputWoError, TextAreaInput } from './../../../util/Form/field'

import { updateCompanyEntities } from './../../../actions/companyActions'

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    devis: state.firestore.ordered.devis,
    initialValues: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0].settingsFactures,
    company: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    loading: state.form.companyEntities
})

const actions = {
  updateCompanyEntities
}

class CompanyForm extends React.Component {

  onFormSubmit = (values) => {
    //console.log(values, this.props.company.id);
    this.props.updateCompanyEntities(values, this.props.company.id, "factures")
    .then(() => this.props.initialize(values))
  };

  render(){
    const {handleSubmit, reset, pristine, submitting, loading} = this.props
    return(
      <Form onFinish={handleSubmit(this.onFormSubmit)}>

        <Row gutter={8}>
          <Col flex="50%">
          <Field
            name="lastNumberFormat"
            labelTitle="Format de Numéro"
            component={SelectInputWoError}
            placeholder="YYYY-NN-V"
            options={[
              {value:'YYYY-NNN', name:'YYYY-NNN'},
              {value:'YYYY-NNN-V', name:'YYYY-NNN-V'},
            ]}
          />
          </Col>
          <Col flex="50%">
          <Field
            name="lastNumber"
            labelTitle="Dernier N°Facture"
            placeholder="YYYY-NNN-V"
            component={TextInputWoError}
          />
          </Col>
          <Col flex="100%">
          <Field
            name="footer"
            labelTitle="Pied de Page (conditions de paiements, etc.)"
            placeholder="Paiement dans un délai de 60 jours maximum à compter de la date de réception de la facture, si les modalités de facturation sont conformes et valides. Au-delà de ce délai une pénalité de retard de 10% du montant total vous sera facturée."
            component={TextAreaInput}
          />
          </Col>
        </Row>
        <div style={{textAlign:'right'}}>
          <Button
            onClick={reset}
            style={{ marginRight: 8 }}
            disabled={pristine || submitting}
          >
            Effacer
          </Button>
          <Button
            loading={loading && loading.submitting}
            type="primary"
            htmlType="submit"
            disabled={pristine || submitting}
          >
            Sauvegarder
          </Button>
        </div>

      </Form>
    )
  }
}

export default connect(mapState, actions)(reduxForm({form:'companyFacturesDetails'})(CompanyForm))
