import React from 'react'
import { Row, Col, Form, Input, Button, Checkbox, Alert } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import './Login.css'

import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {composeValidators, combineValidators, isRequired, hasLengthLessThan, hasLengthGreaterThan, matchesField, createValidator} from 'revalidate'
import { registerUser } from './../../actions/authActions'

import { Bloc } from './../../util/Bloc'
import { TextInput } from './../../util/Form/field'
import { Loading } from './../../util/Errors'

const mapState = (state) => ({
  loading: state.form && state.form.signin
})

const actions = {
registerUser
}

const isValidEmail = createValidator(
  message => value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message
    }
  },
  'Entrer une adresse mail valide'
)

const validate = combineValidators({
  name: composeValidators(
    isRequired({message: 'Votre nom est requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(35)({message:'Trop long'}),
  )(),
  email: composeValidators(
    isRequired({message: 'Votre mail est requis'}),
    hasLengthGreaterThan(3)({message:'Trop court'}),
    hasLengthLessThan(35)({message:'Trop long'}),
    isValidEmail
  )(),
  password: composeValidators(
    isRequired({message: 'Votre mot de passe est requis'}),
    hasLengthGreaterThan(5)({message:'Trop court'}),
    hasLengthLessThan(35)({message:'Trop long'}),
  )(),
  passwordVerificator: matchesField('password')({message: 'Mot de passe différent'}),
})

class SignInForm extends React.Component {
  render() {
    const { handleSubmit, loading, error, style, registerUser, showLogin } = this.props
    return (
      <Bloc
      style={{width:'320px', textAlign:'center', ...style}}
      >
      <Form onFinish={handleSubmit(values => registerUser(values))} className="login-form">
        <Row>
          <Col span={24}>
            <Field
              name="name"
              labelTitle="Votre nom"
              placeholder="Martin"
              component={TextInput}
            />
          </Col>
          <Col span={24}>
            <Field
              name="email"
              labelTitle="Adresse mail"
              placeholder="contact@mail.fr"
              component={TextInput}
            />
          </Col>
          <Col span={24}>
            <Field
              name="password"
              labelTitle="Mot de passe"
              placeholder="au moins 6 caractères"
              component={TextInput}
              type="password"
            />
          </Col>
          <Col span={24}>
            <Field
              name="passwordVerificator"
              labelTitle="Répétition du mot de passe"
              type="password"
              component={TextInput}
            />
          </Col>
          {error && <Col span={24} style={{marginBottom:'5px'}}><Alert message={error} type="error" showIcon /></Col>}
          <Col span={24}>
            <Button loading={loading && loading.submitting} type="primary" htmlType="submit" className="login-form-button">
              Inscription
            </Button>
          </Col>
        </Row>
          <div style={{marginTop:'15px', fontSize:'0.85em'}}>
            <a onClick={showLogin}>J'ai un compte</a>
          </div>
      </Form>
      </Bloc>
    );
  }
}

export default connect(mapState, actions)(reduxForm({form:'signin',  validate})(SignInForm))
