export const sortByNumber = (list) => {
  let filtered = [...list]
  filtered = filtered.sort((a, b) => a.number < b.number)
  return filtered
}


export const sumTotalbyMonth = (list, month, year, paid, caSent) => {
  let sum = 0;
  let filtered = [...list]
  if(paid){
      filtered = filtered.filter(facture => (
        facture.inDate?.substring(3, 5).includes(month) &&
        facture.inDate?.includes(year)
      ))
  }else{
    if(caSent){
      filtered = filtered.filter(facture => (
        facture.creationDate.substring(3, 5).includes(month) &&
        facture.creationDate.includes(year)
      ))
    }else{
      filtered = filtered.filter(facture => (
        !facture.inDate && facture.creationDate.substring(3, 5).includes(month) &&
        facture.creationDate.includes(year)
      ))
    }
  }

  console.log(month,filtered);

  sum = filtered.reduce((a, v) => a = a + (v.totalHT ? parseFloat(v.totalHT) : 0) , 0 )
  return sum
}


export const sumTotalbyTrimestre = (list, trim, year) => {
  let sum = 0;
  let filtered = [...list]
  let t1 = ["01", "02", "03"]
  let t2 = ["04", "05", "06"]
  let t3 = ["07", "08", "09"]
  let t4 = ["10", "11", "12"]
  let tSelected = t1

  if(trim == 1) {tSelected = t1}
  else if(trim == 2) {tSelected = t2}
  else if(trim == 3) {tSelected = t3}
  else if(trim == 4) {tSelected = t4};

  filtered = filtered.filter(facture => (
    facture.creationDate.substring(3, 5).includes(tSelected[0]) ||
    facture.creationDate.substring(3, 5).includes(tSelected[1]) ||
    facture.creationDate.substring(3, 5).includes(tSelected[2])
  )) // substring selectionne le mois// includes compare au trimestre actuel

  filtered = filtered.filter(facture => facture.creationDate.includes(year))

  sum = filtered.reduce((a, v) => a = a + (v.totalHT ? parseFloat(v.totalHT) : 0) , 0 )

  return sum
}
