import React from 'react'
import {Row, Col, Button, Breadcrumb, List, Popconfirm, Input, Tag} from 'antd'
import { HomeOutlined, DeleteOutlined, ArrowLeftOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

import { Link, withRouter } from "react-router-dom";

import { compose } from "redux"
import { useSelector, connect } from 'react-redux'
import { firestoreConnect, isLoaded, withFirestore } from 'react-redux-firebase'


import { inDateFacture } from './../../../actions/devisActions'

import { sortByNumber, sumTotalbyTrimestre } from './../../../util/sort'
import { Bloc } from './../../../util/Bloc'
import BarChart from './../../../util/Charts/BarChart'

import moment from 'moment'

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    factures: state.firestore.ordered.factures,
    myCompany: state.firestore.ordered.myCompany && state.firestore.ordered.myCompany[0],
    loading: state.firestore.status.requested
})

const actions = {
  inDateFacture
}

class Depenses extends React.Component {
  state={
    editId: null,
    cancelEdit:false,
    facturesFiltered: [],
    date:moment().format('DD/MM/YYYY')
  }

  componentDidMount(){
    let filtered = sortByNumber(this.props.factures ? this.props.factures : [])
    this.setState({
      facturesFiltered: filtered
    });
  }
  componentWillReceiveProps(nextProps) {
    let filtered = sortByNumber(nextProps.factures ? nextProps.factures : [])
    this.setState({
      facturesFiltered: filtered
    });
  }

  cancelEdit = () => { //si cancelEdit alors on réinitinialise le form, d'ou désactiver cancelEdit après 10ms
    this.setState({editId: null, cancelEdit:true})
    setTimeout(() => this.setState({cancelEdit:false}), 100)
  }

  handleChange = e => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      currentList = this.props.factures;
      newList = currentList.filter(facture =>
        (facture.tilte && facture.name.toLowerCase().includes(e.target.value.toLowerCase()))
      )

      this.setState({
        facturesFiltered: newList
      })
    } else {
      newList = this.props.factures;
      this.setState({
        facturesFiltered: newList
      });
    }
  }

  render(){
    const { depenses, loading, deleteDepense, auth, myCompany, factures, inDateFacture } = this.props
    const { editId, facturesFiltered, cancelEdit, date } = this.state

    console.log(sumTotalbyTrimestre(facturesFiltered, 3, 2021))
    return(
      <div>
      <Breadcrumb style={{marginBottom:'15px'}}>
        <Breadcrumb.Item href="/">
          <HomeOutlined/>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={'/'+myCompany.id+'/TableauDeBord/'}>
            <span>{myCompany ? myCompany.companyName : 'Chargement...'}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <span>Comptabilité</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row type="flex" gutter={[14, 16]} >
        <Col xs={24} md={24} lg={11} xl={14} xxl={15} >
          <BarChart data={facturesFiltered}/>
        </Col>
        <Col xs={24} md={24} lg={13} xl={10} xxl={9}>
          <List
            size="small"
            dataSource={facturesFiltered}
            renderItem={facture =>
              <List.Item
                style={{borderBottom:'1px solid #80808066', padding:'5px'}}
                actions={[
                  facture.totalHT+ ' €',
                  !facture.inDate && <input placeholder="DD/MM/YYYY" defaultValue={date} onChange={e => this.setState({date : e.target.value})} style={{width:'90px'}}/>,
                  facture.inDate ? facture.inDate : <Button onClick={e => inDateFacture(facture.id, date)}>Encaisser</Button>]}
              >
                <List.Item.Meta
                  title={<>N°{facture.number} - {facture.title}</>}

                />
              </List.Item>}
          />
        </Col>
      </Row>
      </div>
    )
  }
}

//export default withFirestore(connect(mapState)(firestoreConnect(query)(Depenses)))

export default withRouter(withFirestore(connect(mapState, actions)((Depenses))))
